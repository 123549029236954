import {
	GenericComponent
	, GenericForm
	, getInitialState
	, GenericTable
	, raiseError
	, deepCopy
} from "WebUI_Framework";

import purchaseOrderServiceRoutines from "./purchaseOrderServiceRoutines";
import AddEditPurchaseOrder from './AddEditPurchaseOrder';

class ManagePurchaseOrders extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.subscriberServer = this.session.getServerInstance("SUBSCRIBER");
		this.accessToken = this.session.getAccessToken();
		this.orders = [];
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	getFrame = async (offset, recordsPerFrame) => {
		this.resetError();
		try {
			let retData = await purchaseOrderServiceRoutines.list(this.session, {
				offset, num_recs: recordsPerFrame,
				buyer_org_id: this.session.getLoggedInCompanyId()
			});
			if (retData.length > 0) {
				this.orders = this.orders.concat(retData);
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	}

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				this.orders.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
				break;
			case "EDIT":
			case "CANCEL":
			case "ACCEPT":
			case "REJECT":
			case "AMEND":
			case "APPROVE":
			case "APPROVEAMENDMENT":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.orders[updatedArrayIndex] = ret.selectData.header;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.orders.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditPurchaseOrder"));
		}
		return;
	}

	handler = async (action, orderId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(AddEditPurchaseOrder, { action, orderId, arrayIndex, pageNumber, ...this.props }, this.onReturn);
	}

	table_buttons = {
		literal: "Add",
		func: () => {
			const ucp = this.session.getUserCompanyProps(this.session.getLoggedInCompanyId(),
				this.session.getLoggedInParentCompanyId());
			if (!(ucp.org_is_subscriber)) {
				if (ucp.partner_relationship_type === 'S') {
					this.setError(raiseError(window.parseLiteral("CANNOT_CREATE_PURCHASE_ORDER"
						, this.session.getLoggedInCompanyName())));
					return false;
				}
			}
			this.handler("ADD", null, this.pageNumber, 0);
		}
	};

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	}

	getRow = (data, index, pageNumber, arrayIndex) => {

		this.pageNumber = pageNumber;

		return (
			<tr key={index}>
				<td>
					<button type="button"
						style={{
							color: "blue", textDecoration: "underline", cursor: "pointer",
							border: "none", background: "none", padding: 0
						}}
						onClick={() => this.handler("VIEW", data.order_id, pageNumber, arrayIndex)}
					>
						{data.order_number}
					</button>
				</td>
				<td>{data.order_issue_date}</td>
				<td>{data.seller_org_name}</td>
				<td>{data.description}</td>
				<td>{data.order_valid_from_date}</td>
				<td>{data.order_valid_to_date}</td>
				<td>
					{(data.entity_state === "0" && data.deleted !== true) && (
						<>
							<span onClick={() => this.handler("EDIT", data.order_id, pageNumber, arrayIndex)} title='Edit' style={{ cursor : "pointer"}}>
								<i className="bi bi-pencil-square" style={{ fontSize: "20"}} ></i>
							</span>&nbsp;&nbsp;
							<span onClick={() => this.handler("DELETE", data.order_id, pageNumber, arrayIndex)} title='Delete' style={{ cursor : "pointer"}}>
								<i className="bi bi-trash3" style={{ fontSize: "20"}} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={() => this.handler("APPROVE", data.order_id, pageNumber, arrayIndex)} title='Approve' style={{ cursor : "pointer"}}>
								<i className="bi bi-check2" style={{ fontSize: "20"}} ></i>
							</span>&nbsp;&nbsp;
						</>
					)}
					{(((data.entity_state === "1") ||  (data.entity_state === "2")) &&
                        (data.owner_org_id === data.buyer_org_id))&& (
						<>
							<span onClick={() => this.handler("AMEND", data.order_id, pageNumber, arrayIndex)} title='Amend' style={{ cursor : "pointer"}}>
								<i className="bi bi-pen" style={{ fontSize: "20"}} ></i>
							</span>&nbsp;&nbsp;
						</>
						)}
					{(data.entity_state === "2") &&
						(<>
							<span
								onClick={() => this.handler("APPROVEAMENDMENT",
									data.order_id, pageNumber, arrayIndex)}
								title='Approve' style={{ cursor : "pointer"}}>
								<i className="bi bi-check2" style={{ fontSize: "20"}} ></i>
							</span>&nbsp;&nbsp;
						</>)}
				</td>
			</tr>
		);
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Order No.</th>
				<th key={++key}>issue Date</th>
				<th key={++key}>Seller</th>
				<th key={++key}>Description</th>
				<th key={++key}>Valid From</th>
				<th key={++key}>Valid Till</th>
				<th key={++key}>Actions</th>
				<th key={++key}></th>
			</tr>
		);
	}

	render = () => {
		const tableclass = "table table-striped border-dark";
		let datablk = (
			<div className="container-fluid pt-0 m-0 ">
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.orders}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.table_buttons}
						pageChange={this.pageChange}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	}
}

export default ManagePurchaseOrders;
