import {
	//GenericComponent
    GenericForm
	,getInitialState
	,GenericTable
	,raiseError
	,deepCopy
	,formatAmount
	,valuePresent
} from "WebUI_Framework";

import issuedInvoiceServiceRoutines from "./IssuedInvoiceServiceRoutines";
import AddEditIssuedInvoice from './AddEditIssuedInvoice';
import { BaseComponent } from '../common/BaseComponent'

let invoice_statuses = [
	{ value: "0", label: window.getLabelLiteral("STATUS_DRAFT") },
	{ value: "1", label: window.getLabelLiteral("STATUS_DELTED") },
	{ value: "2", label: window.getLabelLiteral("STATUS_SENT") },
	{ value: "3", label: window.getLabelLiteral("STATUS_RECEIVED") },
	{ value: "4", label: window.getLabelLiteral("STATUS_ACKNOWLEDGED") },
	{ value: "5", label: window.getLabelLiteral("STATUS_ACCEPTED") },
	{ value: "6", label: window.getLabelLiteral("STATUS_REJECTED") },
	{ value: "7", label: window.getLabelLiteral("STATUS_PAYMENT_DONE") },
];

class ManageIssuedInvoices extends BaseComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		this.max_invoice_serial_num = 0;
		//this.recordsPerPage = 15;
		this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.subscriberServer = this.session.getServerInstance("SUBSCRIBER");
		this.accessToken = this.session.getAccessToken();
		this.invoices = [];
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	getFrame = async (offset, recordsPerFrame) => {
		this.resetError();
		try {
			let retData = await issuedInvoiceServiceRoutines.list(this.session, {
								offset, num_recs : recordsPerFrame,
								seller_org_id: this.session.getLoggedInCompanyId()
								});
			if (retData.length > 0) {
				this.invoices = this.invoices.concat(retData);
			}
		} catch (e) {
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	}

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				this.invoices.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
				break;
			case "EDIT":
			case "CANCEL":
			case "ACCEPT":
			case "REJECT":
			case "AMEND":
			case "APPROVE":
			case "APPROVEAMENDMENT":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.invoices[ updatedArrayIndex ] = ret.selectData.header;
                    if (valuePresent(ret.referralId)) {
                        this.invoices[ updatedArrayIndex ].referral_id = ret.referralId;
                    }

					let newState = deepCopy(prevState);
					newState.editCount ++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.invoices.splice(ret.arrayIndex, 1);
				this.setState({editCount: this.state.editCount + 1});
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditIssuedInvoice"));
		}
		return ;
	}

	handler = async (action, invoiceId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		this.callForm(AddEditIssuedInvoice, { action, invoiceId, arrayIndex, pageNumber,  ...this.props }, this.onReturn);
	}

	table_buttons = [
		{
			literal: "Filter",
			func: () => {
				this.setState({ viewFilter: true });
			}
		},
		{
			literal: "Add",
			func: () => {
				const ucp = this.session.getUserCompanyProps(this.session.getLoggedInCompanyId(),
															this.session.getLoggedInParentCompanyId());
				if (!(ucp.org_is_subscriber)) {
					if (ucp.partner_relationship_type === 'D') {
						this.setError(raiseError(window.parseLiteral("CANNOT_CREATE_INVOICE"
															, this.session.getLoggedInCompanyName())));
						return false;
					}
				}
				this.handler("ADD", null, this.pageNumber, 0);
			}
		}
	];

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	}

	getRow = (data, index, pageNumber, arrayIndex) => {
        const i = index - 1;

		this.pageNumber = pageNumber;
		let status_string = "Unknown";
        if (data?.referral_id) {
            status_string = window.getLabelLiteral("PENDING_APPROVAL");
        }
        else if (data?.status) {
			status_string = invoice_statuses[data.status].label;
		}
		return (
			<tr key={index}>
				<td>
					<button type="button"
						style={{
							color: "blue", textDecoration: "underline", cursor: "pointer",
							border: "none", background: "none", padding: 0, margin:0,
							textAlign: "left"
						}}
						onClick={() => this.handler("VIEW", data.invoice_id, pageNumber, arrayIndex)}
					>
						{data.invoice_number}
					</button>
				</td>
				<td>{data.invoice_date}</td>
				<td>{data.buyer_org_name}</td>
				<td>{data.payment_due_date}</td>
				<td>{data.buyer_contact_email_id}</td>
				<td style={{textAlign: "right"}}>{formatAmount(data.total_amount, data.currency)}</td>
				<td><i>{status_string}</i></td>
				<td>
                    {(data.entity_state === "0" && data.deleted === false && !valuePresent(data.referral_id)) && (
                        <>
                            <span onClick={() =>
                                this.handler("EDIT", data.invoice_id, pageNumber, arrayIndex)}
                                title='Edit' style={{cursor : "pointer"}}>
                                <i className="bi bi-pencil-square" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                            <span onClick={() =>
                                this.handler("DELETE", data.invoice_id, pageNumber, arrayIndex)}
                                title='Delete' style={{cursor : "pointer"}}>
                                <i className="bi bi-trash3" style={{ fontSize: "20" }} ></i>
                            </span>
                            &nbsp;&nbsp;
                            <span onClick={() =>
                                this.handler("APPROVE", data.invoice_id, pageNumber, arrayIndex)}
                                title='Approve' style={{cursor : "pointer"}}>
                                <i className="bi bi-check2" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>
                    )}
                    {(data.entity_state === "0" && data.deleted === false && valuePresent(data.referral_id)) && (
                        <>
                            <span onClick={() =>
                                this.withdrawReferral(data, i, "referral_id")}
                                title='Withdraw' style={{cursor : "pointer"}}>
                                <i className="bi bi-skip-backward" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>
                    )}
                    {(data.status === "6" && !(valuePresent(data.referral_id))) && (
                        <>
                            <span onClick={() =>
                                this.handler("AMEND", data.invoice_id, pageNumber, arrayIndex)}
                                title='Amend' style={{cursor : "pointer"}}>
                                <i className="bi bi-pen" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>
                    )}
                    {(data.status === "6" && data.entity_state === "2" && !(valuePresent(data.referral_id))) && (
                        <>
                            <span
                                onClick={() => this.handler("APPROVEAMENDMENT",
                                                    data.invoice_id, pageNumber, arrayIndex)}
                                title='Approve' style={{cursor : "pointer"}}>
                                <i className="bi bi-check2" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>
                    )}
                    {(data.status === "6" && data.entity_state === "2" && (valuePresent(data.referral_id))) && (
                        <>
                            <span onClick={() =>
                                this.withdrawReferral(data, i, "referral_id")}
                                title='Withdraw' style={{cursor : "pointer"}}>
                                <i className="bi bi-skip-backward" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>
                    )}
				</td>
			</tr>
		);
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Invoice No.</th>
				<th key={++key}>Invoice Date</th>
				<th key={++key}>Buyer</th>
				<th key={++key}>Payment Due Date</th>
				<th key={++key}>Buyer Contact</th>
				<th key={++key} style={{textAlign:"right"}}>Amount(excl. tax)</th>
				<th key={++key}>Status</th>
				<th key={++key}></th>
			</tr>
		);
	}

	getCompanyList = async (control, searchText) => {
		let retData;
		try {
			retData = await issuedInvoiceServiceRoutines.fetch_first_level_network(this.session.getServerInstance("SUBSCRIBER"),
				this.session.getAccessToken(),
				this.session.getLoggedInCompanyId(),
				null,
				null,
				searchText);
			return retData;
		} catch (e) {
			control.error = true;
			if (e.error_message !== null && e.error_message !== undefined) {
				control.errorMsg = e.error_message;
			}
			else {
				control.errorMsg = e.message;
			}
		}
	};

	render = () => {
		const tableclass = "table table-striped border-dark";
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				{/*
				<div className="row p-0 m-0 g-0">
					<div className="col-sm-12 h3 text-center">Issued Invoices</div>
				</div>
				*/}
				<div className="col-sm-12">
					<GenericTable
						pageHeading={this.getBreadcrumb()}
						pageHeadingClass="h6 text-left"
						data={this.invoices}
						recordsPerPage={this.recordsPerPage}
						getRow={this.getRow}
						getHeader={this.getHeader}
						tableclass={tableclass}
						getFrame={this.getFrame}
						recordsPerFrame={this.recordsPerFrame}
						pageNumber={this.pageNumber}
						isLoading={this.state.isLoading}
						button={this.table_buttons}
						pageChange={this.pageChange}
					/>
				</div>
			</div>
		);

		let frm = (
			<GenericForm
				datablk={datablk}
				buttons={[]}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return frm;
	}
}

export default ManageIssuedInvoices;
