import {
    //GenericComponent,
    GenericForm,
    getInitialState,
    GenericTable,
    deepCopy,
    raiseError,
    search_select,
    searchselect,
    input_date,
    inputdate,
    generic_string,
    genericstring,
    invoicestatus,
    invoice_status,
    copyFormFieldsToDataFields,
    FormField,
    Modal_generic_content,
    Backdrop,
    valuePresent
} from 'WebUI_Framework';

import { BaseComponent } from '../common/BaseComponent';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ViewReceivedInvoice from './ViewReceivedInvoice';
import AddEditInvoice from './AddEditInvoice';
import receivedInvoiceServiceRoutines from './ReceivedInvoiceServiceRoutines';

let invoice_statuses = [
    { value: "0", label: window.getLabelLiteral("STATUS_DRAFT") },
    { value: "1", label: window.getLabelLiteral("STATUS_DELTED") },
    { value: "2", label: window.getLabelLiteral("STATUS_SENT") },
    { value: "3", label: window.getLabelLiteral("STATUS_RECEIVED") },
    { value: "4", label: window.getLabelLiteral("STATUS_ACKNOWLEDGED") },
    { value: "5", label: window.getLabelLiteral("STATUS_ACCEPTED") },
    { value: "6", label: window.getLabelLiteral("STATUS_REJECTED") },
    { value: "7", label: window.getLabelLiteral("STATUS_PAYMENT_DONE") },
];

const fields = {
    buyer_org_name: {
        id: "buyer_org_name",
        name: "buyer_org_name",
        label: "Buyer:",
        class: searchselect,
        component: search_select,
        mandatory: false,
    },
    buyer_org_id: {
        id: "buyer_org_id",
        name: "buyer_org_id",
        label: "Buyer:",
        class: genericstring,
        component: generic_string,
        mandatory: false,
    },
    statuses: {
        id: "statuses",
        name: "statuses",
        label: "Status",
        class: invoicestatus,
        component: invoice_status,
        mandatory: false,
    },
    from_date: {
        id: "from_date",
        name: "from_date",
        label: "From:",
        class: inputdate,
        component: input_date,
        mandatory: false,
    },

    to_date: {
        id: "to_date",
        name: "to_date",
        label: "To:",
        class: inputdate,
        component: input_date,
        mandatory: false,
    },
}

let mapping = [
    ["buyer_org_id", "buyer_org_id"],
    ["from_date", "from_date"],
    ["to_date", "to_date"],
    ["statuses", "statuses"]
]

class ManageReceivedInvoices extends BaseComponent {
    constructor(p) {
        super(p);
        this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
        this.pageNumber = 1;
        //this.recordsPerPage = 15;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
        this.state = {
            isLoading: true,
            viewFilter: false,
            data: []
        }
        this.getRow = this.getRow.bind(this);
        this.getFrame = this.getFrame.bind(this);
        this.company_id = this.session.getLoggedInCompanyId();
        this.closeFilter = this.closeFilter.bind(this);
        this.invoices = [];
    }

    componentDidMount() {
        this.getFrame(1, this.recordsPerFrame);
        this.setState({ data: getInitialState(fields) });
    }

    initialState = getInitialState();

    state = {
        data: deepCopy(this.initialState)
    }

    reset = () => {
        let data = deepCopy(this.initialState);

        this.setState({ data: data, error: undefined });
    };

    onReturn = (ret) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        this.resetError();
        if (ret.error) {
            this.setError(ret.error);
            return;
        }

        let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
        /*eslint no-fallthrough: 0*/
        switch (ret.retFrom) {
            case "VIEW":
                break;
            case 'ACKNOWLEDGE':
            case 'ACCEPT':
            case 'REJECT':
            case 'INITIATEGRN':
            case 'MARK_PAID':
                this.setState((prevState, props) => {
                    this.invoices[updatedArrayIndex] = ret.selectData.header;
                    if (valuePresent(ret.referralId)) {
                        this.invoices[ updatedArrayIndex ].referral_id = ret.referralId;
                    }

                    let newState = deepCopy(prevState);
                    newState.editCount++;
                    return newState;
                });
                break;
            default:
                this.setError(raiseError("Invalid return from ViewReceivedInvoice"));
        }

    }

	onAddEditReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				this.invoices.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
				break;
			case "EDIT":
			case "CANCEL":
			case "ACCEPT":
			case "REJECT":
			case "AMEND":
			case "APPROVE":
			case "APPROVEAMENDMENT":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.invoices[ updatedArrayIndex ] = ret.selectData.header;
                    if (valuePresent(ret.referralId)) {
                        this.invoices[ updatedArrayIndex ].referral_id = ret.referralId;
                    }

					let newState = deepCopy(prevState);
					newState.editCount ++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.invoices.splice(ret.arrayIndex, 1);
				this.setState({editCount: this.state.editCount + 1});
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditInvoice"));
		}
		return ;
	}

    handler = async (action, invoice_id, pageNumber, arrayIndex) => {
        this.pageNumber = pageNumber;
        this.callForm(ViewReceivedInvoice, { action, invoice_id, arrayIndex, pageNumber, ...this.props }, this.onReturn);
    }

    crudHandler = async (action, invoiceId, pageNumber, arrayIndex) => {
        this.pageNumber = pageNumber;
		this.callForm(AddEditInvoice, { action, invoiceId, pageNumber, arrayIndex,  ...this.props }, this.onAddEditReturn);
    }

    getFilteredList = async (offset, recordsPerFrame) => {
        this.resetError();
        try {
            let obj = {};
            copyFormFieldsToDataFields(mapping, this.state.data, obj, fields);
            obj.offset = offset;
            obj.num_recs = recordsPerFrame;
            obj.buyer_org_id = this.session.getLoggedInCompanyId();
            let retData = await receivedInvoiceServiceRoutines.list(
                this.session.getServerInstance('SUBSCRIBER'), this.session.getAccessToken(), obj);
            this.invoices = retData;
            this.setState({ isLoading: false });
        } catch (e) {
            this.setError(raiseError(e.error_message));
            this.setState({ isLoading: false });
        }
    }


    getFrame = async (offset, recordsPerFrame) => {
        try {
            let obj ={};
            obj.offset = offset;
            obj.num_recs = recordsPerFrame;
            obj.buyer_org_id = this.session.getLoggedInCompanyId();
            const retData =
                await receivedInvoiceServiceRoutines.list(this.session.getServerInstance('SUBSCRIBER'),
                    this.session.getAccessToken(), obj);
            this.invoices = this.invoices.concat(retData);
            this.setState({ isLoading: false })
        }
        catch (e) {
            this.setState({ isLoading: false })
            this.setError(raiseError(e.error_message));
        }
    }

    getRow = (data, index, pageNumber, arrayIndex) => {
        const i = index - 1;
        this.pageNumber = pageNumber;
        let key = 0;
        return (
            <tr key={index}>
                <td key={++key}>
                    <button type="button" style={{
                        color: "blue", textDecoration: "underline",
                        cursor: "pointer", border: "none", background: "none", padding: "0",
                        margin: "0", textAlign: "left"
                        }}
                        onClick={() => this.handler("VIEW", data.invoice_id, pageNumber, arrayIndex)}>
                        {data.invoice_number}
                    </button>
                </td>
                <td key={++key}>{data.invoice_date}</td>
                <td key={++key}>{data.seller_org_name}</td>
                <td key={++key}>{data.payment_due_date}</td>
                <td key={++key}>{data.seller_contact_email_id}</td>
                <td key={++key} style={{textAlign:"right"}}>{window.formatCurrency(data.currency, data.total_amount)}</td>
                <td key={++key}><i>{invoice_statuses[data.status].label}</i></td>
                <td key={++key}>
                    {(data.entity_state === "0" && data.deleted === false && !valuePresent(data.referral_id) &&
                      data.owner_org_id === this.company_id) && (
                        <>
                            <span onClick={() =>
                                this.crudHandler("EDIT", data.invoice_id, pageNumber, arrayIndex)}
                                title='Edit' style={{cursor : "pointer"}}>
                                <i className="bi bi-pencil-square" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                            <span onClick={() =>
                                this.crudHandler("DELETE", data.invoice_id, pageNumber, arrayIndex)}
                                title='Delete' style={{cursor : "pointer"}}>
                                <i className="bi bi-trash3" style={{ fontSize: "20" }} ></i>
                            </span>
                            &nbsp;&nbsp;
                            <span onClick={() =>
                                this.crudHandler("APPROVE", data.invoice_id, pageNumber, arrayIndex)}
                                title='Approve' style={{cursor : "pointer"}}>
                                <i className="bi bi-check2" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>)
                    }
                    {(data.status === "3" && !valuePresent(data.referral_id)) && (
                        <>
                            {(!valuePresent(data.receipt_note_number)) &&
                            <>
                                <span onClick={() =>
                                    this.handler("INITIATEGRN", data.invoice_id, pageNumber, arrayIndex)}
                                    title='Process Goods Receipt' style={{cursor : 'pointer'}}>
                                    <i className="bi bi-box-arrow-in-left" style={{ fontSize: 20 }} ></i>
                                </span>&nbsp;&nbsp;
                            </>
                            }
                            <span onClick={() =>
                                this.handler("ACKNOWLEDGE", data.invoice_id, pageNumber, arrayIndex)}
                                    title='Acknowledge' style={{cursor : 'pointer'}}>
                                <i className="bi bi-hand-thumbs-up"
                                        style={{ fontSize: 20, transform: "scaleX(-1)", display: "inline-block" }} ></i>
                            </span>&nbsp;&nbsp;
                            <span onClick={() =>
                                this.handler("REJECT", data.invoice_id, pageNumber, arrayIndex)}
                                title='Reject' style={{cursor : 'pointer'}}>
                                <i className="bi bi-hand-thumbs-down"
                                        style={{ fontSize: 20, transform: "scaleX(-1)", display: "inline-block" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>)
                    }
                    {(data.status === "4" && !valuePresent(data.referral_id)) && (
                        <>
                            {(data.entity_state !== '2') &&
                            <>
                                {(!valuePresent(data.receipt_note_number)) &&
                                <>
                                    <span onClick={() =>
                                        this.handler("INITIATEGRN", data.invoice_id, pageNumber, arrayIndex)}
                                        title='Process Goods Receipt' style={{cursor : 'pointer'}}>
                                        <i className="bi bi-box-arrow-in-left" style={{ fontSize: 20 }} ></i>
                                    </span>&nbsp;&nbsp;
                                </>
                                }
                                <span onClick={() =>
                                    this.handler("ACCEPT", data.invoice_id, pageNumber, arrayIndex)}
                                    title='Accept' style={{cursor : 'pointer'}}>
                                    <i className="bi bi-check2-all" style={{ fontSize: 20 }} ></i>
                                </span>&nbsp;&nbsp;
                            </>
                            }
                            {(data.seller_org_id === data.owner_org_id) &&
                            <>
                                <span onClick={() =>
                                    this.handler("REJECT", data.invoice_id, pageNumber, arrayIndex)}
                                    title='Reject' style={{cursor : 'pointer'}}>
                                    <i className="bi bi-hand-thumbs-down"
                                            style={{ fontSize: 20, transform: "scaleX(-1)", display: "inline-block" }} ></i>
                                </span>&nbsp;&nbsp;
                            </>
                            }
                            {(data.seller_org_id !== data.owner_org_id) &&
                            <>
                                <span onClick={() =>
                                    this.crudHandler("AMEND", data.invoice_id, pageNumber, arrayIndex)}
                                    title='Amend' style={{cursor : "pointer"}}>
                                    <i className="bi bi-pen" style={{ fontSize: "20" }} ></i>
                                </span>&nbsp;&nbsp;
                            </>
                            }
                            {(data.seller_org_id !== data.owner_org_id && data.entity_state === '2') &&
                            <>
                                <span
                                    onClick={() => this.crudHandler("APPROVEAMENDMENT",
                                                        data.invoice_id, pageNumber, arrayIndex)}
                                    title='Approve' style={{cursor : "pointer"}}>
                                    <i className="bi bi-check2" style={{ fontSize: "20" }} ></i>
                                </span>&nbsp;&nbsp;
                                <span onClick={() => this.crudHandler("CANCEL", data.invoice_id, pageNumber, arrayIndex)}
                                    title={window.getLabelLiteral("CANCEL")}
                                    style={{ cursor: 'pointer' }}>
                                    <i className="bi bi-x-square" style={{ fontSize: 20 }} ></i>
                                </span>
                            </>
                            }
                        </>)
                    }
                    {(data.status === "5" && !valuePresent(data.referral_id)) && (
                        <>
                            <span onClick={() =>
                                this.handler("MARK_PAID", data.invoice_id, pageNumber, arrayIndex)}
                                title='Mark Payment Done' style={{cursor : 'pointer'}}>
                                <i className="bi bi-check2-circle" style={{ fontSize: 20 }} ></i>
                            </span>&nbsp;&nbsp;
                        </>)
                    }
                    {valuePresent(data.referral_id) &&
                        <>
                            <span onClick={() =>
                                this.withdrawReferral(data, i, "referral_id")}
                                title='Withdraw' style={{cursor : "pointer"}}>
                                <i className="bi bi-skip-backward" style={{ fontSize: "20" }} ></i>
                            </span>&nbsp;&nbsp;
                        </>
                    }
                </td>
            </tr>
        )
    }

    getHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>Invoice No.</th>
                <th key={++key}>Invoice Date</th>
                <th key={++key}>Seller</th>
                <th key={++key}>Payment Due Date</th>
                <th key={++key}>Seller Contact</th>
                <th key={++key} style={{textAlign:"right"}}>Amount(tax excl.)</th>
                <th key={++key}>Status</th>
                <th key={++key}></th>
            </tr>
        );
    }

    table_buttons = [
        {
            literal: "Filter",
            func: () => {
                this.setState({ viewFilter: true });
            }
        },
		{
			literal: "Add",
			func: () => {
				const ucp = this.session.getUserCompanyProps(this.session.getLoggedInCompanyId(),
															this.session.getLoggedInParentCompanyId());
				if (!(ucp.org_is_subscriber)) {
					if (ucp.partner_relationship_type === 'S') {
						this.setError(raiseError(window.parseLiteral("CANNOT_CREATE_INVOICE"
															, this.session.getLoggedInCompanyName())));
						return false;
					}
				}
				this.crudHandler("ADD", null, this.pageNumber, 0);
			}
		}
    ];

    getCompanyList = async (control, searchText) => {
        let retData;
        try {
            retData = await receivedInvoiceServiceRoutines.fetch_first_level_network(this.session.getServerInstance("SUBSCRIBER"),
                this.session.getAccessToken(),
                this.session.getLoggedInCompanyId(),
                null,
                null,
                searchText);
            return retData;
        } catch (e) {
            control.error = true;
            if (e.error_message !== null && e.error_message !== undefined) {
                control.errorMsg = e.error_message;
            }
            else {
                control.errorMsg = e.message;
            }
        }
    };

    formatDataList = (array) => {
        let formatted_array = [];
        array.map((value, index) => {
            let s = value.org_name;
            formatted_array.push(s);
            return null;
        });
        return formatted_array;
    };

    selectBuyerId = (index, list) => {
        let data = deepCopy(this.state.data);
        data.buyer_org_id = list[index].org_id;
        data.buyer_org_name = list[index].org_name;
        this.setState({ data });
    };

    clearSelection = () => {
        let data = deepCopy(this.state.data);
        data.buyer_org_id = ''
        data.buyer_org_name = ''
        this.setState({ data });
    }

    closeFilter = () => {
        this.setState({ viewFilter: false })
        let data = deepCopy(this.state.data);
        data.buyer_org_id = '';
        data.buyer_org_name = '';
        data.from_date = '';
        data.to_date = '';
        data.statuses = '';
        this.setState({ data });
    }

    onFilter = () => {
        this.getFilteredList(1, this.recordsPerFrame);
        this.closeFilter()
    }

    handleChange = (new_value) => {
        let data = deepCopy(this.state.data);
        data.statuses = new_value;
        this.setState({ data });
    }

    render = () => {
        let buttons = [];
        let filterContent = (
            <div className="container pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-6">
                        <FormField
                            field={fields.buyer_org_name}
                            controlProps={this.controlProps}
                            getDataList={(control, searchtext) => { return this.getCompanyList(control, searchtext) }}
                            formatDataList={this.formatDataList}
                            selectOption={this.selectBuyerId}
                            clearSelection={this.clearSelection}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField field={fields.statuses} controlProps={this.controlProps} handleChange={this.handleChange} />
                    </div>
                    <div className="col-sm-6">
                        <FormField field={fields.from_date} controlProps={this.controlProps} />
                    </div>
                    <div className="col-sm-6">
                        <FormField field={fields.to_date} controlProps={this.controlProps} />
                    </div>
                </div>
            </div>
        );

        const tableclass = "table table-striped border-dark";
        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
            {/*
            <div className="row p-0 m-0 g-0">
                <div className="col-sm-12 h3 text-center" > Received Invoices </div>
            </div>
            */}
            <div className="col-sm-12">
                <GenericTable
                    pageHeading={this.getBreadcrumb()}
                    pageHeadingClass="h6 text-left"
                    data={this.invoices}
                    recordsPerPage={this.recordsPerPage}
                    getRow={this.getRow}
                    getHeader={this.getHeader}
                    tableclass={tableclass}
                    getFrame={this.getFrame}
                    recordsPerFrame={this.recordsPerFrame}
                    pageNumber={this.pageNumber}
                    isLoading={this.state.isLoading}
                    button={this.table_buttons}
                />
            </div>
            {this.state.viewFilter && (
                <Modal_generic_content
                    title="Filter"
                    btn1="List"
                    btn2="Close"
                    onClick={this.closeFilter}
                    onConfirm={() => { return this.onFilter(); }}
                    modalContent={filterContent}
                />
            )}
            {this.state.viewFilter && <Backdrop />}
        </div>

        let frm = <GenericForm
            datablk={datablk} buttons={buttons}
            controlProps={this.controlProps}
            {...this.props} />

        return frm;
    }
}

export default ManageReceivedInvoices;
