import React from 'react';
import {
    //GenericComponent,
    NarrowButton,
    GenericForm,
    FormField,

    MultiRecFormField,
    MultiRec,
    MultiRecHandler,

    fieldValidations,
    getInitialState,
    copyFormFieldsToDataFields,
    copyDataFieldsToFormFields,
    deepCopy,
    addToDate,
    today,
    raiseError,
    extractNumberStr,
    valuePresent,

    filteredselect,
    filtered_select,
    searchselect,
    search_select,
    document_number,
    documentnumber,
    input_date,
    inputdate,
    genericstring,
    generic_string,
    genericcheckbox,
    generic_checkbox,
    itemid,
    item_id,
    genericdecimal,
    generic_decimal,
    amounttextbox,
    amount_textbox,
    unit_measure,
    unitmeasure,
    username,
    user_name,
    emailid,
    email_id,
    contactphonenumber,
    contact_phone_number,
    genericinteger,
    generic_integer,
    Currency,
    currency,
    taxtype,
    tax_type,
    FileSet,
    file_set,


} from 'WebUI_Framework';

import { BaseComponent } from '../common/BaseComponent'

import './AddEditInvoice.css';
import SubmitReferral from '../referral/SubmitReferral';

import receivedInvoiceServiceRoutines from '../received_invoices/ReceivedInvoiceServiceRoutines';

import commonServiceRoutines from "../common/CommonServiceRoutines";
import referralServiceroutines from "../referral/ReferralServiceRoutines";
import PartnerList from '../common/PartnerList'

import AddressFilter from '../common/AddressFilter'
import PurchaseOrderList from '../common/PurchaseOrderList'
import Attachments from '../common/Attachments'

import { formatAddress } from '../common/utils'

const LINE_ITEMS_MREC = "LINE_ITEMS";
const TAX_MREC = "TAX";
const DOCUMENT_TYPE = "8";

const header_fields = {
    invoice_number: {
        id: "invoice_number",
        name: "invoice_number",
        label: window.getLabelLiteralWithColon("INVOICE_NUMBER"),
        class: documentnumber,
        component: document_number,
        mandatory: true,
        //initialValue : "ABCD1234",
    },

    invoice_date: {
        id: "invoice_date",
        name: "invoice_date",
        label: window.getLabelLiteralWithColon("INVOICE_DATE"),
        class: inputdate,
        component: input_date,
        mandatory: true,
    },

    seller_address_id: {
        id: "seller_address_id",
        name: "seller_address_id",
        //class: genericstring,
        //component: generic_string,
        class: filteredselect,
        component: filtered_select,
        label: window.getLabelLiteralWithColon("SELLER_ADDRESS"),
        mandatory: true
    },

    seller_address: {
        id: "seller_address",
        name: "seller_address",
        label: window.getLabelLiteralWithColon("SELLER_ADDRESS"),
        class: searchselect,
        component: search_select,
        mandatory: true,
    },

    owner_org_id: {
        id: "owner_org_id",
        name: "owner_org_id",
        label: window.getLabelLiteralWithColon("DOCUEMNT_OWNER_ORG"),
        class: filteredselect,
        component: filtered_select,
        mandatory: false
    },

    seller_org_id: {
        id: "seller_org_id",
        name: "seller_org_id",
        label: window.getLabelLiteralWithColon("SELLER_COMPANY_NAME"),
        class: filteredselect,
        component: filtered_select,
        mandatory: false
    },

    seller_org_name: {
        id: "seller_org_name",
        name: "seller_org_name",
        label: window.getLabelLiteralWithColon("SELLER_COMPANY_NAME"),
        class: searchselect,
        component: search_select,
        mandatory: true,
    },

    buyer_org_id: {
        id: "buyer_org_id",
        name: "buyer_org_id",
        label: window.getLabelLiteralWithColon("BUYER_COMPANY_NAME"),
        class: filteredselect,
        component: filtered_select,
        mandatory: false
    },

    buyer_org_name: {
        id: "buyer_org_name",
        name: "buyer_org_name",
        label: window.getLabelLiteralWithColon("BUYER_COMPANY_NAME"),
        class: searchselect,
        component: search_select,
        mandatory: true,
    },

    buyer_address_id: {
        id: "buyer_address_id",
        name: "buyer_address_id",
        //class: genericstring,
        //component: generic_string,
        class: filteredselect,
        component: filtered_select,
        label: window.getLabelLiteralWithColon("BUYER_ADDRESS"),
        mandatory: true
    },

    buyer_address: {
        id: "buyer_address",
        name: "buyer_address",
        label: window.getLabelLiteralWithColon("BUYER_ADDRESS"),
        class: searchselect,
        component: search_select,
        mandatory: true,
    },

    //order_id component has to be made
    order_number: {
        id: "order_number",
        name: "order_number",
        label: window.getLabelLiteralWithColon("ORDER_NUMBER"),
        class: documentnumber,
        component: search_select,
        mandatory: false,
    },

    order_issue_date: {
        id: "order_issue_date",
        name: "order_issue_date",
        label: window.getLabelLiteralWithColon("ORDER_ISSUE_DATE"),
        class: inputdate,
        component: input_date,
        mandatory: false,
    },

    payment_due_date: {
        id: "payment_due_date",
        name: "payment_due_date",
        label: window.getLabelLiteralWithColon("PAYMMENT_DUE_DATE"),
        class: inputdate,
        component: input_date,
        mandatory: true,
    },

    seller_contact_user_name: {
        id: "seller_contact_user_name",
        name: "seller_contact_user_name",
        label: window.getLabelLiteralWithColon("SELLER_CONTACT_PERSON"),
        class: username,
        component: user_name,
        mandatory: true,
    },

    seller_contact_email_id: {
        id: "seller_contact_email_id",
        name: "seller_contact_email_id",
        label: window.getLabelLiteralWithColon("SELLER_CONTACT_EMAIL"),
        class: emailid,
        component: email_id,
        mandatory: true,
    },

    seller_contact_phone_num: {
        id: "seller_contact_phone_num",
        name: "seller_contact_phone_num",
        label: window.getLabelLiteralWithColon("SELLER_CONTACT_PHONE"),
        class: contactphonenumber,
        component: contact_phone_number,
        mandatory: true,
    },

    buyer_contact_user_name: {
        id: "buyer_contact_user_name",
        name: "buyer_contact_user_name",
        label: window.getLabelLiteralWithColon("BUYER_CONTACT_PERSON"),
        class: username,
        component: user_name,
        mandatory: true,
    },

    buyer_contact_email_id: {
        id: "buyer_contact_email_id",
        name: "buyer_contact_email_id",
        label: window.getLabelLiteralWithColon("BUYER_CONTACT_EMAIL"),
        class: emailid,
        component: email_id,
        mandatory: true,
    },

    buyer_contact_phone_num: {
        id: "buyer_contact_phone_num",
        name: "buyer_contact_phone_num",
        label: window.getLabelLiteralWithColon("BUYER_CONTACT_PHONE"),
        class: contactphonenumber,
        component: contact_phone_number,
        mandatory: true,
    },

    notes: {
        id: "notes",
        name: "notes",
        label: window.getLabelLiteralWithColon("NOTES"),
        class: genericstring,
        component: generic_string,
        mandatory: false,
    },

    discount_percentage: {
        id: "discount_percentage",
        name: "discount_percentage",
        label: window.getLabelLiteralWithColon("DISCOUNT_PERCENTAGE"),
        class: genericdecimal,
        component: generic_decimal,
        mandatory: false,
        //initialValue: "0",
    },

    discount_amount: {
        id: "discount_amount",
        name: "discount_amount",
        label: window.getLabelLiteralWithColon("DISCOUNT_AMOUNT"),
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
        //initialValue: "1234244.00",
    },

    currency: {
        id: "currency",
        name: "currency",
        label: window.getLabelLiteralWithColon("CURRENCY"),
        class: Currency,
        component: currency,
        mandatory: true,
        //initialValue: "INR",
    },

    total_tax_amount: {
        id: "total_tax_amount",
        name: "total_tax_amount",
        label: "",
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
        //initialValue: "1234244.00",
    },

    total_invoice_amount: {
        id: "total_invoice_amount",
        name: "total_invoice_amount",
        label: "",
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
        //initialValue: "1234244.00",
    },

    total_amount: {
        id: "total_amount",
        name: "total_amount",
        label: "",
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
        //initialValue: "1234244.00",
    },

    attachments: {
        id: "attachments",
        name: "attachments",
        label: window.getLabelLiteralWithColon("ATTACHMENTS"),
        class: file_set,
        component: FileSet,
        mandatory: false,
        //initialValue: "1234244.00",
    },

}

let header_mapping = [
    ["invoice_number", "invoice_number"],
    ["invoice_date", "invoice_date"],
    ["owner_org_id", "owner_org_id"],
    ["seller_org_id", "seller_org_id"],
    ["seller_org_name", "seller_org_name"],
    ["seller_org_name", "seller_org_name"],
    ["seller_address_id", "seller_address_id"],
    ["buyer_org_id", "buyer_org_id"],
    ["buyer_org_name", "buyer_org_name"],
    ["buyer_address_id", "buyer_address_id"],
    ["order_number", "order_number"],
    ["order_issue_date", "order_issue_date"],
    ["payment_due_date", "payment_due_date"],
    ["seller_contact_user_name", "seller_contact_user_name"],
    ["seller_contact_email_id", "seller_contact_email_id"],
    ["seller_contact_phone_num", "seller_contact_phone_num"],
    ["buyer_contact_user_name", "buyer_contact_user_name"],
    ["buyer_contact_email_id", "buyer_contact_email_id"],
    ["buyer_contact_phone_num", "buyer_contact_phone_num"],
    ["notes", "notes"],
    ["discount_percentage", "discount_percentage"],
    ["discount_amount", "discount_amount"],
    ["currency", "currency"],
    ["payment_due_date", "payment_due_date"],
    ["version", "version"],
    ["entity_state", "entity_state"],
    ["attachments", "attachments"],
];

const details_fields = {
    item_index: {
        id: "item_index",
        name: "item_index",
        label: window.getLabelLiteralWithColon(""),
        class: genericinteger,
        component: generic_integer,
        mandatory: true
    },

    seller_item_id: {
        id: "seller_item_id",
        name: "seller_item_id",
        label: window.getLabelLiteralWithColon("SELLER_ITEM_ID"),
        class: itemid,
        component: item_id,
        mandatory: true
    },


    seller_item_description: {
        id: "seller_item_description",
        name: "seller_item_description",
        label: window.getLabelLiteralWithColon("ITEM_DESCRIPTION"),
        class: genericstring,
        component: generic_string,
        mandatory: true,
    },

    hsn_code: {
        id: "hsn_code",
        name: "hsn_code",
        label: window.getLabelLiteralWithColon("HSN_SAC_CODE"),
        class: genericstring,
        component: generic_string,
        mandatory: false,
    },

    invoice_serial_number: {
        id: "invoice_serial_number",
        name: "invoice_serial_number",
        label: window.getLabelLiteralWithColon("INVOICE_SERIAL_NUMBER"),
        class: genericinteger,
        component: generic_integer,
        mandatory: false,
    },

    notes: {
        id: "notes",
        name: "notes",
        label: window.getLabelLiteralWithColon("NOTES"),
        class: genericstring,
        component: generic_string,
        mandatory: false,
    },

    quantity: {
        id: "quantity",
        name: "quantity",
        label: window.getLabelLiteralWithColon("QUANTITY"),
        class: genericdecimal,
        component: generic_decimal,
        mandatory: true,
    },

    rate_per_unit: {
        id: "rate_per_unit",
        name: "rate_per_unit",
        label: window.getLabelLiteralWithColon("RATE_PER_UNIT"),
        class: amounttextbox,
        component: amount_textbox,
        mandatory: true,
    },

    discount_percentage: {
        id: "discount_percentage",
        name: "discount_percentage",
        label: window.getLabelLiteralWithColon("DISCOUNT_PERCENTAGE"),
        class: genericdecimal,
        component: generic_decimal,
        mandatory: false,
    },

    discount_amount: {
        id: "discount_amount",
        name: "discount_amount",
        label: window.getLabelLiteralWithColon("DISCOUNT_AMOUNT"),
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
    },

    unit_of_measure: {
        id: "unit_of_measure",
        name: "unit_of_measure",
        label: window.getLabelLiteralWithColon("UNIT"),
        class: unitmeasure,
        component: unit_measure,
        mandatory: true,
    },

    amount: {
        id: "amount",
        name: "amount",
        label: window.getLabelLiteralWithColon("AMOUNT"),
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
    },

    deleted: {
        id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
        class: genericcheckbox, component: generic_checkbox, mandatory: false
    },
};

let details_mapping = [
    ["invoice_serial_number", "invoice_serial_number"]
    , ["seller_item_id", "seller_item_id"]
    , ["seller_item_description", "seller_item_description"]
    , ["hsn_code", "hsn_code"]
    , ["notes", "notes"]
    , ["unit_of_measure", "unit_of_measure"]
    , ["quantity", "quantity"]
    , ["discount_percentage", "discount_percentage"]
    , ["discount_amount", "discount_amount"]
    , ["rate_per_unit", "rate_per_unit"]
    , ["deleted", "deleted"]
    , ["amount", null]
];

const tax_fields = {
    tax_type: {
        id: "tax_type",
        name: "tax_type",
        label: window.getLabelLiteralWithColon("TAX_TYPE"),
        class: taxtype,
        component: tax_type,
        mandatory: false,
    },

    tax_percentage: {
        id: "tax_percentage",
        name: "tax_percentage",
        label: window.getLabelLiteralWithColon("TAX_PERCENTAGE"),
        class: genericdecimal,
        component: generic_decimal,
        mandatory: false,
    },

    tax_amount: {
        id: "tax_amount",
        name: "tax_amount",
        label: window.getLabelLiteralWithColon("TAX_AMOUNT"),
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
    },


};

let tax_mapping = [
    ["tax_type", "tax_type"]
    , ["tax_percentage", "tax_percentage"]
    , ["tax_amount", null]
];

const TotalAmountContext = React.createContext({ tta: 0, tia: 0, ta: 0 });

class TotalAmount extends React.Component {

    render() {
        let total = this.context;

        const { atype, ...rest } = this.props;

        return <FormField valueSuperseed={total[atype].toString()}  {...rest} />
    }
}

TotalAmount.contextType = TotalAmountContext;

class AddEditInvoice extends BaseComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;
        this.state.step = 1;

        this.readOnly = false;
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();
        this.action = props.action;
        this.excpAccepted = false;

        this.logged_in_org_id = this.session.getLoggedInCompanyId();
        this.logged_in_org_name = this.session.getLoggedInCompanyName();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_org_id === this.parent_org_id);

        this.invoiceId = props.invoiceId;

        this.number = props.number;
        this.date = props.date;
        this.sellerOrgId = props.sellerOrgId;

        this.invoice = { header: {}, details: [{}] };

        this.page_title = "";
        this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
        this.expandBuyer(false);
        this.expandSeller(false);
        if (this.action === "ADD") {
            this.state.data.invoice_date = this.session.getCurrentDate();
            this.expandBuyer(true);
            this.expandSeller(true);
        }
        else if (this.action === "FORMINVOICE") {
            this.state.data.invoice_date = this.session.getCurrentDate();
            this.expandBuyer(true);
            this.expandSeller(true);
            this.invoice = JSON.parse(JSON.stringify(props.invoice));
            this.invoice.details[0] = {};
        }
        else if (this.action === "EDIT" || this.action === "AMEND" || this.action === "EDITINVOICE") {
            this.state.step = 2;
        }
        else if (this.action === "APPROVE" || this.action === "CANCEL" ||
            this.action === 'APPROVEAMENDMENT') {
            this.expandBuyer(true);
            this.expandSeller(true);
            this.state.step = 2;
            this.readOnly = true;
        }
        else if (this.action === "VIEW" || this.action === "VIEWINVOICE" || this.action === 'VIEWWITHNUMBER') {
            this.expandBuyer(true);
            this.expandSeller(true);
            this.readOnly = true;
            this.state.step = 2;
        }
        else if (this.action === "DELETE") {
            this.readOnly = true;
        }
        if (this.action !== "EDITINVOICE" && this.action !== "VIEWINVOICE")
            this.page_title += " " + window.getLabelLiteral("INVOICE_TITLE");

        this.lineItemsHandler = new MultiRecHandler(this.session, details_fields, details_mapping, LINE_ITEMS_MREC);

        this.tax_mrecs = {};
    }


    expandBuyer = (flg) => {
        this.aria_expanded_buyer = flg;
        this.show_buyer = (this.aria_expanded_buyer) ? "show" : "";
        this.aria_collapsed_buyer = (this.aria_expanded_buyer) ? "expanded" : "collapsed";
    }

    expandSeller = (flg) => {
        this.aria_expanded_seller = flg;
        this.show_seller = (this.aria_expanded_seller) ? "show" : "";
        this.aria_collapsed_seller = (this.aria_expanded_seller) ? "expanded" : "collapsed";
    }

    initialState = getInitialState(header_fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error, excp_obj) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.invoice, error,
                referralId: excp_obj?.referral_id
            });
        else console.log("LOADED AS A PARENT");
    }

    fetchUsingNumberAndDate = async (number, date, orgId) => {
        return await receivedInvoiceServiceRoutines.fetchUsingNumberAndDate(this.session, number, date, orgId);
    }

    fetch = async (invoiceId) => {
        return await receivedInvoiceServiceRoutines.fetchPurchaseInvoice(this.session, invoiceId);
    }

    fetchAmended = async (invoiceId) => {
        return await receivedInvoiceServiceRoutines.fetchAmended(this.session, invoiceId);
    }

    delete = async (invoice) => {
        let retData;
        try {
            await receivedInvoiceServiceRoutines.delete(this.session, invoice.header);
        }
        catch (e) {
            //this.setError(raiseError(e.error_message));
            throw e;
        }

        return retData
    }

    loadSellerAddress = (data) => {
        data.seller_address = formatAddress(this.invoice.header.seller_address);
    }

    loadBuyerAddress = (data) => {
        data.buyer_address = formatAddress(this.invoice.header.buyer_address);
    }

    populateDefaultInformation = async (data) => {
        try {
            const buyerDetails = await commonServiceRoutines.fetchCompany(this.session, data.buyer_org_id);

            if (data.buyer_org_name === "")
                data.buyer_org_name = buyerDetails.org_name;
            if (data.buyer_contact_user_name === "")
                data.buyer_contact_user_name = buyerDetails.contact_name;
            if (data.buyer_contact_email_id === "")
                data.buyer_contact_email_id = buyerDetails.contact_email_id;
            if (data.buyer_contact_phone_num === "")
                data.buyer_contact_phone_num = buyerDetails.contact_phone_number;

            const buyerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, data.buyer_org_id);

            if (data.buyer_address_id === "")
                data.buyer_address_id = buyerAddress.address_id;
            if (data.buyer_address === "")
                data.buyer_address = formatAddress(buyerAddress);

        } catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
        return;
    }

    fetchOrder = async (buyerOrgId, orderIssueDate, orderNumber) => {
        if (this.order) {
            if ((this.order.header.order_issue_date === orderIssueDate) &&
                (this.order.header.order_number === orderNumber) &&
                (this.order.header.buyer_org_id === buyerOrgId)) {
                return;
            }
        }

        if (!valuePresent(buyerOrgId) || !valuePresent(orderIssueDate) || !valuePresent(orderNumber) ||
            buyerOrgId === '' || orderIssueDate === '' || orderNumber === '') {
            this.order = null;
            return;
        }

        let retData;
        try {
            retData = await receivedInvoiceServiceRoutines.fetch_purchase_order(this.session,
                buyerOrgId, orderIssueDate, orderNumber);
            this.order = retData;
        }
        catch (e) {
            //this.setError(raiseError(e.error_message));
            console.log(e.error_message);
            this.order = null;
        }
    }

    checkPartnership = async () => {
        let partnershipRec;
        try {
            partnershipRec = await commonServiceRoutines.fetchPartnershipRec(this.session,
                                            this.logged_in_org_id, this.invoice.header.seller_org_id);
        } catch (e) {}

        if (valuePresent(partnershipRec)) {
            if (partnershipRec.partner_relationship_type === 'D') {
                throw new Error("Cannot create purchase invoice for "+this.invoice.header.seller_org_id);
            }
        }
        else {
            partnershipRec =
                await commonServiceRoutines.fetchPartnershipRec(this.session,
                                    this.invoice.header.seller_org_id, this.logged_in_org_id);
            if (partnershipRec.partner_relationship_type === 'S') {
                throw new Error("Cannot create purchase invoice for "+this.invoice.header.seller_org_id);
            }
        }

        return 
    }

    componentDidMount = async () => {
        let newData_1, newData_2, newData;
        try {
            switch (this.action) {
                case 'ADD':
                    newData = deepCopy(this.state.data);
                    newData = this.lineItemsHandler.dataArrayToFormFields(this.invoice.details, newData);
                    this.invoice.header.buyer_org_id = this.logged_in_org_id;
                    this.invoice.header.buyer_org_name = this.logged_in_org_name;
                    newData.buyer_org_id = this.logged_in_org_id;
                    newData.buyer_org_name = this.logged_in_org_name;
                    await this.populateDefaultInformation(newData);
                    this.setState({ loading: false, data: newData });
                    break;
                case "FORMINVOICE":
                    newData = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.invoice.header, newData);
                    newData = this.lineItemsHandler.dataArrayToFormFields(this.invoice.details, newData);
                    for (let i = 0; i < this.invoice.details.length; i++) {
                        if (valuePresent(this.invoice.details[i].tax_details)) {
                            this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
                            newData = this.tax_mrecs[TAX_MREC + i].dataArrayToFormFields(this.invoice.details[i].tax_details, newData);
                        }
                    }
                    this.loadBuyerAddress(newData);
                    this.loadSellerAddress(newData);
                    await this.checkPartnership();

                    this.setState({ loading: false, data: newData });
                    break;
                /*eslint no-fallthrough: 0*/
                case 'AMEND':
                case 'CANCEL':
                case 'VIEW':
                case 'VIEWINVOICE':
                case 'VIEWWITHNUMBER':
                case 'APPROVE':
                case 'EDIT':
                case 'EDITINVOICE':
                case 'DELETE':
                case 'APPROVEAMENDMENT':
                    switch (this.action) {
                        /*eslint no-fallthrough: 0*/
                        case 'AMEND':
                        case 'CANCEL':
                        case 'APPROVEAMENDMENT':
                            this.invoice = await this.fetchAmended(this.invoiceId);
                            break;
                        case 'VIEWWITHNUMBER':
                            this.invoice = await this.fetchUsingNumberAndDate(this.number, this.date, this.sellerOrgId);
                            break;
                        default:
                            this.invoice = await this.fetch(this.invoiceId);
                            break;
                    }

                    newData_1 = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.invoice.header, newData_1);
                    newData_2 = this.lineItemsHandler.dataArrayToFormFields(this.invoice.details, newData_1);
                    for (let i = 0; i < this.invoice.details.length; i++) {
                        if (valuePresent(this.invoice.details[i].tax_details)) {
                            this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
                            newData_2 =
                                this.tax_mrecs[TAX_MREC + i].dataArrayToFormFields(this.invoice.details[i].tax_details, newData_2);
                        }
                    }

                    switch (this.action) {
                        case 'DELETE':
                            this.setState({ data: newData_2 });
                            if (this.invoice.deleted === true) {
                                this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " [" + this.invoiceId + "]"));
                                this.backHandler(this.action);
                            }
                            await this.delete(this.invoice);
                            this.backHandler(this.action);
                            break;
                        case 'EDIT':
                        case 'EDITINVOICE':
                            if (newData_2.order_issue_date !== "" && newData_2.order_number !== "") {
                                await this.fetchOrder(newData_2.buyer_org_id, newData_2.order_issue_date, newData_2.order_number);
                            }
                        default:
                            this.loadBuyerAddress(newData_2);
                            this.loadSellerAddress(newData_2);

                            this.setState({ loading: false, data: newData_2 });
                            break;
                    }

                    break;
                default:
                    this.setError(raiseError("AddEditInvoice: Invalid action [" + this.action + "]"));
                    this.backHandler(this.action, this.error);
                    break;
            }
        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    low_computeAmount = (data, i) => {
        let ras = data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)];
        if (!ras) ras = "";
        const rate = Number(extractNumberStr(ras));

        let qs = data[this.lineItemsHandler.getFieldIdentifier("quantity", i)];
        if (!qs) qs = "";
        const quantity = Number(extractNumberStr(qs));

        let dps = data[this.lineItemsHandler.getFieldIdentifier("discount_percentage", i)];
        if (!dps) dps = "";
        const dp = Number(extractNumberStr(dps));

        let ds = data[this.lineItemsHandler.getFieldIdentifier("discount_amount", i)];
        if (!ds) ds = "";
        const d = Number(extractNumberStr(ds));

        return ((rate * ((100 - dp) / 100)) - d) * quantity;
    }

    low_computeTaxAmount = (ia, data, i, detail_i) => {
        let tps = data[this.tax_mrecs[TAX_MREC + detail_i].getFieldIdentifier("tax_percentage", i)];
        if (!tps) tps = "";
        const tp = Number(extractNumberStr(tps));
        const ta = (ia * tp) / 100;

        return ta;
    }

    computeTotalAmount = () => {
        let ta = 0;
        let tia = 0;
        let tta = 0;

        try {
            const dc = this.lineItemsHandler.getCount(this.state.data);
            for (let i = 0; i < dc; i++) {
                const ia = this.low_computeAmount(this.state.data, i);
                ta += ia;
                tia += ia;
                if (this.tax_mrecs[TAX_MREC + i]) {
                    const tc = this.tax_mrecs[TAX_MREC + i].getCount(this.state.data);
                    for (let j = 0; j < tc; j++) {
                        const xa = (this.low_computeTaxAmount(ia, this.state.data, j, i));
                        ta += xa;
                        tta += xa;
                    }
                }
            }
        } catch (e) {
            console.log(e);
            ta = 0;
            tta = 0;
            tia = 0;
        }

        return { ta, tta, tia };
    }

    populateSellerOrgInfo = async (data) => {
        const sellerDetails = await commonServiceRoutines.fetchCompany(this.session, data.seller_org_id);

        data.seller_org_name = sellerDetails.org_name;
        data.seller_contact_user_name = sellerDetails.contact_name;
        data.seller_contact_email_id = sellerDetails.contact_email_id;
        data.seller_contact_phone_num = sellerDetails.contact_phone_number;

        const sellerAddress = await commonServiceRoutines.fetchDefaultAddress(this.session, data.seller_org_id);

        data.seller_address_id = sellerAddress.address_id;
        data.seller_address = formatAddress(sellerAddress);
    }

    partnerSelectOption = async (org_id, org_name) => {
        let data = deepCopy(this.state.data)
        data.seller_org_name = org_name;
        data.seller_org_id = org_id;

        if (org_id !== undefined && org_id !== null && org_id !== '')
            await this.populateSellerOrgInfo(data);
        this.setState({ data });
    }

    populateSellerInfoFromOrder = (data, order) => {
        data.seller_org_id = order.header.seller_org_id;
        data.seller_org_name = order.header.seller_org_name;
        data.seller_contact_user_name = order.header.seller_contact_user_name;
        data.seller_contact_email_id = order.header.seller_contact_email_id;
        data.seller_contact_phone_num = order.header.seller_contact_phone_num;

        data.seller_address_id = order.header.seller_address_id;
        data.seller_address = formatAddress(order.header.seller_address);
    }

    populateBuyerInfoFromOrder = (data, order) => {
        data.buyer_org_name = order.header.buyer_org_name;
        data.buyer_contact_user_name = order.header.billing_contact_user_name;
        data.buyer_contact_email_id = order.header.billing_contact_email_id;
        data.buyer_contact_phone_num = order.header.billing_contact_phone_num;

        data.buyer_address_id = order.header.billing_address_id;
        data.buyer_address = formatAddress(order.header.billing_address);
    }

    poSelectOption = async (order_number, order_issue_date, buyer_org_id, seller_org_id, seller_org_name) => {
        let data = deepCopy(this.state.data)
        data.order_number = order_number;
        data.order_issue_date = order_issue_date;
        if (valuePresent(seller_org_id) && seller_org_id !== '') {
            data.seller_org_id = seller_org_id;
            data.seller_org_name = seller_org_name;
        }
        this.setState({ loading: true });
        try {
            await this.fetchOrder(data.buyer_org_id, data.order_issue_date, data.order_number);

            if (this.order) {
                this.populateSellerInfoFromOrder(data, this.order);
                /* this.populateBuyerInfoFromOrder(data, this.order);
                 * Should this be populated from the order or should it be
                 * as per the ORG setup of the self organization
                 * Since the document is originating from here
                 * the self organiation is the owner of the info and
                 * the info present in order is only reference for the buyer
                 */
            }

            /* This is to only populate those fields not in order */
            /* This condition is less likely to happen if the Sales order
             * originates from within BIOP */
            if (!(data.seller_address_id) || data.seller_address_id === "" ||
                !(data.seller_contact_user_name) || data.seller_contact_user_name === "" ||
                !(data.seller_contact_email_id) || data.seller_contact_email_id === "" ||
                !(data.seller_contact_phone_num) || data.seller_contact_phone_num === "") {
                let new_data = deepCopy(data);
                if (valuePresent(seller_org_id) && seller_org_id !== '') {
                    await this.populateSellerOrgInfo(new_data);
                    if (!(data.seller_address_id) || data.seller_address_id === "") {
                        data.seller_address_id = new_data.data.seller_address_id;
                        data.seller_address = new_data.data.seller_address;
                    }
                    if (!(data.seller_contact_user_name) || data.seller_contact_user_name === "") {
                        data.seller_contact_user_name = new_data.seller_contact_user_name;
                    }
                    if (!(data.seller_contact_email_id) || data.seller_contact_email_id === "") {
                        data.seller_contact_email_id = new_data.seller_contact_email_id;
                    }
                    if (!(data.seller_contact_phone_num) || data.seller_contact_phone_num === "") {
                        data.seller_contact_phone_num = new_data.seller_contact_phone_num;
                    }
                }
            }

            data.currency = (this.order?.header?.currency) ? this.order.header.currency : '';

            if (this.order?.header?.payment_period) {
                let payment_date = addToDate(today(this.session),
                    this.order.header.payment_period,
                    this.order.header.payment_period_unit);
                data.payment_due_date = payment_date;
            }

            this.setState({ loading: false, data });
        } catch (e) {
            console.log(this.action);
            console.log(e);
            this.setState({ loading: false });
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            //this.backHandler(this.action, this.error);
        }
    }

    poOnChange = (event) => {
        let data = deepCopy(this.state.data)
        data.order_number = event.target.value;
        this.setState({ data });

    }

    addNewRow = () => {
        let data = this.state.data;
        this.resetError();
        let [newData, new_list] = this.lineItemsHandler.addMultiRecRow(data, this.invoice.details);
        this.invoice.details = new_list;
        this.setState({ data: newData });
    }

    button_inputs = [
        {
            literal: "Add",
            func: () => this.addNewRow()
        }
    ]

    deleteRow = (rowData, i) => {
        if (this.invoice.details[i].version === null ||
            this.invoice.details[i].version === undefined) {
            this.resetError();
            let [newData, updated_list] = this.lineItemsHandler.deleteMultiRecRow(this.state.data, this.invoice.details, i);
            this.invoice.details = updated_list;
            this.setState({ data: newData });
        }
        else {
            this.invoice.details[i].deleted = true;
        }
    }

    computeAmount = (data, i) => {
        let rate;
        let d_pcnt;
        let d_amt;
        let d_quantity;
        try {
            rate = Number(
                extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)].trim()));
            d_pcnt = Number(
                extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("discount_percentage", i)].trim()));
            d_amt = Number(
                extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("discount_amount", i)].trim()));
            d_quantity = Number(
                extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("quantity", i)].trim()));
        } catch (e) {
            console.log(e);
            return "";
        }
        //let quantity = Number(data[this.lineItemsHandler.getFieldIdentifier("quantity", i)]);
        if (isNaN(d_quantity)) return "";

        return ((((rate * (100 - d_pcnt)) / 100) - d_amt) * d_quantity).toString();
    }

    deleteTaxRow = (rowData, i, detail_i) => {
        this.resetError();
        let [newData, updated_list] =
            this.tax_mrecs[TAX_MREC + detail_i].deleteMultiRecRow(this.state.data, this.invoice.details[detail_i].tax_details, i);
        this.invoice.details[detail_i].tax_details = updated_list;
        if (this.invoice.details[detail_i].tax_details.length === 0) {
            this.invoice.details[detail_i].tax_details = undefined;
            delete this.tax_mrecs[TAX_MREC + i];
        }

        this.setState({ data: newData });
    }

    getTaxHeader = () => {
        let key = 0;
        let style = {};
        if (this.readOnly)
            style = { color: "white" };
        return (
            <tr key={++key}>
                <th key={++key}>{window.getLabelLiteral("TAX_TYPE")}</th>
                <th key={++key} style={{ textAlign: "right" }}>{window.getLabelLiteral("TAX_PERCENTAGE")}</th>
                <th key={++key} style={{ textAlign: "right" }}>{window.getLabelLiteral("TAX_AMOUNT")}</th>
                <th key={++key} style={style}>{window.getLabelLiteral("DELETE")}</th>
            </tr>
        );
    }

    computeTaxAmount = (invoiceAmount, data, i, detail_i) => {
        let ta = 0
        try {
            const ia = Number(extractNumberStr(invoiceAmount));
            const tp = Number(extractNumberStr(data[this.tax_mrecs[TAX_MREC + detail_i].getFieldIdentifier("tax_percentage", i)]));
            ta = (ia * tp) / 100;
        } catch (e) {
            /* We ignore any errors here */
            console.log(e);
        }

        return ta;
    }

    getTaxRow = (rowData, index, detail_i, invoiceAmount) => {
        const i = index - 1;
        let data = deepCopy(this.state.data);
        let key = 0;
        let style = { borderBottom: "0px solid" };

        const ca = (this.computeTaxAmount(invoiceAmount, data, i, detail_i)).toString();

        const taMarginLeft = (this.readOnly) ? "" : "0.75rem";

        let r = <React.Fragment key={index}>
            <tr>
                <td key={++key} style={style}>
                    <MultiRecFormField field={tax_fields.tax_type} controlProps={this.controlProps} index={i}
                        multiRecId={TAX_MREC + detail_i}
                        style={{ width: "100%" }}
                        disabled={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={tax_fields.tax_percentage} controlProps={this.controlProps} index={i}
                        formattingInputs={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
                        multiRecId={TAX_MREC + detail_i}
                        style={{ width: "70%", textAlign: "right", marginLeft: "3rem" }}
                        disabled={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={tax_fields.tax_amount} controlProps={this.controlProps} index={i}
                        currency={data.currency}
                        valueSuperseed={ca}
                        multiRecId={TAX_MREC + detail_i}
                        style={{ width: "100%", textAlign: "right", marginLeft: taMarginLeft }}
                        readOnly={true} />
                </td>
                {!(this.readOnly) &&
                    <td key={++key} style={style}>
                        <div style={{ paddingLeft: "20px", cursor: "pointer" }}>
                            <span onClick={() => { return this.deleteTaxRow(rowData, i, detail_i); }}>
                                <i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
                            </span>
                        </div>
                    </td>
                }
            </tr>
        </React.Fragment>;

        return r;
    }

    addTax = (i) => {
        let data = deepCopy(this.state.data);
        if (!(this.invoice.details[i].tax_details)) {
            this.invoice.details[i].tax_details = [];
            this.tax_mrecs[TAX_MREC + i] = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC + i);
        }

        let [newData, new_list] = this.tax_mrecs[TAX_MREC + i].addMultiRecRow(data, this.invoice.details[i].tax_details);
        this.invoice.details[i].tax_details = new_list;
        this.setState({ data: newData });
    }

    getHeader = () => {
        let key = 0;
        let style = {};
        if (this.readOnly)
            style = { color: "white" };
        return (
            <tr key={++key}>
                <th key={++key} >{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
                <th key={++key}>{window.getLabelLiteral("HSN_SAC_CODE")}</th>
                <th key={++key} >{window.getLabelLiteral("QUANTITY")}</th>
                <th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
                <th key={++key} style={{ textAlign: "right" }}>{window.getLabelLiteral("RATE")}</th>
                {/*<th key={++key} style={{ textAlign: "right" }}>{window.getLabelLiteral("DISCOUNT_PERCENTAGE")}</th>*/}
                <th key={++key} style={{ textAlign: "right" }}>{window.getLabelLiteral("DISCOUNT_AMOUNT")}</th>
                <th key={++key} style={{ textAlign: "right" }}>{window.getLabelLiteral("NET_AMOUNT")}</th>
                <th key={++key} style={style}>{window.getLabelLiteral("DELETE")}</th>
                <th key={++key} style={style}>{window.getLabelLiteral("TAX")}</th>
            </tr>
        );
    }

    getRow = (rowData, index) => {
        const i = index - 1;

        let delete_button = 1;
        if (rowData.version >= 0)
            delete_button = 2;

        let view_delete_option = 0;
        if (!this.readOnly && delete_button === 1) view_delete_option = 1;
        else if (!this.readOnly && delete_button === 2) view_delete_option = 2;

        let select = false;
        let selectList = [];
        if (this.order) {
            select = true;
            selectList = deepCopy(this.order.details);
        }

        selectList.forEach(item => {
            if (item.hasOwnProperty('seller_item_id')) {
                item['item_id'] = item['seller_item_id'];
                item['item_description'] = item['seller_item_description'];
                delete item['seller_item_id'];
                delete item['seller_item_description'];
            }
        });

        let itemIdChange = (event, i) => {
            let data = deepCopy(this.state.data);
            /*
            * We have to set item id also because the state change done in
            * genericcomponent will get overwritten by the following change
            */
            let selected_i = event.target.selectedIndex;

            if (selected_i === 0) {
                data[this.lineItemsHandler.getFieldIdentifier("seller_item_id", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("unit_of_measure", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("quantity", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("seller_item_description", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("hsn_code", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("notes", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("discount_percentage", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("discount_amount", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("item_index", i)] = "";
            } 
            else {
                let selected_rec = selectList[selected_i-1];
                data[this.lineItemsHandler.getFieldIdentifier("seller_item_id", i)] = selected_rec.item_id;
                data[this.lineItemsHandler.getFieldIdentifier("unit_of_measure", i)] = selected_rec.unit_of_measure;
                data[this.lineItemsHandler.getFieldIdentifier("quantity", i)] = selected_rec.quantity;
                data[this.lineItemsHandler.getFieldIdentifier("seller_item_description", i)] = selected_rec.item_description;
                data[this.lineItemsHandler.getFieldIdentifier("hsn_code", i)] = 
                                                valuePresent(selected_rec.hsn_code)?selected_rec.hsn_code:"";
                data[this.lineItemsHandler.getFieldIdentifier("notes", i)] = selected_rec.item_description;
                data[this.lineItemsHandler.getFieldIdentifier("discount_percentage", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("discount_amount", i)] = "";
                data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)] = selected_rec.rate_per_unit;
                data[this.lineItemsHandler.getFieldIdentifier("item_index", i)] = selected_i - 1;
            }

            this.setState({ data });
        }

        let data = deepCopy(this.state.data);
        const ca = this.computeAmount(data, i);
        let style = valuePresent(this.invoice.details[i].tax_details) ? { borderBottom: "0px solid" } : undefined;

        let key = 0;
        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key} style={style}>
                    {(select) &&
                        <MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
                            multiRecId={LINE_ITEMS_MREC}
                            readOnly={((this.readOnly) || (rowData.version !== undefined && rowData.version !== null))}
                            select={select} selectList={selectList}
                            style={{ width: "95%" }}
                            onChange={(event) => { return itemIdChange(event, i); }} />
                    }
                    {!(select) &&
                        <MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
                            multiRecId={LINE_ITEMS_MREC}
                            style={{ width: "95%" }}
                            readOnly={((this.readOnly) || (rowData.version !== undefined && rowData.version !== null))} />
                    }
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.seller_item_description} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.hsn_code} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        maxLength={"20"}
                        style={{ width: "100%" }}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.quantity} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        formattingInputs={{ minimumFractionDigits: 0, maximumFractionDigits: 6 }}
                        style={{ width: "75%", textAlign: "right"}} readOnly={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.unit_of_measure} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "80%" }} disabled={this.readOnly || select === true} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.rate_per_unit} controlProps={this.controlProps} index={i}
                        currency={data.currency}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "100%", textAlign: "right" }}
                        readOnly={this.readOnly} />
                </td>
                {/*
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.discount_percentage} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        formattingInputs={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
                        style={{ width: "65%", textAlign: "right", marginLeft: "34%" }}
                        readOnly={this.readOnly} />
                </td>
                */}
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.discount_amount} controlProps={this.controlProps} index={i}
                        currency={data.currency}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "100%", textAlign: "right" }}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField field={details_fields.amount} controlProps={this.controlProps} index={i}
                        currency={data.currency}
                        value={ca}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "100%", textAlign: "right" }}
                        readOnly={true} />
                </td>
                <td key={++key} style={style}>
                    {(view_delete_option === 1) &&
                        <div style={{ paddingLeft: "20px", cursor: "pointer" }}>
                            <span onClick={() => { return this.deleteRow(rowData, i); }} data-toggle="tooltip" title="Delete">
                                <i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
                            </span>
                        </div>
                    }
                    {(view_delete_option === 2) &&
                        <div className="ml-2" style={{ paddingTop: "4px", paddingLeft: "20px" }}>
                            <MultiRecFormField field={details_fields.deleted} controlProps={this.controlProps}
                                multiRecId={LINE_ITEMS_MREC}
                                style={{}} index={i} disabled={this.readOnly} />
                        </div>
                    }
                </td>
                {(!this.readOnly) &&
                    <td key={++key} style={style}>
                        <div style={{ cursor: "pointer" }}>
                            <span onClick={() => { return this.addTax(i); }} data-toggle="tooltip" title="Tax">
                                <i className="bi bi-plus-circle" style={{ fontSize: 20 }}></i>
                            </span>
                        </div>
                    </td>
                }
            </tr>
            {(valuePresent(this.invoice.details[i].tax_details) && this.invoice.details[i].tax_details.length > 0) &&
                <tr key={++key}>
                    <td key={++key} colSpan="5"></td>
                    <td key={++key} colSpan="4" className="m-0 p-0">
                        <MultiRec key={++key} tableClass={"table table-condensed"}
                            getRow={(rowdata, index, pn, k, ainfo) => { return this.getTaxRow(rowdata, index, i, ainfo); }}
                            getHeader={this.getTaxHeader}
                            data={this.invoice.details[i].tax_details}
                            buttons={null}
                            tbodyStyle={{ borderTop: "2px solid", borderColor: "LightGray" }}
                            additionalInfo={ca}
                        />
                    </td>
                </tr>
            }
        </React.Fragment>
        return r;
    }

    getFooter = () => {
        const style1 = { borderBottom: "0px", margin: "0px" };
        const style2 = { borderBottom: "0px", textAlign: "right", margin: "0px" };
        let key = 0;
        return <React.Fragment>
            <tr key={++key}>
                <th key={++key} colSpan="6" style={style1}></th>
                <th key={++key} colSpan="1" style={style2}>
                    {window.getLabelLiteral("INVOICE_AMOUNT")}
                </th>
                <th key={++key} colSpan="1" style={style1}>
                    <TotalAmount
                        atype="tia"
                        field={header_fields.total_invoice_amount}
                        style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
                        currency={this.state.data.currency}
                        controlProps={this.controlProps}
                        readOnly={true}
                    />
                </th>
                <th key={++key} style={style1} />
                <th key={++key} style={style1} />
            </tr>
            <tr key={++key}>
                <th key={++key} colSpan="6" style={style1}></th>
                <th key={++key} colSpan="1" style={{ borderBottom: "1px solid", textAlign: "right" }}>
                    {window.getLabelLiteral("TAX_AMOUNT")}
                </th>
                <th key={++key} colSpan="1" style={{ borderBottom: "1px solid" }}>
                    <TotalAmount
                        atype="tta"
                        field={header_fields.total_tax_amount}
                        style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
                        currency={this.state.data.currency}
                        controlProps={this.controlProps}
                        readOnly={true}
                    />
                </th>
                <th key={++key} style={style1} />
                <th key={++key} style={style1} />
            </tr>
            <tr key={++key}>
                <th key={++key} colSpan="6" style={style1}></th>
                <th key={++key} colSpan="1" style={style2}>
                    {window.getLabelLiteral("TOTAL_AMOUNT")}
                </th>
                <th key={++key} colSpan="1"
                    style={style1}>
                    <TotalAmount
                        atype="ta"
                        field={header_fields.total_amount}
                        style={{ width: "100%", textAlign: "right", fontWeight: "bold" }}
                        currency={this.state.data.currency}
                        controlProps={this.controlProps}
                        readOnly={true}
                    />
                </th>
                <th key={++key} style={style1} />
                <th key={++key} style={style1} />
            </tr>
        </React.Fragment>;
    }

    selectAddressIdSeller = (address_id, formatted_address, address) => {
        let data = deepCopy(this.state.data);
        data.seller_address = formatted_address;
        data.seller_address_id = address_id;
        this.setState({ data });
    }

    clearSelectionSellerAddress = () => {
        let data = deepCopy(this.state.data);
        data.seller_address = ''
        data.seller_address_id = ''
        this.setState({ data });
    }

    selectAddressIdBuyer = (address_id, formatted_address, address) => {
        let data = deepCopy(this.state.data);
        data.buyer_address = formatted_address;
        data.buyer_address_id = address_id;
        this.setState({ data });
    };

    clearSelectionBuyerAddress = () => {
        let data = deepCopy(this.state.data);
        data.buyer_address = ''
        data.buyer_address_id = ''
        this.setState({ data });
    }

    proceed = async () => {
        this.resetError();
        let r = fieldValidations(header_fields, this.state.data);
        if (!r.status) {
            console.log(r)
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            this.setError(r);
            return false;
        }

        let fo = (this.order) ? false : true;
        if (this.order) {
            if ((this.state.data.order_issue_date !== this.order.header.order_issue_date) ||
                (this.state.data.order_number !== this.order.header.order_number) ||
                (this.state.data.buyer_org_id !== this.order.header.buyer_org_id)) {
                fo = true;
            }
        }

        let data = deepCopy(this.state.data);
        if (fo) {
            if (data.order_issue_date !== "" && data.order_number !== "") {
                await this.fetchOrder(data.buyer_org_id, data.order_issue_date, data.order_number);
                console.log(this.order);
                if (this.order) {
                    data.buyer_org_name = this.order.header.buyer_org_name;
                }
            }
            else {
                this.order = null
            }
        }
        this.expandBuyer(false);
        this.expandSeller(false);

        this.setState({ step: 2, data });
    }

    data_render = () => {
        const ta = this.computeTotalAmount();
        let key = 0;

        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 me-2 pe-2">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb([window.getLabelLiteral(this.action) + " " + window.getLabelLiteral("INVOICE")])}
                    </div>
                    <hr></hr>
                    <div className="col-sm-6">
                        <PurchaseOrderList
                            selectOption={this.poSelectOption}
                            field={header_fields.order_number}
                            issueDateFieldName="order_issue_date"
                            controlProps={this.controlProps}
                            onChange={this.poOnChange}
                            sellerOrgId={this.state.data.seller_org_id}
                            readOnly={((this.readOnly) || (this.state.step > 1) ||
                                ((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.order_issue_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) ||
                                (this.action !== "ADD") || (this.action !== "FORMINVOICE"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.invoice_number}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) ||
                                ((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.invoice_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) ||
                                ((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.currency}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) ||
                                ((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.payment_due_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) ||
                                ((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.discount_percentage}
                            style={{}}
                            formattingInputs={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.discount_amount}
                            style={{}}
                            currency={this.state.data.currency}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.notes}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        &nbsp;
                    </div>
                    <div className="col-sm-6 mt-2 mb-3 ">
                        <Attachments
                            field={header_fields.attachments}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                            fileAttributeRequired={true}
                        />
                    </div>

                    <div className="accordion accordion-flush" id="sellerdetails">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="sellerdetailsdata">
                                <div className="col-sm-12 h4 text-left">
                                    <div className="col-sm-2">
                                        <button className=
                                            {`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_seller}`}
                                            id="collapseOne-button"
                                            style={{
                                                color: "black",
                                                backgroundColor: "white",
                                            }}
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded={this.aria_expanded_seller}
                                            aria-controls="collapseOne">
                                            <h5>Seller Details</h5>
                                        </button>
                                    </div>
                                </div>
                            </h2>
                            <div id="collapseOne" className={`accordion-collapse collapse ${this.show_seller}`}
                                aria-labelledby="sellerdetailsdata" data-bs-parent="#sellerdetails">
                                <div className={`accordion-body mx-0 gx-0 px-0`} id="sellerdetails-1">
                                    <div className="row    p-0 m-0 g-0 ">
                                        <div className="col-sm-6" >
                                            <PartnerList
                                                field={header_fields.seller_org_id}
                                                keyName={"org_id"}
                                                descName={"org_name"}
                                                selectOption={this.partnerSelectOption}
                                                controlProps={this.controlProps}
                                                relationship='S'
                                                readOnly={((this.readOnly) || (this.state.step > 1) ||
                                                    ((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <AddressFilter
                                                field={header_fields.seller_address_id}
                                                controlProps={this.controlProps}
                                                orgId={this.state.data.seller_org_id}
                                                selectOption={this.selectAddressIdSeller}
                                                clearSelection={this.clearSelectionSellerAddress}
                                                readOnly={this.readOnly}
                                                dependency={[this.state.data.seller_org_id]}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.seller_contact_user_name}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.seller_contact_email_id}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.seller_contact_phone_num}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion accordion-flush" id="buyerdetails">
                        <div className="accordion-item">
                            <h2 className="accordion-header " id="buyerdetailsdata">
                                <div className="col-sm-12 h4 text-left">
                                    <div className="col-sm-2">
                                        <button
                                            className=
                                            {`accordion-button btn link-dark my-0 py-0 mx-0 gx-0 px-0 ${this.aria_collapsed_buyer}`}
                                            id="collapseTwo-button"
                                            type="button" data-bs-toggle="collapse"
                                            style={{
                                                color: "black",
                                                backgroundColor: "white",
                                            }}
                                            data-bs-target="#collapseTwo" aria-expanded={this.aria_expanded_buyer}
                                            aria-controls="collapseTwo">
                                            <h5>Buyer Details</h5>
                                        </button>
                                    </div>
                                </div>
                            </h2>
                            <div id="collapseTwo" className={`accordion-collapse collapse ${this.show_buyer}`}
                                aria-labelledby="buyerdetailsdata" data-bs-parent="#buyerdetails">
                                <div className={`accordion-body mx-0 gx-0 px-0`} id="buyerdetails-1">
                                    <div className="row    p-0 m-0 g-0 ">
                                        <div className="col-sm-6">
                                            <AddressFilter
                                                field={header_fields.buyer_address_id}
                                                controlProps={this.controlProps}
                                                orgId={this.logged_in_org_id}
                                                selectOption={this.selectAddressIdBuyer}
                                                clearSelection={this.clearSelectionBuyerAddress}
                                                readOnly={((this.readOnly) || (this.state.step > 1) ||
                                                    ((this.action !== "ADD") && (this.action !== "FORMINVOICE")))}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.buyer_contact_user_name}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.buyer_contact_email_id}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <FormField
                                                field={header_fields.buyer_contact_phone_num}
                                                controlProps={this.controlProps}
                                                readOnly={this.readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(this.state.step === 1) &&
                        <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                            <NarrowButton value="Proceed" onClick={this.proceed} />
                            <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                        </div>
                    }

                    {(this.state.step === 2) &&
                        <TotalAmountContext.Provider value={ta}>
                            <div key={++key} style={{ margin: 0, padding: 0, height: "25px" }}></div>
                            <MultiRec
                                key={++key}
                                getRow={this.getRow}
                                getHeader={this.getHeader}
                                getFooter={this.getFooter}
                                data={this.invoice.details}
                                buttons={(this.readOnly) ? null : this.button_inputs}
                                tfootStyle={{ borderTop: "0.1rem solid" }}
                                tbodyStyle={{ borderTop: "2.5px solid" }}
                            />
                        </TotalAmountContext.Provider>
                    }
                </div>

                {(this.state.step === 2) &&
                    <div className="row  p-0 m-0 g-0 " >
                        <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                            {!(this.readOnly) &&
                                <NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
                            }
                            {(this.readOnly && (this.action === "APPROVE")) &&
                                <NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
                            }
                            {(this.readOnly && (this.action === "APPROVEAMENDMENT")) &&
                                <NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
                            }
                            {(this.readOnly && (this.action === "CANCEL")) &&
                                <NarrowButton value="Cancel" onClick={this.submitForm} key={(++key).toString()} />
                            }
                            <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                        </div>
                    </div>
                }
            </div>
        );

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    loading_render = () => {
        let buttons = [];

        let datablk = <div className="container border border-0 pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

    validateForm = () => {
        let r = fieldValidations(header_fields, this.state.data);
        if (!r.status) {
            console.log(r);
            this.expandBuyer(true);
            this.expandSeller(true);
            this.setError(r);
            return false;
        }
        r = this.lineItemsHandler.multiRecFieldValidations(this.state.data);
        if (!r.status) {
            console.log(r);
            this.setState({ error: r });
            return false;
        }

        return true;
    }

    onReturn = async (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
            case 'BACK':
                break;
            case 'PROCEED':
                this.excpAccepted = true;
                return await this.submitForm();
            default:
                break;
        }
    }

    handleExceptions = (referral) => {
		this.callForm(SubmitReferral, { ...this.props,  referral_action: "PROCEED", referral }, this.onReturn);
    }

    submitForm = async () => {
        this.resetError();
        if (!this.validateForm()) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            return;
        }

        let data = deepCopy(this.state.data);
        copyFormFieldsToDataFields(header_mapping, data, this.invoice.header, header_fields);
        this.lineItemsHandler.formFieldsToDataArray(this.state.data, this.invoice.details);
        for (let i = 0; i < this.invoice.details.length; i++) {
            if (this.invoice.details[i].tax_details) {
                this.tax_mrecs[TAX_MREC + i].formFieldsToDataArray(data, this.invoice.details[i].tax_details);
            }
        }

        try {
            switch (this.action) {
                case "FORMINVOICE":
                case "ADD":
                    await receivedInvoiceServiceRoutines.add(this.session, this.invoice);
                    this.invoice =
                        await receivedInvoiceServiceRoutines.fetchUsingNumberAndDate(this.session,
                            this.invoice.header.invoice_number,
                            this.invoice.header.invoice_date,
                            this.invoice.header.seller_org_id);

                    break;
                case "EDIT":
                case "EDITINVOICE":
                    await receivedInvoiceServiceRoutines.modify(this.session, this.invoice);
                    this.invoice = await this.fetch(this.invoiceId);
                    break;
                case "AMEND":
                    await receivedInvoiceServiceRoutines.amend(this.session, this.invoice);
                    this.invoice = await this.fetch(this.invoiceId);
                    break;
                case "CANCEL":
                    await receivedInvoiceServiceRoutines.cancelAmendment(this.session, this.invoice);
                    this.invoice = await this.fetch(this.invoiceId);
                    break;
                case "APPROVE":
                    {
                        let referral;
                        if (!(this.excpAccepted)) {
                            referral = await referralServiceroutines.check_rules(this.session, {
                                str_action : "approve"
                                ,referral_org_id : this.logged_in_org_id
                                ,document_org_id : this.invoice.header.seller_org_id
                                ,document_type: DOCUMENT_TYPE
                                ,document_date: this.invoice.header.invoice_date
                                ,document_number: this.invoice.header.invoice_number
                            });
                        }
                        if (this.excpAccepted === true || referral.excp.count === 0) {
                            await receivedInvoiceServiceRoutines.approve(this.session, this.invoice);
                            this.invoice = await this.fetch(this.invoiceId);
                            break;
                        }
                        else {
                            return this.handleExceptions(referral);
                        }
                    }
                case "APPROVEAMENDMENT":
                    {
                        let referral;
                        if (!(this.excpAccepted)) {
                            referral = await referralServiceroutines.check_rules(this.session, {
                                str_action : "approve_amendment"
                                ,referral_org_id : this.logged_in_org_id
                                ,document_org_id : this.invoice.header.seller_org_id
                                ,document_type: DOCUMENT_TYPE
                                ,document_date: this.invoice.header.invoice_date
                                ,document_number: this.invoice.header.invoice_number
                            });
                        }
                        if (this.excpAccepted === true || referral.excp.count === 0) {
                            await receivedInvoiceServiceRoutines.approveAmendment(this.session, this.invoice);
                            //this.invoice = await this.fetch(this.invoiceId);
                            this.invoice = await this.fetchAmended(this.invoiceId);
                            break;
                        }
                        else {
                            return this.handleExceptions(referral);
                        }
                    }
                default:
                    break;
            }

            this.backHandler(this.action);
        }
        catch (e) {
            console.log(e);
            if (this.handleFormSubmissionErr(e, this.excpAccepted)) {
                this.backHandler(this.action, undefined, e.excp_obj);
            }
        }
    }

}


export default AddEditInvoice;
