import {
    GenericComponent,
    GenericForm,
    FormField,
    valuePresent,
    copyDataFieldsToFormFields,
    copyFormFieldsToDataFields,
    NarrowButton,
    //InnerHTML,
    //GenericTable,

    getInitialState,
    deepCopy,
    raiseError,

    //document_number,
    //documentnumber,
    generic_textarea,
    generictextarea,
    generic_string,
    genericstring,
    //genericgenrefcode,
    //generic_genrefcode,
    //inputdate,
    //input_date,

} from 'WebUI_Framework';

import emailServiceRtn from './EmailServiceRoutines.js'

const fields = {
    message_id: {
        id: "message_id", name: "message_id", label: "",
        class: genericstring, component: generic_string, mandatory: true,
    },
    to: {
        id: "to", name: "to", label: window.getLabelLiteralWithColon("EMAIL_TO"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    subject: {
        id: "subject", name: "subject", label: window.getLabelLiteralWithColon("SUBJECT"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    message_body: {
        id: "message_body", name: "message_body", label: window.getLabelLiteralWithColon("EMAIL_BODY"),
        class: generictextarea, component: generic_textarea, mandatory: true,
    },
};

const mapping = [
    ["message_id", "message_id"],
    ["to", "to"],
    ["subject", "subject"],
    ["message_body", "message_body"],
];

class ReplyToEmail extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;

        this.readOnly = false;
 
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();

        this.action = props.action
        this.message_id = props.messageId;
        this.reply = {};
        this.email = {};
        if (valuePresent(props.email)) {
            this.email = props.email;
        }
        if (!valuePresent(this.message_id)) {
            throw new Error("Either email or message_id should be present");
        }

        this.logged_in_org_id = this.session.getLoggedInCompanyId();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_org_id);

        this.referral = props.referral;
    }

    initialState = getInitialState(fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                pageNumber: this.props.pageNumber,
                selectData: this.reply, error
            });
        else console.log("LOADED AS A PARENT");
    }

    fetch = async () => {
        const email = await emailServiceRtn.fetch(this.session, this.message_id);
        email.date = email.date.split('T')[0]

        return email;
    }

    componentDidMount = async () => {

        const newData = this.state.data;
        if (!valuePresent(this.props.email))
            this.email = await this.fetch();

        this.reply.to = this.email.from;
        this.reply.subject = 'Re: '+ this.email.subject
        this.reply.message_body = '\r\n\r\n'+ this.email.message_body;
        this.reply.message_id = this.message_id;

        copyDataFieldsToFormFields(mapping, this.reply, newData);

        this.setState({ data: newData})
        return;
    }

    getBody = (body) => {
        return <div className="col-sm-10 "
                style={{width:"81%"}}
            >
            <FormField
                field={fields.message_body}
                controlProps={this.controlProps}
                rows={"20"}
                showToolbar={false}
                enablePreview={false}
            />
        </div>;
    }

    getRow = (data, index, pageNumber, arrayIndex) => {
        let style = { borderBottom: "0px solid" };
        let key = index;
        this.pageNumber = pageNumber;

        return( <tr key={++key}>
                <td style={style}>{data.file_name}</td>
                <td style={style}>{data.mime_type}</td>
                <td style={style}>{data.size}</td>
            </tr>
        );
        
    }

    getHeader() {
        let key = 0;
        return (
        <tr key={++key}>
            <td>{window.getLabelLiteral("FILE_NAME")}</td>
            <td>{window.getLabelLiteral("MIME_TYPE")}</td>
            <td>{window.getLabelLiteral("SIZE")}</td>
        </tr>
        );
    }

    render = () => {
        let key=0;
        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb([window.getLabelLiteral(this.action)])}
                    </div>
                    <hr></hr>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.to}
                            controlProps={this.controlProps}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-12 justify-content-start">
                        <FormField
                            field={fields.subject}
                            controlProps={this.controlProps}
                            style={{
                                width: "90%"
                            }}
                            containerClass={"ms-0"}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <>
                        {this.getBody(fields.message_body)}
                    </>
                </div>
                <div className="row  p-0 m-0 g-0 " >
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                        <NarrowButton value="Send" onClick={() => { return this.submitForm("SEND"); }} key={(++key).toString()} />
                    </div>
                </div>
            </div>
        )

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    submitForm = async() => {
        this.resetError();
        try {
            copyFormFieldsToDataFields(mapping, this.state.data, this.reply, fields);
            const reply = deepCopy(this.reply);
            delete reply.to;

            await emailServiceRtn.reply(this.session, reply);

            this.backHandler(this.action);
        }
        catch (e) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            console.log(this.reply);
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }

};

export default ReplyToEmail;


