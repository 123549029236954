import { valuePresent, FormField, MultiRecFormField } from 'WebUI_Framework';
import { useState } from "react";
import commonServiceRoutines from './CommonServiceRoutines'

function SalesInvoiceList(props) {
	if (typeof(props.selectOption) !== "function") {
		throw new Error("SalesInvoiceList: Invalid input selectOption");
	}

	if (props.field === null || props.field === undefined) {
		throw new Error("SalesInvoiceList: Field definition must be passed");
	}

	if (typeof(props.dateFieldName) !== 'string') {
		throw new Error("SalesInvoiceList: Date Field Name must be passed");
	}
	let invoiceDate = (props.controlProps.getState()).data[props.dateFieldName];;

	if (props.controlProps === null || props.controlProps === undefined) {
		throw new Error("SalesInvoiceList: controlProps definition must be passed");
	}

    let buyerOrgId;
    if (valuePresent(props.buyerOrgId) && props.buyerOrgId !== "") {
        buyerOrgId = props.buyerOrgId;
    }

	let readOnly = false;
	if (props.readOnly !== null && props.readOnly !== undefined) {
		readOnly = props.readOnly;
	}
	let onChange = (event) => {
		if (props.onChange) {
			return props.onChange(event);
		}
	};

	let inMultiRec = false;
	let i;
	if (props.inMultiRec !== null && props.inMultiRec !== undefined) {
		inMultiRec = props.inMultiRec;
		if (props.i === null || props.i === undefined) {
			throw new Error("PartnerList: iterator i is necessary in a multirec field");
		}
		i = props.i;
	}

	const session = props.controlProps.cInstance.session;
	if (session === null || session === undefined) {
		throw new Error("SalesInvoiceList: Unable to deduce session");
	}
	const accessToken = session.getAccessToken();
	if (accessToken === null || accessToken === undefined) {
		throw new Error("SalesInvoiceList: Unable to deduce accessToken");
	}
	const loggedInCompanyId = session.getLoggedInCompanyId();
	if (loggedInCompanyId === null || loggedInCompanyId === undefined) {
		throw new Error("SalesInvoiceList: Unable to deduce loggedInCompanyId");
	}
	const parentCompanyId = session.getLoggedInParentCompanyId();
	if (parentCompanyId === null || parentCompanyId === undefined) {
		throw new Error("SalesInvoiceList: Unable to deduce parentCompanyId");
	}
	//const isSubscriber = (loggedInCompanyId === parentCompanyId);

	let [invoiceNumber, setInvoiceNumber] = useState(!(props.invoice_number)?"":props.invoice_number);

	let formatDataList = (array) => {
		let formatted_array = [];
		if (array.length > 0) {
			array.map((value, index) => {
				let s = value.invoice_date + " " + value.invoice_number + " " + value.seller_org_name.substring(0, 15);
				formatted_array.push(s)
				return s;
			})
		}
		return formatted_array;
	}

	let clearSelection = () => {
		invoiceNumber = '';
		props.selectOption({});
		setInvoiceNumber(invoiceNumber);
	}

	let local_selectOption = (index, list) => {
		setInvoiceNumber(list[index].invoice_number);
		return props.selectOption(list[index]);
	}

	let local_getDataList = async (control, searchText) => {
		if (searchText === '') {
			searchText = invoiceNumber;
		}
		try {
			const retData =
				await commonServiceRoutines.salesInvoiceList(session,
                        {
                            invoiceNumber: searchText,
                            invoiceDate,
                            sellerOrgId: loggedInCompanyId,
                            buyerOrgId
                        });
			return retData;
		}
		catch (e) {
			control.error = true;
			if (e.error_message !== null && e.error_message !== undefined) {
				control.errorMsg = e.error_message;
			}
			else {
				control.errorMsg = e.message;
			}
		}
	}

	return (inMultiRec) ?
			(
				<MultiRecFormField
					field={ props.field }
					controlProps={ props.controlProps }
					getDataList={(control, searchtext) => { return local_getDataList(control, searchtext);}}
					formatDataList={ formatDataList }
					selectOption={ local_selectOption }
					clearSelection={clearSelection}
					/*dependency={[invoiceDate]}*/
					readOnly={readOnly}
					onChange={onChange}
					index={i}
					style={props.style}
					width2={props.width2}
					w_pcnt={props.w_pcnt}
					w_pcnt1={props.w_pcnt1}
					w_pcnt2={props.w_pcnt2}
					multiRecId={props.multiRecId}
					value={props.value}
				/>
			)
		:
			(
				<FormField
					field={ props.field }
					controlProps={ props.controlProps }
					getDataList={(control, searchtext) => { return local_getDataList(control, searchtext);}}
					formatDataList={ formatDataList }
					selectOption={ local_selectOption }
					clearSelection={clearSelection}
					/*dependency={[invoiceDate]}*/
					readOnly={readOnly}
					onChange={onChange}
					style={props.style}
					width2={props.width2}
					w_pcnt={props.w_pcnt}
					w_pcnt1={props.w_pcnt1}
					w_pcnt2={props.w_pcnt2}
				/>
			);

}

export default SalesInvoiceList;
