import React from 'react';
import {
    GenericComponent,
    GenericForm,
    FormField,
    getInitialState,
    deepCopy,
    GenericTable,
    raiseError,
    input_date,
    inputdate,
    genericcheckbox,
    generic_checkbox,
    filteredselect,
    filtered_select,
    //valuePresent,
    genericstring,
    generic_string
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';

import emailServiceRtn from './EmailServiceRoutines.js';
import ProcessEmail from './ProcessEmail.js';
import ReplyToEmail from './ReplyToEmail.js';
import AddEditInvoice from '../received_invoices/AddEditInvoice';
import AddEditOrder from '../sales_orders/AddEditOrder';
import PartnerList from '../common/PartnerList';
import { copyValues } from '../common/utils';

const fields = {
    from_date: {
        id: "from_date", name: "from_date", label: window.getLabelLiteralWithColon("FROM_DATE"),
        class: inputdate, component: input_date, mandatory: true,
    },
    processed: {
        id: "processed", name: "processed", label: window.getLabelLiteral("PROCESSED"),
        class: genericcheckbox, component: generic_checkbox, mandatory: false
    },
    sender_org_id: {
        id: "sender_org_id", name: "sender_org_id", label: window.getLabelLiteralWithColon("SENDER_ORG"),
        class: filteredselect, component: filtered_select, mandatory: false
    },
    subject: {
        id: "subject", name: "subject", label: window.getLabelLiteralWithColon("SUBJECT"),
        class: genericstring, component: generic_string, mandatory: false
    },
}


class ManageEmails extends GenericComponent {
    constructor(p) {
        super(p);
        this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
        this.pageNumber = 1;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");

        this.rocServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken =  this.session.getAccessToken();
        this.logged_in_org_id = this.session.getLoggedInCompanyId();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();

        this.message_id = null;
        this.emails = [];
        this.email = {};
        let d = new Date();
        d.setFullYear(d.getFullYear() - 1)
        d.setDate(d.getDate() + 1);
        this.criteria = {
            from_date : d.toISOString().split('T')[0]
        };

        this.state.editCount = 0;
        this.state.isLoading = true;
        this.state.modal_in_progress = false;
        this.state.data.from_date = this.criteria.from_date;
        this.state.data.processed = false;

        this.action = "";
        this.arrayIndex = -1;
    }

    initialState = getInitialState(fields);
    state = {
        data : deepCopy(this.initialState),
    }

    getFrame = async (offset,recordsPerFrame) => {
        let retData;
        try {
            this.criteria = copyValues(this.criteria, this.state.data, ["from_date", "subject", "processed", "sender_org_id"]);
            retData = await emailServiceRtn.list(this.session, {
                offset,
                num_recs: recordsPerFrame,
                ...this.criteria
            });
            this.setState({isLoading : false})
            if (offset > 1 && retData.length > 0) {
                this.emails = this.emails.concat(retData);
            }
            else {
                this.emails = retData;
            }
        }
        catch(e) {
            this.setError(raiseError(e.error_message));
        }
    }

    componentDidMount = async () => {
        try {
            await this.getFrame(1, this.recordsPerFrame);
            this.setState({isLoading: false})
        } catch (e) {
            this.setState({isLoading: false})
        }
    }

    onReturn = (ret) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        this.resetError();
        if (ret.error) {
            this.setError(ret.error);
            return;
        }

        switch (ret.retFrom) {
            case "VIEW":
                break;
            case "SENDREPLY":
                {
                    const email = ret.selectData;
                    let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
                    const message_id = this.emails[updatedArrayIndex].message_id;
                    return this.replyHandler(message_id, email, ret.pageNumber, ret.arrayIndex);
                }
            case "REPLY":
                {
                    let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
                    this.emails.splice(updatedArrayIndex, 1);
                }
                break;
            case "PROCESS":
                {
                    //let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
                    const output = ret.selectData;
                    const arrayIndex = ret.arrayIndex;
                    const pageNumber = this.pageNumber;
                    if (output.document_type === "8") {
                        const invoice = output.document;
                        this.callForm(AddEditInvoice,
                            { action: "FORMINVOICE", invoice, arrayIndex, pageNumber, ...this.props },
                            this.onReturn);
                    }
                    else {
                        const order = output.document;
                        this.callForm(AddEditOrder,
                            { action: "FORMORDER", order, arrayIndex, pageNumber, ...this.props },
                            this.onReturn);
                    }
                }
                break;
            case "FORMORDER":
            case "FORMINVOICE":
                {
                    let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
                    this.emails.splice(updatedArrayIndex, 1);
                    this.setState({ editCount: this.state.editCount+1 });
                    break;
                }
            default:
                break;
        }
    }

    replyHandler = (message_id, email, pageNumber, arrayIndex) => {
        const data = this.emails[arrayIndex];
		this.callForm(ReplyToEmail, { action: "REPLY", inboundEmail: data,
            messageId: message_id, email, arrayIndex, pageNumber,  ...this.props }, this.onReturn);
    }

    viewHandler = (data, pageNumber, arrayIndex) => {
		this.callForm(ProcessEmail, { action: "VIEW", inboundEmail: data,
            messageId: data.message_id, arrayIndex, pageNumber,  ...this.props }, this.onReturn);
    }

    processHandler = (data, pageNumber, arrayIndex) => {
		this.callForm(ProcessEmail, { action: "PROCESS", inboundEmail: data,
            messageId: data.message_id, arrayIndex, pageNumber,  ...this.props }, this.onReturn);
    }

    getRow = (data, index, pageNumber, arrayIndex) => {
        let key = index;
        this.pageNumber = pageNumber;

        return(<React.Fragment key={index}>
            <tr key={++key}>
                <td>{data.sender_org_name}</td>
                <td>{data.sender_email}</td>
                <td>
					<button type="button"
						style={{
							color: "blue", textDecoration: "underline", cursor: "pointer",
							border: "none", background: "none", padding: 0, margin:0,
							textAlign: "left"
						}}
						onClick={() => this.viewHandler(data, pageNumber, arrayIndex)}
					>
                        {data.subject}
					</button>
                </td>
                <td>{data.received_time}</td>
                <td>
                    {!data.processed &&
                    <>
                        <span onClick={() => this.processHandler(data, pageNumber, arrayIndex)}
                        title="Process"
                        style={{ cursor: 'pointer' }} >
                        <i className="bi bi-arrow-right-circle" style={{ fontSize: 20 }} ></i>
                        </span>
                    </>
                    }
                </td>
            </tr>
        </React.Fragment>);
    }

    getHeader() {
        let key = 0;
        return (
        <tr key={++key}>
            <th key={++key}>{window.getLabelLiteral("SENDER_ORG")}</th>
            <th key={++key}>{window.getLabelLiteral("SENDER_EMAIL")}</th>
            <th key={++key}>{window.getLabelLiteral("SUBJECT")}</th>
            <th key={++key}>{window.getLabelLiteral("RECEIVED_TIME")}</th>
        </tr>
        );
    }

    render = () => {
        const selectOption = (org_id, org_name) => {
            this.state.data.sender_org_id = org_id;
            this.setState({ data: this.state.data });
        }
        let buttons = [];
        const filterBlock = <div className="row">
            <div className="col-sm-6">
                <FormField
                    field={fields.from_date}
                    controlProps={this.controlProps}
                />
            </div>
            <div className="col-sm-6">
                <FormField
                    field={fields.processed}
                    controlProps={this.controlProps}
                />
            </div>
            <div className="col-sm-6">
                <PartnerList
                    field={fields.sender_org_id}
                    keyName={"org_id"}
                    descName={"org_name"}
                    selectOption={selectOption}
                    controlProps={this.controlProps}
                    relationship='B'
                />
            </div>
            <div className="col-sm-6">
                <FormField
                    field={fields.subject}
                    controlProps={this.controlProps}
                    maxLength={512}
                />
            </div>
        </div>

        const tableclass = "table table-striped border-dark";
        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
            <div className="col-sm-12">
                <GenericTable
                    pageHeading={this.getBreadcrumb()}
                    pageHeadingClass="h6 text-left"
                    data={this.emails}
                    recordsPerPage={this.recordsPerPage}
                    getRow = {this.getRow}
                    getHeader={this.getHeader}
                    tableclass={tableclass}
                    getFrame={this.getFrame}
                    recordsPerFrame={this.recordsPerFrame}
                    pageNumber={this.pageNumber}
                    isLoading = {this.state.isLoading}
                    button = {this.button}
                    showFilterCriteria = {filterBlock}
                    modalDialogStyle={{marginLeft: "15em"}}
                />
            </div>
        </div>

        let frm = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props } />
        return frm;

    }
}


export default ManageEmails;

