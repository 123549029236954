import {
	GenericComponent,
	GenericForm,
	getInitialState,
	deepCopy,
	GenericTable,
	raiseError,
} from 'WebUI_Framework';

import AddEditDrCrNote from './AddEditDrCrNote';
import DrCrNoteServiceRoutines from "./DrCrNoteServiceRoutines";

class ManageDebitCreditNotes extends GenericComponent {
	constructor(props) {
		super(props);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.subscriberService = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.props = props;
		this.dr_cr_notes = []; /* Data of main page */
		this.state.isLoading = true; /* Loading animation for table */
		this.state.editCount = 0;
		this.getHeader = this.getHeader.bind(this);
		this.getRow = this.getRow.bind(this);
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	}

	async getFrame(offset, recordsPerFrame) {
		this.resetError();
		this.setState({ isLoading: true });
		try {
			const retData = await DrCrNoteServiceRoutines.list(this.subscriberService, this.accessToken,
				offset, recordsPerFrame, this.session.getLoggedInCompanyId(), this.props.type);
			if (retData.length > 0) {
				this.dr_cr_notes = this.dr_cr_notes.concat(retData);
			}
		}
		catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	}

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				this.dr_cr_notes.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
			case "EDIT":
			case "ACCEPT":
			case "REJECT" : 
			case "APPROVE":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.dr_cr_notes[updatedArrayIndex] = ret.selectData.header;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.dr_cr_notes.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditDrCrNote"));
		}
		return;
	}

	handler = async (action, documentId, arrayIndex, pageNumber, owner_org_id) => {
		this.pageNumber = pageNumber;
		this.callForm(AddEditDrCrNote,
			{ action, documentId, arrayIndex, pageNumber, owner_org_id, ...this.props }, this.onReturn);
	}

	getRow = (row, index, pageNumber, arrayIndex) => {
		let stat;
		let i = index - 1;
		switch (row.status) {
			case '1':
				stat = window.getLabelLiteral("STATUS_DRAFT");
				break;
			case '2':
				stat = window.getLabelLiteral("STATUS_SENT");
				break;
			case '3':
				stat = window.getLabelLiteral("STATUS_RECEIVED");
				break;
			case '4':
				stat = window.getLabelLiteral("STATUS_ACCEPTED");
				break;
			case '5':
				stat = window.getLabelLiteral("STATUS_REJECTED");
				break;
			default:
				this.setError(raiseError("Invalid status code [" + row.status + "]"));
				stat = "ERROR";
				break;
		}

		let tableRow =
			<tr key={i}>
				<td>{row.document_date}</td>
				<td>{row.owner_org_name}</td>
				<td> <button type="button"
					style={{
						color: "blue", textDecoration: "underline", cursor: "pointer",
						border: "none", background: "none", padding: 0
					}}
					onClick={() => this.handler("VIEW", row.document_id, i, pageNumber, row.owner_org_id)}>{row.document_number}
				</button>
				</td>
				{this.props.type === "C" &&
					<td>{row.cr_org_name}</td>
				}
				{this.props.type === "D" &&
					<td>{row.dr_org_name}</td>
				}
				<td>{row.currency}</td>
				<td>{stat}</td>
				<td>
					{(row.deleted !== true && row.entity_state === "0") &&
						<>
							<span onClick={() => this.handler("APPROVE", row.document_id, i, pageNumber, row.owner_org_id)}
								title={window.getLabelLiteral("APPROVE")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-check2-square" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={() => this.handler("EDIT", row.document_id, i, pageNumber, row.owner_org_id)}
								title={window.getLabelLiteral("EDIT")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-pencil-square" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={() => this.handler("DELETE", row.document_id, i, pageNumber)}
								title={window.getLabelLiteral("DELETE")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-trash3" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{(row.deleted !== true && row.status === "3") &&
						<>
							<span onClick={() => this.handler("REJECT", row.document_id, i, pageNumber, row.owner_org_id)}
								title={window.getLabelLiteral("REJECT")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-hand-thumbs-down" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={() => this.handler("ACCEPT", row.document_id, i, pageNumber, row.owner_org_id)}
								title={window.getLabelLiteral("ACCEPT")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-hand-thumbs-up" style={{ fontSize: 20 }} ></i>
							</span>
						</>
					}
				</td>
			</tr>

		return tableRow;

	}

	getHeader() {
		let key = 0;
		return <>
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("OWNER_ORG")}</th>
				<th key={++key}>
                    {(this.props.type === 'D')?window.getLabelLiteral("DR_NOTE_NUMBER"):window.getLabelLiteral("CR_NOTE_NUMBER")}
                </th>
				{this.props.type === "C" &&
					<th key={++key}>{window.getLabelLiteral("CREDITED_COMPANY_NAME")}</th>
				}
				{this.props.type === "D" &&
					<th key={++key}>{window.getLabelLiteral("DEBITED_COMPANY_NAME")}</th>
				}
				<th key={++key}>{window.getLabelLiteral("CURRENCY")}</th>
				<th key={++key}>{window.getLabelLiteral("STATUS_TITLE")}</th>
				<th key={++key}>{window.getLabelLiteral("ACTIONS")}</th>
			</tr>
		</>
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	buttons = [
		{
			literal: "Add",
			func: () => {
				this.callForm(AddEditDrCrNote, { action: "ADD", ...this.props }, this.onReturn);
			}
		}
	]

	render() {
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="col-sm-12">
				<GenericTable
					pageHeading={this.getBreadcrumb()}
					pageHeadingClass="h6 text-left"
					data={this.dr_cr_notes}
					recordsPerPage={this.recordsPerPage}
					getRow={this.getRow}
					getHeader={this.getHeader}
					tableclass="table table-striped border-dark"
					getFrame={this.getFrame}
					recordsPerFrame={this.recordsPerFrame}
					button={this.buttons}
					pageNumber={this.pageNumber}
					isLoading={this.state.isLoading}
				/>
			</div>

		</div>

		let frm = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
		return frm;
	}
}

export default ManageDebitCreditNotes;
