import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let SalesOrdersServiceRoutines = {};

SalesOrdersServiceRoutines.list = async (session, offset, recordsPerFrame, company_id) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
    try {
        response =
            await serverInstance.get(
                `biop/subscriber/sales_order/list?offset=${offset}&num_recs=${recordsPerFrame}&seller_org_id=${company_id}`,
                {
                    headers: {
                        "X-Auth": accessToken
                    }
                }
            );
        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

SalesOrdersServiceRoutines.fetch = async (session, selectedOrderId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
    try {
        response = await serverInstance.get(`biop/subscriber/sales_order/fetch?order_id=${selectedOrderId}`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

SalesOrdersServiceRoutines.fetchUsingNumberAndDate = async (session, buyerOrgId, orderDate, orderNumber) => {
    const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		order_number : orderNumber,
		order_issue_date : orderDate,
		buyer_org_id : buyerOrgId,
	};
    try {
        response = await serverInstance.get(`biop/subscriber/sales_order/fetch_using_number_and_date`,
            {
				params,
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

SalesOrdersServiceRoutines.add = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/sales_order_manager/add", order, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

SalesOrdersServiceRoutines.delete = async (session, orderHeader) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(`biop/subscriber/sales_order_manager/delete`, orderHeader, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

SalesOrdersServiceRoutines.modify = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put("biop/subscriber/sales_order_manager/modify", order, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

SalesOrdersServiceRoutines.approve = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put("biop/subscriber/sales_order_manager/approve", order, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

SalesOrdersServiceRoutines.fetchAmended = async (session, orderId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/sales_order_manager/fetch_for_amendment?order_id=${orderId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

SalesOrdersServiceRoutines.amend = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/sales_order_manager/amend',
			order,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

SalesOrdersServiceRoutines.approveAmendment = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/sales_order_manager/approve_amendment',
			order,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

SalesOrdersServiceRoutines.cancelAmendment = async (session, order) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/sales_order_manager/cancel_amendment',
			order,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

export default SalesOrdersServiceRoutines;
