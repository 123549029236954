import React from 'react';
import {
    GenericComponent,
    GenericForm,
    getInitialState,
    deepCopy,
    GenericTable,
    raiseError,
    valuePresent,
    Backdrop,
    Modal_generic_content,
    genericstring,
    generic_string
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';
import referralServiceRtn from './ReferralServiceRoutines.js'

import AddEditIssuedInvoice from "../invoice/AddEditIssuedInvoice";
import ViewReceivedInvoice from "../received_invoices/ViewReceivedInvoice";

import { setReferralErrors } from '../common/utils';

const documentHandlers = {
    "7": AddEditIssuedInvoice,
    "8": ViewReceivedInvoice
};

let documentTypes = window.getGenericRefCode('documentTypes');
let referralStatuses = window.getGenericRefCode('referralStatuses');

const fields = {
    notes: {
        id: "notes", name: "notes", label: "Notes:",
        class: genericstring, component: generic_string, mandatory: false
    },
}

class ManageReferrals extends GenericComponent {
    constructor(p) {
        super(p);
        this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
        this.pageNumber = 1;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
        this.rocServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken =  this.session.getAccessToken();
        this.logged_in_org_id = this.session.getLoggedInCompanyId();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();

        this.referral_id = null;
        this.referrals = [];
        this.referral = {};
        this.state.editCount = 0;
        this.state.isLoading = true;
        this.state.modal_in_progress = false;
        this.action = "";
        this.arrayIndex = -1;
    }

    initialState = getInitialState(fields);
    state = {
        data : deepCopy(this.initialState),
    }

    getFrame = async (offset,recordsPerFrame) => {
        let retData;
        try {
            retData = await referralServiceRtn.pending_referrals(this.session, {
                offset,
                num_recs: recordsPerFrame,
                user_principal: this.session.user_profile.email_id
            });
            this.setState({isLoading : false})
            if (retData.length > 0) {
                this.referrals = this.referrals.concat(retData);
            }
        }
        catch(e) {
            this.setError(raiseError(e.error_message));
        }
    }

    componentDidMount = async () => {
        try {
            await this.getFrame(1, this.recordsPerFrame);
            this.setState({isLoading: false})
        } catch (e) {
            this.setState({isLoading: false})
        }
    }

    handleFormSubmissionErr = (e) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        if (valuePresent(e.excp_obj)) {
            if (e.err_type === 'X') {
                this.setError(raiseError(e.error_message));
            }
            else {
                this.setError(setReferralErrors(e.excp_obj));
            }
        }
        else {
            this.setError(raiseError(e.error_message));
        }
    }

    approveOrReject = async (referral) => {
        let method_name = "";
        let stat = "";
        switch(this.action) {
            case 'APPROVE':
                method_name = "approve";
                stat = "2";
                break;
            case 'REJECT':
                method_name = "reject";
                stat = "3";
                break;
            default:
                throw new Error("Invalid action "+this.action);
        }
        this.setState({ isLoading : true } );
        try {
            await referralServiceRtn[method_name](this.session, referral.referral_id, referral.notes);
            for (let i =0; i<this.referrals.length; i++) {
                if (this.referrals[i].referral_id === referral.referral_id) {
                    this.referrals[i].status = stat;
                }
            }
            this.setState({ isLoading : false } );
        } catch (e) {
            this.setState({ isLoading : false } );
            this.handleFormSubmissionErr(e);
        }

        return;
    }

    confirmAction = async (control) => {
        this.referral.notes = this.state.data.notes;
        await this.approveOrReject(this.referral);
        this.state.data.notes = "";
        this.setState( { data: this.state.data } );
        this.setState({ modal_in_progress: false });
    }

    closeModalView = async (control) => {
        this.state.data.notes = "";
        this.setState( { data: this.state.data } );
        this.setState({ modal_in_progress: false });
    }

    getModalContent = () => {
        return <>
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                        <label htmlFor="notes"
                            className="col-form-label-sm text-secondary fw-bolder"
                        >
                            {window.getLabelLiteral("NOTES")}
                        </label>
                        <input
                            type="text"
                            name="notes"
                            id="notes"
                            value={this.state.data.notes}
                            onChange={(e) => this.setState((old) =>  { old.data.notes = e.target.value; return old; } ) }
                            maxLength={256}
                            className={`form-control-sm border text-body`}
                        />
                </div>
            </div>
        </>
    }

    handler = (action, data, pageNumber, arrayIndex) => {
        this.action = action;
        this.arrayIndex = arrayIndex;
        this.referral = data;
        this.resetError();
        this.setState({ modal_in_progress: true });
    }

	onReturn = (ret) => {
		this.resetError();
		return ;
	}

    viewHandler = (data, pageNumber, arrayIndex) => {
		this.callForm(documentHandlers[data.document_type], { action: "VIEWWITHNUMBER",
            number:data.document_number, date: data.document_date, orgId: data.document_org_id,
            arrayIndex, pageNumber,  ...this.props }, this.onReturn);
    }

    willGetApproved = (item) => {
        return valuePresent(this.session.id_inboxes[this.logged_in_org_id][item.inbox_index]);
    }

    getRow = (data, index, pageNumber, arrayIndex) => {
        let local_index = index*10 + 1;
        let subKey = 0;
        this.pageNumber = pageNumber;
        let nb_style = { borderBottom: "0px solid" };

        return(<React.Fragment key={index}>
            <tr key={++local_index}>
                <td style={nb_style}>{documentTypes[data.document_type]}</td>
                <td style={nb_style}>{data.document_org_name}</td>
                <td style={nb_style}>{data.document_date}</td>
                <td style={nb_style}>
					<button type="button"
						style={{
							color: "blue", textDecoration: "underline", cursor: "pointer",
							border: "none", background: "none", padding: 0, margin:0,
							textAlign: "left"
						}}
						onClick={() => this.viewHandler(data, pageNumber, arrayIndex)}
					>
                        {data.document_number}
					</button>
                </td>
                <td style={nb_style}>{referralStatuses[data.status]}</td>
                <td style={nb_style}>{(data.status === '1') &&
                    <>
                        <span onClick={() =>
                            this.handler("APPROVE", data, pageNumber, arrayIndex)}
                            title='Approve' style={{cursor : "pointer"}}>
                            <i className="bi bi-hand-thumbs-up"
                                style={{ fontSize: 20, transform: "scaleX(-1)", display: "inline-block" }} ></i>
                        </span>&nbsp;&nbsp;
                        <span onClick={() =>
                            this.handler("REJECT", data, pageNumber, arrayIndex)}
                            title='Reject' style={{cursor : 'pointer'}}>
                            <i className="bi bi-hand-thumbs-down "
                                style={{ fontSize: 20, transform: "scaleX(-1)", display: "inline-block" }} ></i>
                        </span>&nbsp;&nbsp;
                    </>
                }
                </td>
            </tr>
            <tr key={++local_index} >
                <td colSpan="4" style={nb_style}>
                    <table className="table border-dark mt-3">
                        <thead>
                            <tr>
                                <th key={++subKey}>
                                    <div className="text-start">
                                    {window.getLabelLiteral("SRL")}
                                    </div>
                                </th>
                                <th key={++subKey}>
                                    <div className="text-center">
                                    {window.getLabelLiteral("EXCEPTION_DESCRIPTION")}
                                    </div>
                                </th>
                                <th key={++subKey}>
                                    <div className="text-center">
                                    {window.getLabelLiteral("ADDITIONAL_INFO")}
                                    </div>
                                </th>
                                <th key={++subKey}>
                                    <div className="text-center">
                                    {window.getLabelLiteral("INBOX_NAME")}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ borderTop: "2px solid", borderColor: "LightGray" }}>
                            {data.excp.excp.filter((x) => valuePresent(this.session.id_inboxes[this.logged_in_org_id][x.inbox_index]))
                                .map((item, i) => {
                                return <tr key={++subKey}>
                                    <td key={++local_index} style={nb_style}>
                                        <div className="text-center " >
                                            {(i+1).toString()+"."}
                                        </div>
                                    </td>
                                    <td key={++subKey} style={nb_style}>
                                        <div className="text-center " >
                                            {item.description}
                                        </div>
                                    </td>
                                    <td key={++subKey} style={nb_style}>
                                        <div className="text-center " >
                                            {item.description_2}
                                        </div>
                                    </td>
                                    <td key={++subKey} style={nb_style}>
                                        <div className="text-center " >
                                            {item.inbox_name}
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </td>
            </tr>
        </React.Fragment>);
    }

    getHeader() {
        let key = 0;
        return (
        <tr key={++key}>
            <th key={++key}>{window.getLabelLiteral("DOCUMENT_TYPE")}</th>
            <th key={++key}>{window.getLabelLiteral("DOCUMENT_ORG")}</th>
            <th key={++key}>{window.getLabelLiteral("DOCUMENT_DATE")}</th>
            <th key={++key}>{window.getLabelLiteral("DOCUMENT_NUMBER")}</th>
            <th key={++key}>{window.getLabelLiteral("STATUS_TITLE")}</th>
            <th key={++key}>{window.getLabelLiteral("ACTIONS")}</th>
        </tr>
        );
    }

    getBtnName = () => {
        switch(this.action) {
            case 'APPROVE':
                return window.getLabelLiteral("APPROVE");
            case 'REJECT':
                return window.getLabelLiteral("REJECT");
            default:
                return "NON SENSE";
        }
    }

    render = () => {
        let buttons = [];

        const tableclass = "table table-striped border-dark";
        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
            <div className="col-sm-12">
                <GenericTable
                    pageHeading={this.getBreadcrumb()}
                    pageHeadingClass="h6 text-left"
                    data={this.referrals}
                    recordsPerPage={this.recordsPerPage}
                    getRow = {this.getRow}
                    getHeader={this.getHeader}
                    tableclass={tableclass}
                    getFrame={this.getFrame}
                    recordsPerFrame={this.recordsPerFrame}
                    pageNumber={this.pageNumber}
                    isLoading = {this.state.isLoading}
                    button = {this.button}
                />
            </div>
            {(this.state.modal_in_progress) && <Modal_generic_content
                modalDialogClass={"modal-dialog"}
                modalDialogStyle={{marginLeft: "10em"}}
                title={window.getLabelLiteral((this.action === "APPROVE")?"APPROVE_REFERRAL":"REJECT_REFERRAL")}
                btn1={this.getBtnName()}
                btn2={window.getLabelLiteral("CANCEL")}
                onClick={this.closeModalView}
                onConfirm={this.confirmAction}
                modalContent={this.getModalContent()}
                />
            }
            {(this.state.modal_in_progress) && <Backdrop />}
        </div>

        let frm = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props } />
        return frm;

    }
}


export default ManageReferrals;

