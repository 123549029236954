import {
	GenericComponent,
	GenericForm,
	FormField,
	NarrowButton,
	getInitialState,
	copyDataFieldsToFormFields,
	deepCopy,
	copyFormFieldsToDataFields,
	raiseError,
	acct_identifier,
	bank_identifier_type,
	bankidentifiertype,
	bank_identifier,
	bankidentifier,
	acct_name,
	acct_number,
	acctidentifier,
	acctname,
	acctnumber,
	fieldValidations,
	Modal,
	Backdrop,
	search_select,
	searchselect,
} from "WebUI_Framework";

import  serviceRoutines from './BankdetailsServiceRoutines';

const fields = {
	acct_identifier: {
		id: "acct_identifier",
		name: "acct_identifier",
		label: "Account Identifier:",
		class: acctidentifier,
		component: acct_identifier,
		mandatory: true,
	},
	acct_name: {
		id: "acct_name",
		name: "acct_name",
		label: "Account Name:",
		class: acctname,
		component: acct_name,
		mandatory: true,
	},
	acct_number: {
		id: "acct_number",
		name: "acct_number",
		label: "Account Number:",
		class: acctnumber,
		component: acct_number,
		mandatory: true,
	},
	bank_identifier_type: {
		id: "bank_identifier_type",
		name: "bank_identifier_type",
		label: "Bank Identifier Type:",
		class: bankidentifiertype,
		component: bank_identifier_type,
		mandatory: true,
	},
	bank_identifier: {
		id: "bank_identifier",
		name: "bank_identifier",
		label: "Bank Identifier:",
		class: bankidentifier,
		component: bank_identifier,
		mandatory: true,
	},
	bank_identifier_desc: {
		id: "bank_identifier_desc",
		name: "bank_identifier_desc",
		label: "Bank Identifier Desc:",
		class: searchselect,
		component: search_select,
		mandatory: false,
	},
};

let mapping = [
	["org_id", "org_id"],
	["acct_identifier", "acct_identifier"],
	["acct_name", "acct_name"],
	["acct_number", "acct_number"],
	["bank_identifier_type", "bank_identifier_type"],
	["bank_identifier", "bank_identifier"],
	["version", "version"],
];

class EditBankDetail extends GenericComponent {
	constructor(props) {
		super(props);
		this.serverInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.state.fetchedlist = [];
		this.selectedBankdata = {};
		this.readOnly = this.props.readOnly;
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	};

	componentDidMount() {
		this.getSelectedBankdata();
	}

	async getSelectedBankdata() {
		try {
			const retData =
					await serviceRoutines.fetch(this.serverInstance, this.accessToken,
											this.props.orgId, this.props.acct_identifier);
			if(retData !== undefined && retData !== null) {
				this.selectedBankdata = retData;
				let data = {};
				copyDataFieldsToFormFields(mapping, this.selectedBankdata, data);
				let refCode = window.getRefCode(this.selectedBankdata.bank_identifier_type);
				const bankName = refCode[data.bank_identifier];
				data.bank_identifier_desc = `${data.bank_identifier}--${bankName}`;
				this.setState({ data });
			}
		}
		catch(e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
	}

	reset = () => {
		let data = deepCopy(this.initialState);
		this.setState({ data: data, error: undefined });
	};

	backHandler = (retFrom) => {
		if (retFrom !== "edit") {
			retFrom = "back";
		}

		if (this.isChildMode()) {
			this.props.returnToParent( {
				retFrom: retFrom,
				arrayIndex: this.props.arrayIndex,
				selectData: this.selectedBankdata,
			});
		}
		else {
			console.log("LOADED AS A PARENT");
		}
	};

	defaultFilter = (array, searchText, newArray) => {
		for (const key of Object.keys(array)) {
			if (typeof array[key] === "string") {
				if ( array[key].toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
						key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
					let temp = {};
					temp[key] = array[key];
					newArray.push(temp);
				}
			}
		}
	};

	getDataList = (control, searchText) => {
		if ( this.state.data.bank_identifier_type === "" ||
			this.state.data.bank_identifier_type === undefined) {
			this.error1 = window.getLiterals("BANK_IDENTIFIER_TYPE_MUST_BE_SELECTED");
			this.setState({ showErrorFlag: true });
		} else {
			let searched_data = [];
			let refCode = window.getRefCode(this.state.data.bank_identifier_type);
			this.defaultFilter(refCode, searchText, searched_data);
			this.setState({ fetchedlist: searched_data });
			return searched_data;
		}
	};

	formatDataList(searched_data) {
		let formattedArray = [];
		if (searched_data.length !== 0) {
			for (const item of searched_data) {
				const key = Object.keys(item)[0];
				const value = item[key];
				const formattedItem = key + "--" + value;
				formattedArray.push(formattedItem);
			}
		}
		return formattedArray;
	}

	selectOption = (index, list) => {
		let data = deepCopy(this.state.data);
		data.bank_identifier = Object.keys(list[index])[0];
		let refCode = window.getRefCode(data.bank_identifier_type);
		const bankName = refCode[data.bank_identifier];
		data.bank_identifier_desc = `${data.bank_identifier}--${bankName}`;
		this.setState({ data });
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};

	render() {
		let buttons = [];
		let key = 0;
		if (this.props.readOnly) {
				this.readOnly = true;
		this.disabled = true;
			}
		let datablk = (
			<div className="container-fluid pt-0 m-0 pe-2">
				<div className="row p-0 m-0 g-0 ">
				{ !this.readOnly ?
                (
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral("EDIT")])}
					</div>
				)
				:
                (
					<div className="col-sm-12 h6 text-left">
						{this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
					</div>
				)
                }

				<div className="col-sm-6">
					<FormField
					field={fields.acct_identifier}
					controlProps={this.controlProps}
					readOnly={this.readOnly}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
					field={fields.acct_name}
					controlProps={this.controlProps}
					readOnly={this.readOnly}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
					field={fields.acct_number}
					controlProps={this.controlProps}
					readOnly={this.readOnly}
					/>
				</div>
				<div className="col-sm-6">
					<FormField
					field={fields.bank_identifier_type}
					controlProps={this.controlProps}
					disabled={this.disabled}            />
				</div>
				<div className="col-sm-6">
					<FormField
						field={fields.bank_identifier}
						controlProps={this.controlProps}
						bankIdentifierType={this.state.data.bank_identifier_type}
						readOnly={this.readOnly}
					/>
				</div>

				<div className="row p-0 m-0 g-0 ">
					<div className="col-sm-6 mt-4 d-flex justify-content-right ">
                    { !this.readOnly && 
                    <>
                        <NarrowButton
                            value="Update"
                            onClick={this.submitForm}
                            key={(++key).toString()}
                        />

                        <NarrowButton
                            value="Clear"
                            onClick={this.reset}
                            key={(++key).toString()}
                        />
                    </>
                    }

					<NarrowButton
						value="Back"
						onClick={this.backHandler}
						key={(++key).toString()}
					/>
					</div>
				</div>
				</div>
				{this.state.showModal && (
					<Modal
						title="Update Confirmation"
						msg="Are you sure, you want to update Bank Detail?"
						btn1="Confirm"
						btn2="Cancel"
						onClick={this.closeModal}
						onConfirm={this.onConfirm}
					/>
				)}
				{this.state.showModal && <Backdrop />}
			</div>
		);
		let form = (
			<GenericForm
				datablk={datablk}
				buttons={buttons}
				controlProps={this.controlProps}
				{...this.props}
			/>
		);
		return form;
	}

	validateForm = () => {
		let r = fieldValidations(fields, this.state.data);
		if (!r.status) {
			this.setState({ error: r });
			return false;
		}

		return true;
	};

	submitForm = () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}
		this.setState({ showModal: true });
	};

	onConfirm = async(control) => {
		copyFormFieldsToDataFields(mapping, this.state.data, this.selectedBankdata, fields);

		try {
			const retData = await serviceRoutines.modify(this.serverInstance, this.accessToken, this.selectedBankdata);
			if(retData !== undefined && retData !== null) {
				this.backHandler("edit");
			}
			this.setState({ showModal: false });
		}
		catch(e) {
			this.setError(raiseError(e.error_message));
			control.error = true;
			control.errorMsg = e.error_message;
		}
	}
}

export default EditBankDetail;
