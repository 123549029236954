import {
    GenericComponent,
    valuePresent,
    raiseError,
} from 'WebUI_Framework';

import { setReferralErrors } from '../common/utils';
import commonServiceRoutines from './CommonServiceRoutines';

class BaseComponent extends GenericComponent {
    handleFormSubmissionErr = (e, excpAccepted) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        if (valuePresent(e.excp_obj)) {
            if (e.err_type === 'X') {
                if (excpAccepted !== true) this.setError(raiseError(e.error_message));
                else return true;
            }
            else {
                this.setError(setReferralErrors(e.excp_obj));
            }
        }
        else {
            this.setError(raiseError(e.error_message));
        }

        return false;
    }

	withdrawReferral = async (data, i, name) => {
        this.resetError();
        this.setState({ isLoading : true } );
        try {
            const referralId = data[name];
            await commonServiceRoutines.withdrawReferral(this.session, referralId);
            data[name] = undefined;
            this.setState({ isLoading : false } );
        } catch (e) {
            this.setState({ isLoading : false } );
            this.setError(raiseError(e.error_message));
        }
        return;
	}

    /*
    onReturn = async (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		//eslint no-fallthrough: 0
		switch (ret.retFrom) {
            case 'BACK':
                break;
            case 'PROCEED':
                this.excpAccepted = true;
                return await this.submitForm();
            default:
                break;
        }
    }

    handleExceptions = (referral) => {
		this.callForm(SubmitReferral, { action: "PROCEED", referral,  ...this.props }, this.onReturn);
    }
    */

}

export { BaseComponent }
