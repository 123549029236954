import {
    GenericComponent,
    FormField,
    valuePresent,
    copyDataFieldsToFormFields,
    //copyFormFieldsToDataFields,
    NarrowButton,

    getInitialState,
    deepCopy,
    //raiseError,

    document_number,
    documentnumber,
    generic_string,
    genericstring,
    genericgenrefcode,
    generic_genrefcode,
    inputdate,
    input_date,

} from 'WebUI_Framework';

const fields = {
    document_type: {
        id: "document_type", name: "document_type", label: window.getLabelLiteralWithColon("DOCUMENT_TYPE"),
        class: genericgenrefcode, component: generic_genrefcode, mandatory: true
    },
    document_date: {
        id: "document_date", name: "document_date", label: window.getLabelLiteralWithColon("DOCUMENT_DATE"),
        class: inputdate, component: input_date, mandatory: true,
    },
    document_number: {
        id: "document_number", name: "document_number", label: window.getLabelLiteralWithColon("DOCUMENT_NUMBER"),
        class: documentnumber, component: document_number, mandatory: true,
    },
    document_org_name: {
        id: "document_org_name", name: "document_org_name", label: window.getLabelLiteralWithColon("COMPANY_NAME"),
        class: genericstring, component: generic_string, mandatory: true,
    },

};

const mapping = [
    ['document_type', 'document_type'],
    ['document_date', 'document_date'],
    ['document_number', 'document_number'],
    ['document_org_name', 'document_org_name'],
    ['status', 'status'],
];

class SubmitReferral extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;

        this.readOnly = false;
 
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();

        this.referral_action = props.referral_action;
        this.action = props.action
        this.excpObject = props.excpObject;

        this.logged_in_org_id = this.session.getLoggedInCompanyId();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_org_id);

        this.page_title += window.getLabelLiteral("SUBMIT_REFERRAL_TITLE");

        this.referral = props.referral;
    }

    initialState = getInitialState(fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.rule_set, error
            });
        else console.log("LOADED AS A PARENT");
    }

    componentDidMount = async () => {

        const newData = {};
        copyDataFieldsToFormFields(mapping, this.referral, newData);
        this.setState({ data: newData})
        return;
    }

    willGetApproved = (item) => {
        return valuePresent(this.session.id_inboxes[this.logged_in_org_id][item.inbox_index]);
    }

    render = () => {
        let key =0;
        let nb_style = { borderBottom: "0px solid" };
        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb([window.getLabelLiteral(this.action)])}
                    </div>
                    <hr></hr>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.document_org_name}
                            controlProps={this.controlProps}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.document_type}
                            controlProps={this.controlProps}
                            refType={"documentTypes"}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.document_date}
                            controlProps={this.controlProps}
                            refType={"documentTypes"}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.document_number}
                            controlProps={this.controlProps}
                            refType={"documentTypes"}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-form-label-sm text-secondary fw-bolder"
                                    style={{fontSize:"0.9rem"}}>{window.getLabelLiteral("STATUS_TITLE")}</div>
                        </div>
                        <div className="row">
                            <div className="d-inline-block col-12">
                                <div className="d-inline-flex col-12">
                                    <input
                                        type="text"
                                        name={"status"}
                                        id={"status"}
                                        maxLength={256}
                                        className={`form-control-sm border w-60 text-body`}
                                        value={window.getGenericRefCode("referralStatuses")[this.referral.status]}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 pt-3">
                        <div className="row my-2">
                            <div className="text-start fw-bolder"
                                    style={{}}>{window.getLabelLiteral("EXCEPTIONS")}</div>
                        </div>
                        <table className="table border-dark">
                            <thead>
                                <tr>
                                    <th key={++key}>
                                        <div className="text-start">
                                        {window.getLabelLiteral("SRL")}
                                        </div>
                                    </th>
                                    <th key={++key}>
                                        <div className="text-center">
                                        {window.getLabelLiteral("EXCEPTION_DESCRIPTION")}
                                        </div>
                                    </th>
                                    <th key={++key}>
                                        <div className="text-center">
                                        {window.getLabelLiteral("ADDITIONAL_INFO")}
                                        </div>
                                    </th>
                                    <th key={++key}>
                                        <div className="text-center">
                                        {window.getLabelLiteral("INBOX_NAME")}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "2px solid", borderColor: "LightGray" }}>
                                {this.referral.excp.excp
                                    .map((item, i) => {
                                    return <tr key={++key}>
                                        <td key={++key} style={nb_style}>
                                            <div className="text-start "
                                                style={{ fontWeight: (this.willGetApproved(item))?"bolder":"" }}
                                            >
                                                {(i+1).toString()+"."}
                                            </div>
                                        </td>
                                        <td key={++key} style={nb_style}>
                                            <div className="text-center "
                                                style={{ fontWeight: (this.willGetApproved(item))?"bolder":"" }}
                                            >
                                                <>
                                                    {this.willGetApproved(item) &&
                                                        <span>
                                                            <i className="bi bi-check2 fw-bolder" style={{ fontSize: "20" }} >
                                                            </i>
                                                        </span>
                                                    }
                                                    &nbsp;&nbsp;
                                                    <span>{item.description}</span>
                                                </>
                                            </div>
                                        </td>
                                        <td key={++key} style={nb_style}>
                                            <div className="text-center "
                                                style={{ fontWeight: (this.willGetApproved(item))?"bolder":"" }}
                                            >
                                                {item.description_2}
                                            </div>
                                        </td>
                                        <td key={++key} style={nb_style}>
                                            <div className="text-center "
                                                style={{ fontWeight: (this.willGetApproved(item))?"bolder":"" }}
                                            >
                                                {item.inbox_name}
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row  p-0 m-0 g-0 pt-3 " >
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        <NarrowButton value="Proceed" onClick={this.submitForm} key={(++key).toString()} />
                        <NarrowButton value="Cancel" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                    </div>
                </div>
            </div>
        )

        return datablk;
    }

    submitForm = async() => {
        this.backHandler(this.referral_action);
    }


};

export default SubmitReferral;


