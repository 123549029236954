import { getServiceDetails } from '../common/utils'
import {
	handleAxiosHTTPException
} from "WebUI_Framework";

let ReceiptNoteServiceRoutines = {};

ReceiptNoteServiceRoutines.list = async ( serverInstance, accessToken, offset, recordsPerFrame) => {
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/receipt_note/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : {
					offset: offset,
					num_recs: recordsPerFrame,
					deleted: 0
				}
			}
		);
	} catch (e) {
		console.log(e);
		return handleAxiosHTTPException(e);
	}
	return response.data;
}

ReceiptNoteServiceRoutines.fetch = async (session, receiptNoteId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
    try {
        response = await serverInstance.get(
			`/biop/subscriber/receipt_note/fetch?receipt_note_id=${ receiptNoteId }`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.fetchAmended = async (session, receiptNoteId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
    try {
        response = await serverInstance.get(
			`/biop/subscriber/receipt_note/fetch_amended?receipt_note_id=${ receiptNoteId }`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.fetchUsingNumberAndDate = async (serverInstance, accessToken, receiptNoteNumber, receiptNoteDate, orgId) => {
    let response;
	let params = {
		receipt_note_number : receiptNoteNumber,
		receipt_note_date : receiptNoteDate,
		buyer_org_id : orgId
	};
    try {
        response = await serverInstance.get(
			`/biop/subscriber/receipt_note/fetch_using_number_and_date`,
            {
				params,
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.fetch_order = async (serverInstance, accessToken, buyerOrgId, orderIssueDate, orderNumber) => {
    let response;
	let params = {};
	if (orderNumber) {
		params.order_number = orderNumber;
	}
	if (orderIssueDate) {
		params.order_issue_date = orderIssueDate;
	}
	if (buyerOrgId) {
		params.buyer_org_id = buyerOrgId;
	}
    try {
        response = await serverInstance.get(
			`/biop/subscriber/order/fetch_using_order_num_and_date`,
            {
				params,
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
		console.log(e);
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.add = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.post('/biop/subscriber/receipt_note/add',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.modify = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/receipt_note/modify',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.amend = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/receipt_note/amend',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.cancelAmendMent = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/receipt_note/cancel_amendment',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.accept = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/receipt_note/accept',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.reject = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/receipt_note/reject',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.approve = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/receipt_note/approve',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

ReceiptNoteServiceRoutines.delete = async (serverInstance, accessToken, receiptNote) => {
    let response;
    try {
        response = await serverInstance.put('/biop/subscriber/receipt_note/delete',
            receiptNote,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}


export default ReceiptNoteServiceRoutines;
