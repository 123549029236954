import { FormField } from 'WebUI_Framework';
import commonServiceRoutines from './CommonServiceRoutines'

function Attachments(props) {
	if (props.field === null || props.field === undefined) {
		throw new Error("Attachments: Field definition must be passed");
	}
	if (props.controlProps === null || props.controlProps === undefined) {
		throw new Error("Attachments: controlProps definition must be passed");
	}

	let readOnly = false;
	if (props.readOnly !== null && props.readOnly !== undefined) {
		readOnly = props.readOnly;
	}

	/*
	let inMultiRec = false;
	let i;
	if (props.inMultiRec !== null && props.inMultiRec !== undefined) {
		inMultiRec = props.inMultiRec;
		if (props.i === null || props.i === undefined) {
			throw new Error("Attachments: iterator i is necessary in a multirec field");
		}
		i = props.i;
	}
	*/

	const session = props.controlProps.cInstance.session;
	if (session === null || session === undefined) {
		throw new Error("Attachments: Unable to deduce session");
	}

	let localOnDownload = async (inp_file) => {
		const file = await commonServiceRoutines.fetchFile(session, inp_file.file_id);
		return file;
	};

	return <FormField
        {...props}
		field={ props.field }
		controlProps={ props.controlProps }
		readOnly={readOnly}
		onDownload={localOnDownload}
	/>

}

export default Attachments;
