import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let EmailSetServiceroutines = {};

EmailSetServiceroutines.list = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/inbound_email_manager/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

EmailSetServiceroutines.fetch = async (session, messageId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		message_id : messageId,
	};
    try {
        response = await serverInstance.get(
            `biop/subscriber/inbound_email_manager/fetch`,
            {
                params,
                headers: {
                    "X-Auth": accessToken,
                },
            }
        );
    } catch (e) {
		return handleAxiosHTTPException(e);
    }

    return response.data;
}

EmailSetServiceroutines.fetchFullMessage = async (session, messageId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		message_id : messageId,
	};
    try {
        response = await serverInstance.get(
            `biop/subscriber/inbound_email_manager/fetch_full_email_message`,
            {
                params,
                headers: {
                    "X-Auth": accessToken,
                },
            }
        );
    } catch (e) {
		return handleAxiosHTTPException(e);
    }

    return response.data;
}

EmailSetServiceroutines.reply = async (session, replyMessage) => {
	const {serverInstance, accessToken} = getServiceDetails(session);

	try {
		await serverInstance.post("biop/subscriber/inbound_email_manager/reply", replyMessage, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}

    return;
}

export default EmailSetServiceroutines;


