import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let ReferralSetServiceroutines = {};

ReferralSetServiceroutines.check_rules = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/referral_manager/check_rules`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ReferralSetServiceroutines.pending_referrals = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/referral_manager/pending_referrals`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ReferralSetServiceroutines.list = async (session, obj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(
			`biop/subscriber/referral_manager/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
				params : obj
			});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

ReferralSetServiceroutines.fetch = async (session, referralId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		referral_id : referralId,
	};
    try {
        response = await serverInstance.get(
            `biop/subscriber/referral_manager/fetch`,
            {
                params,
                headers: {
                    "X-Auth": accessToken,
                },
            }
        );
    } catch (e) {
		return handleAxiosHTTPException(e);
    }

    return response.data;
}

ReferralSetServiceroutines.withdraw = async (session, referralId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);

    const referralData = await ReferralSetServiceroutines.fetch(session, referralId);

	try {
		await serverInstance.post("biop/subscriber/referral_manager/withdraw", referralData, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}

    return;
}

ReferralSetServiceroutines.approve = async (session, referralId, notes) => {
	const {serverInstance, accessToken} = getServiceDetails(session);

    const referralData = await ReferralSetServiceroutines.fetch(session, referralId);
    referralData.notes = notes;

	try {
		await serverInstance.post("biop/subscriber/referral_manager/approve", referralData, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}

    return;
}

ReferralSetServiceroutines.reject = async (session, referralId, notes) => {
	const {serverInstance, accessToken} = getServiceDetails(session);

    const referralData = await ReferralSetServiceroutines.fetch(session, referralId);
    referralData.notes = notes;

	try {
		await serverInstance.post("biop/subscriber/referral_manager/reject", referralData, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}

    return;
}

export default ReferralSetServiceroutines;


