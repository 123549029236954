import {
	GenericComponent,
	GenericForm,
	getInitialState,
	deepCopy,
    valuePresent,
	GenericTable,
	raiseError
} from 'WebUI_Framework';

import AddEditReceiptNote from './AddEditReceiptNote';
import AddEditDrCrNote from '../debit_credit_notes/AddEditDrCrNote';
import receiptNoteService from "./ReceiptNoteServiceRoutines";
import { debitNoteFromReceiptNote } from './utils';

class ManageReceiptNotes extends GenericComponent {
	constructor (p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.subscriberService = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);

		this.receipt_notes = []; /* Data of main page */
		this.state.isLoading = true; /* Loading animation for table */
		this.state.editCount = 0;

	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	}

	async getFrame (offset, recordsPerFrame) {
		this.resetError();
		this.setState({ isLoading: true });
		try {
			const retData = await receiptNoteService.list( this.subscriberService, this.accessToken, offset, recordsPerFrame);
			if (retData.length > 0) {
				this.receipt_notes = this.receipt_notes.concat(retData);
			}
		}
		catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	}

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				this.receipt_notes.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
			case "EDIT":
			case "CANCEL":
			case "ACCEPT":
			case "REJECT":
			case "AMEND":
			case "APPROVE":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.receipt_notes[ updatedArrayIndex ] = ret.selectData.header;

					let newState = deepCopy(prevState);
					newState.editCount ++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.receipt_notes.splice(ret.arrayIndex, 1);
				this.setState({editCount: this.state.editCount + 1});
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditReceiptNote or AddEditDrCrNote"));
		}
		return ;
	}

	handler = async (action, receiptNoteId, arrayIndex, pageNumber) => {
		this.pageNumber = pageNumber;
		this.callForm(AddEditReceiptNote, { action, receiptNoteId, arrayIndex, pageNumber,  ...this.props }, this.onReturn);
	}

	drNoteReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		switch (ret.retFrom) {
            case "INIT_DR_NOTE":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.receipt_notes[ updatedArrayIndex ].dr_note_number = ret.selectData.header.document_number;
					this.receipt_notes[ updatedArrayIndex ].dr_note_date = ret.selectData.header.document_date;

					prevState.editCount ++;
					return prevState;
				});
				break;
			case "BACK":
			case "VIEWWITHNUMBER":
                break;
			default:
				this.setError(raiseError("Invalid return from AddEditDrCrNote"));
		}
    }

	drNoteHandler = async (receiptNoteId, arrayIndex, pageNumber, documentNumber, documentDate, drOrgId) => {
        if (valuePresent(documentNumber)) {
            const ownerOrgId = this.session.getLoggedInCompanyId();
            this.callForm(AddEditDrCrNote,
                { action: "VIEWWITHNUMBER", receiptNoteId, arrayIndex, pageNumber, type: 'D',
                    documentNumber, documentDate, ownerOrgId, ...this.props}, this.drNoteReturn);
        }
        else {
            try {
                const drNote = await debitNoteFromReceiptNote(this.session, receiptNoteId);

                this.callForm(AddEditDrCrNote,
                    { action: "INIT_DR_NOTE", receiptNoteId, arrayIndex,
                        pageNumber, drNote, type: 'D', ...this.props}, this.drNoteReturn);
            } catch (e) {
                console.log(e);
                this.setError(raiseError(e.error_message));
            }
        }
	}

	pageChange = (pageNumber) => {
		this.pageNumber = pageNumber;
	}

	getRow = (row, index, pageNumber, i) => {
		let stat;
		if (row.entity_state === "0")
			stat = window.getLabelLiteral("STATUS_DRAFT");
		else if (row.entity_state === "2")
			stat = window.getLabelLiteral("STATUS_BEING_MODIFIED");
		else {
			switch (row.receipt_note_status) {
				case '1':
					stat = window.getLabelLiteral("STATUS_DRAFT");
					break;
				case '2':
					stat = window.getLabelLiteral("STATUS_DELETED");
					break;
				case '3':
					stat = window.getLabelLiteral("STATUS_PROPOSED");
					break;
				case '4' :
					stat = window.getLabelLiteral("STATUS_ACCEPTED");
					break;
				case '5':
					stat = window.getLabelLiteral("STATUS_REJECTED");
					break;
				default:
					this.setError(raiseError("Invalid status code ["+row.receipt_note_status+"]"));
					stat = "ERROR";
			}
		}

		let tableRow = 
			<tr key={i}>
				<td>{row.receipt_note_date}</td>
				<td> <button type="button"
						style={{ color: "blue", textDecoration: "underline", cursor: "pointer",
								border:"none", background:"none", padding:0 }}
								onClick={() => this.handler("VIEW", row.receipt_note_id, i, pageNumber)}>{row.receipt_note_number}
						</button>
				</td>
				<td>{row.seller_org_name}</td>
                <td>{window.getGenericRefCode('deliveryDocumentTypes')[row.delivery_document_type]}</td>
                {(row.delivery_document_type === '1' || row.delivery_document_type === '2') &&
                <>
                    <td>{row.delivery_note_date}</td>
                    <td>{row.delivery_note_number}</td>
                </>
                }
                {(row.delivery_document_type === '3') &&
                <>
                    <td>{row.invoice_date}</td>
                    <td>{row.invoice_number}</td>
                </>
                }
                {(!valuePresent(row.delivery_document_type) || row.delivery_document_type === "") &&
                <>
                    <td/>
                    <td/>
                </>
                }
				<td>{row.description}</td>
				<td>{stat}</td>
				<td>
					{ (row.deleted !== true && row.entity_state === "0") &&
						<>
							<span onClick={ () => this.handler("APPROVE", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("APPROVE")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-check2-square" style={ { fontSize: 20 } } ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={ () => this.handler("EDIT", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("EDIT")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-pencil-square" style={ { fontSize: 20 } } ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={ () => this.handler("DELETE", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("DELETE")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-trash3" style={ { fontSize: 20 } } ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{ (row.deleted !== true && row.entity_state !== "0" && row.receipt_note_status !== '5' &&
                        row.delivery_document_type !== '3' &&
						this.session.getLoggedInCompanyId() === row.owner_org_id) &&
						<>
							<span onClick={ () => this.handler("AMEND", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("AMEND")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-pen" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{(row.deleted !== true && row.entity_state === "2" && row.receipt_note_status === '3') &&
						<>
							<span onClick={ () => this.handler("CANCEL", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("CANCEL")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-x-square" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{(row.deleted !== true && row.entity_state !== "0" && row.receipt_note_status === '3') &&
						<>
							<span onClick={ () => this.handler("ACCEPT", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("ACCEPT")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-hand-thumbs-up" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={ () => this.handler("REJECT", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("REJECT")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-hand-thumbs-down" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{(row.deleted !== true && row.entity_state === "2" && row.receipt_note_status === '4') &&
						<>
							<span onClick={ () => this.handler("CANCEL", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("CANCEL")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-x-square" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={ () => this.handler("ACCEPT", row.receipt_note_id, i, pageNumber) }
									title={window.getLabelLiteral("ACCEPT")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-hand-thumbs-up" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{(row.deleted !== true && row.entity_state === "1" &&
                        valuePresent(row.invoice_number) && row.receipt_note_status === '4') &&
                        <>
							<span onClick={ () =>
                                this.drNoteHandler(row.receipt_note_id,
                                    i, pageNumber, row.dr_note_number, row.dr_note_date, row.seller_org_id) }
                                title={window.getLabelLiteral("INIT_DR_NOTE")}
                                style={{ cursor: 'pointer' }}
                            >
								<i className="bi bi-journal-minus" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
                        </>
                    }
				</td>
			</tr>

		return tableRow;

	}

	getHeader () {
		let key = 0;
		return <>
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("RECEIPT_NOTE_NUMBER")}</th>
				<th key={++key}>{window.getLabelLiteral("SELLER_COMPANY_NAME")}</th>
				<th key={++key}>{window.getLabelLiteral("DELIVERY_DOCUMENT_TYPE")}</th>
				<th key={++key}>{window.getLabelLiteral("DOCUMENT_DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("DOCUMENT_NUMBER")}</th>
				<th key={++key}>{window.getLabelLiteral("DESCRIPTION")}</th>
				<th key={++key}>{window.getLabelLiteral("STATUS_TITLE")}</th>
			</tr>
		</>
	}

	componentDidMount () {
		this.getFrame(1, this.recordsPerFrame);
	}

	buttons = [
		{
			literal: "Add",
			func: () => {
				const ucp = this.session.getUserCompanyProps(this.session.getLoggedInCompanyId(),
															this.session.getLoggedInParentCompanyId());
				if (!(ucp.org_is_subscriber)) {
					if (ucp.partner_relationship_type === 'S') {
						this.setError(raiseError(window.parseLiteral("CANNOT_CREATE_RELEASE_NOTE"
															, this.session.getLoggedInCompanyName())));
						return false;
					}
				}
				this.handler("ADD", null, 0, this.pageNumber);
			}
		}
	]

	render () {
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="col-sm-12">
				<GenericTable
                    pageHeading={this.getBreadcrumb()}
                    pageHeadingClass="h6 text-left"
                    data={this.receipt_notes}
                    recordsPerPage={this.recordsPerPage}
                    getRow={this.getRow}
                    getHeader={this.getHeader}
                    tableclass="table table-striped border-dark"
                    getFrame={this.getFrame}
                    pageChange={this.pageChange}
                    recordsPerFrame={this.recordsPerFrame}
                    button={this.buttons}
                    pageNumber={this.pageNumber}
                    isLoading={this.state.isLoading}
				/>
			</div>

		</div>

		let frm = <GenericForm datablk={ datablk } buttons={ [] } controlProps={ this.controlProps } { ...this.props } />
		return frm;
	}
}

export default ManageReceiptNotes;
