import React from 'react';
import {
    GenericForm,
    GenericComponent,
    FormField,
    valuePresent,
    copyDataFieldsToFormFields,
    //copyFormFieldsToDataFields,
    NarrowButton,
    InnerHTML,
    GenericTable,
    Modal_view_content,
    Modal_generic_content,
    Backdrop,

    MultiRecFormField,
    MultiRecHandler,
    MultiRec,

    getInitialState,
    deepCopy,
    raiseError,

    arrayBufferFromBase64,

    //document_number,
    //documentnumber,
    generic_textarea,
    generictextarea,
    generic_string,
    genericstring,
    generic_checkbox,
    genericcheckbox,
    //genericgenrefcode,
    //generic_genrefcode,
    inputdate,
    input_date,

} from 'WebUI_Framework';

import emailServiceRtn from './EmailServiceRoutines.js'
import partnerServiceRoutines from '../partners/PartnerServiceRoutines';
import commonServiceRoutines from '../common/CommonServiceRoutines';
import { populateOrgInfo } from '../common/utils'

const fields = {
    document_type: {
        id: "document_type", name: "document_type", label: window.getLabelLiteralWithColon("DOCUMENT_TYPE"),
        //class: genericgenrefcode, component: generic_genrefcode, mandatory: true
        class: genericstring, component: generic_string, mandatory: false
    },
    id: {
        id: "id", name: "id", label: "",
        class: genericstring, component: generic_string, mandatory: true,
    },
    date: {
        id: "date", name: "date", label: window.getLabelLiteralWithColon("EMAIL_DATE"),
        class: inputdate, component: input_date, mandatory: true,
    },
    from: {
        id: "from", name: "from", label: window.getLabelLiteralWithColon("EMAIL_FROM"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    to: {
        id: "to", name: "to", label: window.getLabelLiteralWithColon("RECEIVED_BY"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    delivered_to: {
        id: "delivered_to", name: "delivered_to", label: window.getLabelLiteralWithColon("RECEIVED_BY"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    subject: {
        id: "subject", name: "subject", label: window.getLabelLiteralWithColon("SUBJECT"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    html_body: {
        id: "html_body", name: "html_body", label: window.getLabelLiteralWithColon("EMAIL_BODY"),
        class: generictextarea, component: generic_textarea, mandatory: true,
    },
    message_body: {
        id: "message_body", name: "message_body", label: window.getLabelLiteralWithColon("EMAIL_BODY"),
        class: generictextarea, component: generic_textarea, mandatory: true,
    },
};

const mapping = [
    ["id", "id"],
    ["date", "date"],
    ["from", "from"],
    ["delivered_to", "delivered_to"],
    ["to", "to"],
    ["subject", "subject"],
    ["html_body", "html_body"],
    ["message_body", "message_body"],
];

const attachment_fields = {
    selected: {
        id: "selected", name: "selected", label: window.getLabelLiteralWithColon("FILE_NAME"),
        class: genericcheckbox, component: generic_checkbox, mandatory: true,
    },
    file_name: {
        id: "file_name", name: "file_name", label: window.getLabelLiteralWithColon("FILE_NAME"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    mime_type: {
        id: "mime_type", name: "mime_type", label: window.getLabelLiteralWithColon("MIME_TYPE"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    size: {
        id: "size", name: "size", label: window.getLabelLiteralWithColon("SIZE"),
        class: genericstring, component: generic_string, mandatory: true,
    },
    category: {
        id: "category", name: "category", label: window.getLabelLiteralWithColon("CATEGORY"),
        class: genericstring, component: generic_string, mandatory: false,
    },
}

const attachment_mapping = [
    ["selected", "selected"],
    ["file_name", "file_name"],
    ["mime_type", "mime_type"],
    ["size", "size"],
    ["category", "category"],
]

const ATTACHMENTS_MREC = "ATTACHMENTS_MREC"
const documentTypes = {
    "3": "Received Purchase Order",
    "8": "Purchase Invoice",
};


class ProcessEmail extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;

        this.readOnly = false;
 
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();

        this.action = props.action
        this.message_id = props.messageId;
        this.inbound_email = props.inboundEmail;
        this.email = {};
        this.full_mail_fetched = false;
        this.view_modal_content = <></>;
        this.state.show_view_modal = false;
        this.proceed_modal_content = <></>;
        this.state.show_proceed_modal = false;
        this.file_name = ""
        this.attachments = [];

        this.logged_in_org_id = this.session.getLoggedInCompanyId();
        this.logged_in_org_name = this.session.getLoggedInCompanyName();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_org_id === this.parent_org_id);

        if (this.action === "VIEW") {
            this.page_title = window.getLabelLiteral("VIEW_EMAIL");
            this.readOnly = true;
        }
        else {
            this.page_title = window.getLabelLiteral("PROCESS_EMAIL");
        }

        this.attHandler = new MultiRecHandler(this.session, attachment_fields, attachment_mapping, ATTACHMENTS_MREC);
    }

    initialState = getInitialState(fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                pageNumber: this.props.pageNumber,
                selectData: this.email, error
            });
        else console.log("LOADED AS A PARENT");
    }

    backHandler_1 = (retFrom, output) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                pageNumber: this.props.pageNumber,
                selectData: output 
            });
        else console.log("LOADED AS A PARENT");
    }

    fetch = async () => {
        const methodName = (this.readOnly) ? "fetch" : "fetchFullMessage";
        const email = await emailServiceRtn[methodName](this.session, this.message_id);
        email.date = email.date.split('T')[0]

        if (!this.readOnly) {
            this.full_mail_fetched = true;
        }

        return email;
    }

    componentDidMount = async () => {
        try {

            let newData = this.state.data;
            this.email = await this.fetch();
            try {
                this.partnership_rec = await
                    commonServiceRoutines.fetchPartnershipRec(this.session, this.logged_in_org_id, this.inbound_email.sender_org_id);
            } catch (e) {}
            if (!valuePresent(this.partnership_rec)) {
                this.partnership_rec = await
                    commonServiceRoutines.fetchPartnershipRec(this.session, this.inbound_email.sender_org_id, this.logged_in_org_id);
            }

            copyDataFieldsToFormFields(mapping, this.email, newData);

            if (valuePresent(this.email.attachments)) {
                for (let i=0; i<this.email.attachments.length; i++) {
                    this.attachments[i] = this.email.attachments[i];
                }
            }

            newData = this.attHandler.dataArrayToFormFields(this.attachments, newData);

            this.setState({ data: newData, loading: false})
        } catch(e) {
            this.setState({ loading: false });
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
        return;
    }

    getBody = (body) => {
        return <div className="col-sm-10 w-80 ">
            <div className="container">
                <div className="row">
                    <span className="col-form-label-sm text-secondary fw-bolder"
                            style={{fontSize:"0.9rem"}}>{body.label}</span>
                </div>
                <div className="row">
                    <div className="d-inline-block col-12">
                        <div className="d-inline-flex col-12">
                            <div id="documentation-display"
                                style={{
                                    height: "20rem",
                                    width:"100%",
                                    overflow: "scroll",
                                    background: "#fbfbfb",
                                    borderStyle:"groove",
                                }}
                            >
                                <InnerHTML rawHTML={this.state.data[body.id]}
                                    style={{
                                        marginTop:"5px",
                                        marginRight:"5px",
                                        marginBottom:"5px",
                                        marginLeft:"10px",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    fetchFullEmail = async () => {
        if (!(this.full_mail_fetched)) {
            try {
                const data = this.state.data;
                this.email = await emailServiceRtn.fetchFullMessage(this.session, this.message_id);
                this.email.date = this.email.date.split('T')[0]
                copyDataFieldsToFormFields(mapping, this.email, data);

                this.full_mail_fetched = true;

                this.setState({ data })
            }
            catch (e) {
                console.log(e);
                this.setError(raiseError(e.error_message));
            }
        }

        return;
    }

    showAttachment = async (rowData, pageNumber, arrayIndex) => {

        await this.fetchFullEmail();

        this.file_name = rowData.file_name;
        //const data = this.email.attachments[arrayIndex].data;
        const mimeType = this.email.attachments[arrayIndex].mime_type;
        if (this.email.attachments[arrayIndex].file_name !== this.file_name) {
            throw new Error("Something wrong has happened");
        }
        //const pdfUrl = `data:${mimeType};base64,${data}`;

        /*
         * Changed to showing file content in a new browser tab rather than
         * in a model window
        this.view_modal_content = <>
            <iframe
                src={pdfUrl}
                title={this.file_name}
                width="100%"
                height="600px"
                style={{ border: 'none' }}
            />
        </>;

        this.setState ( { show_view_modal : true });
        //window.open(pdfUrl, '_blank');
        */
        const byteArray = arrayBufferFromBase64(this.email.attachments[arrayIndex].data);
        const blob = new Blob([byteArray], { type: mimeType });

        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');

        URL.revokeObjectURL(blobUrl);
        this.file_name = ""
    }

    downloadAttachment = async (rowData, pageNumber, arrayIndex) => {
        await this.fetchFullEmail();

        const fileName = rowData.file_name;
        const data = arrayBufferFromBase64(this.email.attachments[arrayIndex].data);
        const mimeType = this.email.attachments[arrayIndex].mime_type;
        if (this.email.attachments[arrayIndex].file_name !== fileName) {
            throw new Error("Something wrong has happened");
        }

        const blob = new Blob([data], { type: mimeType });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    closeModal = () => {
        this.view_modal_content = <></>;
        this.file_name = ""
        this.setState ( { show_view_modal : false });
    }

    fileAction = async (rowData, pageNumber, arrayIndex) => {
        let action = 0;
        if (rowData.mime_type.substring(0, 11) === 'application') {
            switch (rowData.mime_type) {
                case 'application/pdf':
                    action = 1;
                    break;
                default:
                    action = 0;
                    break;
            }
        }
        else if (rowData.mime_type.substring(0, 5) === 'image') {
            action = 1;
        }
        if (action === 1) {
            return this.showAttachment(rowData, pageNumber, arrayIndex);
        }
        else {
            return this.downloadAttachment(rowData, pageNumber, arrayIndex);
        }
    }

    getRow = (data, index, pageNumber, arrayIndex) => {
        let style = { borderBottom: "0px solid" };
        let key = index;
        this.pageNumber = pageNumber;

        return( <tr key={++key}>
                <td style={style}>
                    <button type="button"
                        style={{
                            color: "blue", textDecoration: "underline", cursor: "pointer",
                            border: "none", background: "none", padding: 0, margin:0,
                            textAlign: "left"
                        }}
                        onClick={() =>
                            this.fileAction(data, pageNumber, arrayIndex)}
                    >
                        {data.file_name}
                    </button>
                </td>
                <td style={style}>{data.mime_type}</td>
                <td style={style}>{data.size}</td>
            </tr>
        );
        
    }

    getHeader() {
        let key = 0;
        return (
        <tr key={++key}>
            <td>{window.getLabelLiteral("FILE_NAME")}</td>
            <td>{window.getLabelLiteral("MIME_TYPE")}</td>
            <td>{window.getLabelLiteral("SIZE")}</td>
        </tr>
        );
    }

    getAttHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key} width={"5%"} >{window.getLabelLiteral("INCLUDE")}</th>
                <th key={++key} width={"40%"} >{window.getLabelLiteral("FILE_NAME")}</th>
                <th key={++key} >{window.getLabelLiteral("MIME_TYPE")}</th>
                <th key={++key} >{window.getLabelLiteral("CATEGORY")}</th>
            </tr>
        );
    }

    getAttRow = (rowData, index) => {
        const i = index - 1;
        let key = 0;
        let style = { borderBottom: "0px solid" };

        const clickables = <>
            &nbsp;&nbsp;
            <span onClick={() =>
                this.fileAction(rowData, 1, i)}
                title="File"
                style={{ cursor: 'pointer' }}>
                <i className="bi bi-info-square" stype={{ fontSize: 20 }}></i>
            </span>
        </>;

        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key} style={style} width={"5%"}>
                    <MultiRecFormField
                        field={attachment_fields.selected}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={ATTACHMENTS_MREC}
                        readOnly={this.readOnly}
                        maxLength={100}
                        containerClass={"m-0 p-0"}
                        style={{
                            padding:"0",
                            paddingTop:"5px",
                            paddingLeft:"10px"
                        }}
                    />
                </td>
                <td key={++key} style={style} width={"40%"}>
                    <MultiRecFormField
                        field={attachment_fields.file_name}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={ATTACHMENTS_MREC}
                        readOnly={true}
                        maxLength={256}
                        containerClass={"ms-0 ps-0"}
                        w_pcnt={"w-100"}
                        clickables={clickables}
                    />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField
                        field={attachment_fields.mime_type}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={ATTACHMENTS_MREC}
                        readOnly={true}
                        maxLength={100}
                        containerClass={"ms-0 ps-0"}
                    />
                </td>
                <td key={++key} style={style}>
                    <MultiRecFormField
                        field={attachment_fields.category}
                        controlProps={this.controlProps}
                        index={i}
                        multiRecId={ATTACHMENTS_MREC}
                        readOnly={this.readOnly}
                        maxLength={100}
                        containerClass={"ms-0 ps-0"}
                    />
                </td>
            </tr>
        </React.Fragment>
        return r;
    }

    render = () => {
        if (this.state.loading) {
            return <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }
        let key=0;
        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb([window.getLabelLiteral(this.action)])}
                    </div>
                    <hr></hr>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.date}
                            controlProps={this.controlProps}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        &nbsp;
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.from}
                            controlProps={this.controlProps}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={fields.to}
                            controlProps={this.controlProps}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-sm-12 justify-content-start">
                        <FormField
                            field={fields.subject}
                            controlProps={this.controlProps}
                            style={{
                                width: "90%"
                            }}
                            containerClass={"ms-0"}
                            readOnly={true}
                        />
                    </div>
                    {valuePresent(this.email.html_body) &&
                        <>
                            {this.getBody(fields.html_body)}
                        </>
                    }
                    {!valuePresent(this.email.html_body) && valuePresent(this.email.message_body) &&
                        <>
                            {this.getBody(fields.message_body)}
                        </>
                    }
                </div>
                {this.action === "VIEW" && valuePresent(this.email.attachments) &&
                    <div className="col-sm-8 mt-3" >
                        <div className="container ps-0">
                            <div className="row">
                                <span className="col-form-label-sm text-secondary fw-bolder"
                                        style={{fontSize:"0.9rem"}}>{window.getLabelLiteralWithColon("ATTACHMENTS")}</span>
                            </div>
                            <div className="row">
                                <GenericTable
                                    pageHeadingClass="col-form-label-sm text-secondary fw-bolder"
                                    data={this.email.attachments}
                                    recordsPerPage={this.recordsPerPage}
                                    getRow = {this.getRow}
                                    theadStyle={{fontWeight:"bold"}}
                                    getHeader={this.getHeader}
                                    tableclass={"table "}
                                    recordsPerFrame={this.recordsPerFrame}
                                    pageNumber={this.pageNumber}
                                    isLoading = {false}
                                    showSearch={false}
                                />
                            </div>
                        </div>
                    </div>
                }
                {this.action === "PROCESS" && valuePresent(this.email.attachments) &&
                    <div className="col-sm-8 mt-3" >
                        <div className="container ps-0">
                            <div className="row">
                                <span className="col-form-label-sm text-secondary fw-bolder"
                                        style={{fontSize:"0.9rem"}}>{window.getLabelLiteralWithColon("ATTACHMENTS")}</span>
                            </div>
                            <div className="row">
                                <MultiRec
                                    noInfoMessage={""}
                                    pageHeading={""}
                                    pageHeadingClass={"fs-6 fw-bold"}
                                    data={this.attachments}
                                    buttons={(this.readOnly) ? null : this.inboxes_buttons}
                                    getRow={this.getAttRow}
                                    getHeader={this.getAttHeader}
                                    tfootStyle={{ borderTop: "0.1rem solid" }}
                                    tbodyStyle={{ borderTop: "2.5px solid" }}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="row  p-0 m-0 g-0 " >
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                        <NarrowButton value="Reply" onClick={() => { return this.backHandler("SENDREPLY"); }} key={(++key).toString()} />
                        {this.action === "PROCESS" && 
                        <NarrowButton value="Proceed" onClick={() => { return this.showConfirmation(); }} key={(++key).toString()} />
                        }
                    </div>
                </div>
                {(this.state.show_view_modal) && <Modal_view_content
                    modalDialogClass={"modal-dialog"}
                    modalDialogStyle={{marginLeft: "5em"}}
                    width={"1200px"}
                    title={this.file_name}
                    btn1="Close"
                    onClick={this.closeModal}
                    modalContent={this.view_modal_content}
                />
                }
                {(this.state.show_view_modal) && <Backdrop />}
                {(this.state.show_proceed_modal) && <Modal_generic_content
                    modalDialogClass={"modal-dialog"}
                    modalDialogStyle={{marginLeft: "25em"}}
                    title={"Choose Document TYpe"}
                    btn1="Submit"
                    onConfirm={this.confirmAction}
                    btn2="Close"
                    onClick={this.closeProceedModal}
                    width={"400px"}
                    modalContent={this.proceed_modal_content}
                />
                }
                {(this.state.show_proceed_modal) && <Backdrop />}
            </div>
        )

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    confirmAction = async (control) => {
        if (this.state.data.document_type === "" || !valuePresent(this.state.data.document_type)) {
            control.error = true;
            control.errorMsg = "Document type should be selected";
            return ;
        }
        this.proceed_modal_content = <></>;
        this.setState ( { show_proceed_modal : false });
        return this.submitForm();
    }

    closeProceedModal = (control) => {
        this.proceed_modal_content = <></>;
        this.setState ( { show_proceed_modal : false });
    }

    showConfirmation = async () => {
        this.resetError();

        /*
        let count = 0;
        for (const att of this.attachments) {
            if (att.selected) {
                count++
                break;
            }
        }

        if (count === 0) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            this.setError(raiseError("No Attachments selected"));
            return;
        }
        */

        this.proceed_modal_content = <>
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-6">
                        <label htmlFor="document_type"
                            className="col-form-label-sm text-secondary fw-bolder"
                        >
                            {window.getLabelLiteral("DOCUMENT_TYPE")}
                        </label>
                        <select
                            type="text"
                            name="document_type"
                            id="document_type"
                            value={this.state.document_type}
                            onChange={(e) => this.setState((old) =>  { old.data.document_type = e.target.value; return old; } ) }
                            className={`form-control-sm border text-body`}
                        >
                            <option key="" value="">Select </option>
                            {Object.entries(documentTypes).filter(([key, value]) => {
                                if (this.logged_in_org_id === this.partnership_rec.parent_org_id) {
                                    if (key === "3" && this.partnership_rec.partner_relationship_type !== "S") {
                                        return true;
                                    }
                                    else if (key === "8" && this.partnership_rec.partner_relationship_type !== "D") {
                                        return true;
                                    }
                                }
                                else {
                                    if (key === "3" && this.partnership_rec.partner_relationship_type !== "D") {
                                        return true;
                                    }
                                    else if (key === "8" && this.partnership_rec.partner_relationship_type !== "S") {
                                        return true;
                                    }
                                }
                                return false;
                            }).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>;

        this.setState ( { show_proceed_modal : true });
    }

    getPrefilledOrder = async (attachments) => {
        const order = { header: {}, details : []};
        order.header.buyer_org_id = this.inbound_email.sender_org_id;
        order.header.buyer_org_name = this.inbound_email.sender_org_name;

        order.header.seller_org_id = this.logged_in_org_id;

        order.header.message_id = this.inbound_email.message_id;

        order.header.attachments = attachments;

        return order;
    }

    getPreFilledInvoice = async (attachments) => {
        const invoice = { header: {}, details : []};
        invoice.header.seller_org_id = this.inbound_email.sender_org_id;
        invoice.header.seller_org_name = this.inbound_email.sender_org_name;

        invoice.header.buyer_org_id = this.logged_in_org_id;
        invoice.header.buyer_org_name = this.logged_in_org_name;

        invoice.header.message_id = this.inbound_email.message_id;

        await populateOrgInfo(this.session, invoice.header, "buyer");
        await populateOrgInfo(this.session, invoice.header, "seller");

        invoice.header.attachments = attachments;

        const partnerParameters = await partnerServiceRoutines.fetchParameters(this.session, this.inbound_email.sender_org_id);
        invoice.header.currency = partnerParameters.currency;

        return invoice;
    }

    submitForm = async() => {
        this.setState({ loading: true });
        try {
            this.attHandler.formFieldsToDataArray(this.state.data, this.attachments);
            const attachments = [];
            let i = 0;
            for (const att of this.attachments) {
                if (att.selected) {
                    attachments[i++] = {
                        name : att.file_name,
                        type: att.mime_type,
                        size: att.size,
                        content: att.data,
                        category: att.category
                    };
                }
            }
            const doc = await (async () => {
                switch(this.state.data.document_type) {
                    case "3":
                        return await this.getPrefilledOrder(attachments);
                    case "8":
                        return await this.getPreFilledInvoice(attachments);
                    default:
                        throw new Error("Document type ["+this.state.data.document_type+"] not supported");
                }
            })();
            this.setState({ loading: false });
            this.backHandler_1(this.action, {document_type:this.state.data.document_type, document : doc});
        } catch(e) {
            this.setState({ loading: false });
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
        }
    }
};

export default ProcessEmail;


