import React from 'react';
import {
    //valuePresent,
	GenericComponent,
	email_id,
	emailid,
	user_name,
	username,
	org_name,
	orgname,
	contact_phone_number,
	contactphonenumber,
	address_line,
	addressline,
	City,
	city,
	State,
	state,
	Country,
	country,
	zip_code,
	zipcode,
	GenericForm,
	NarrowButton,
	FormField,
	fieldValidations,
	getInitialState,
	copyDataFieldsToFormFields,
	raiseError,
	copyFormFieldsToDataFields,
	deepCopy,
	MultiRec,
	MultiRecHandler,
	MultiRecFormField,
	//Backdrop,
	tax_number_type,
	taxnumbertype,
	tinnumber,
	tin_number,
	genericcheckbox,
	generic_checkbox
} from 'WebUI_Framework';

import companyServiecRoutines from '../partners/PartnerServiceRoutines';

const fields = {
	org_name: {
        id: "org_name", name: "org_name", label: window.getLabelLiteralWithColon("COMPANY_NAME"),
		class: orgname, component: org_name, mandatory: true
	},

	contact_phone_number: {
        id: "contact_phone_number", name: "contact_phone_number", label: window.getLabelLiteralWithColon("CONTACT_PHONE"),
        class: contactphonenumber, component: contact_phone_number, mandatory: true
	},

	contact_email_id: {
        id: "contact_email_id", name: "contact_email_id", label: window.getLabelLiteralWithColon("CONTACT_EMAIL"),
		class: emailid, component: email_id, mandatory: true
	},

	contact_name: {
		id: "contact_name", name: "contact_name", label: window.getLabelLiteralWithColon("CONTACT_PERSON"),
		class: username, component: user_name, mandatory: true
	},

	address_1: {
		id: "address_1", name: "address_1", label: window.getLabelLiteralWithColon("ADDRESS"),
		class: addressline, component: address_line, mandatory: true
	},

	address_2: {
		id: "address_2", name: "address_2", label: window.getLabelLiteralWithColon("ADDRESS_2"),
		class: addressline, component: address_line, mandatory: false
	},

	zip_code: {
		id: "zip_code", name: "zip_code", label: window.getLabelLiteralWithColon("ZIP_CODE"),
		class: zipcode, component: zip_code, mandatory: false
	},

	city: {
		id: "city", name: "city", label: window.getLabelLiteralWithColon("CITY"),
		class: city, component: City, mandatory: true
	},

	state: {
		id: "state", name: "state", label: window.getLabelLiteralWithColon("STATE"),
		class: state, component: State, mandatory: false
	},

	cntry: {
		id: "cntry", name: "cntry", label: window.getLabelLiteralWithColon("CNTRY"),
		class: country, component: Country, mandatory: false
	},
	is_subscriber: {
        id: "is_subscriber", name: "is_subscriber", label: window.getLabelLiteralWithColon("IS_SUBSCRIBER"),
		class: genericcheckbox, component: generic_checkbox, mandatory: false
	},
}


let mapping = [
	["org_name", "org_name"],
	["contact_phone_number", "contact_phone_number"],
	["contact_email_id", "contact_email_id"],
	["contact_name", "contact_name"],
	["address_1", "address_line_1"],
	["address_2", "address_line_2"],
	["zip_code", "zip_code"],
	["city", "city"],
	["state", "state"],
	["cntry", "cntry"],
	["version", "version"],
	["org_id", "org_id"],
	["is_subscriber", "is_subscriber"],
];

const TAX_MREC = "TAX";
const tax_type = window.getGenericRefCode('TaxType');

const tax_fields = {
	tax_number_type : { id: "tax_number_type", name : "tax_number_type", label: "",
        class : taxnumbertype, component: tax_number_type, mandatory: true},
    tax_number : { id: "tax_number", name : "tax_number", label: "",
        class : tinnumber, component: tin_number, mandatory: true},
}


let tax_mapping = [
	["tax_number", "tax_identifier"],
	["tax_number_type", "tax_identity_type"]
]

class EditCompany extends GenericComponent {
	constructor(props) {
		super(props);
		this.selectedOrgData = {company_tax_dtls : []};
		this.state.mode = "add";
		this.state.arrayIndex = 0;
		this.tableclass = "t";
        this.state.editCount = 0;

		this.serverInstance = this.session.getServerInstance('ROC');
		this.readOnly = (this.props.action === "VIEW");
        this.action = this.props.action;
		this.taxHandler = new MultiRecHandler(this.session, tax_fields, tax_mapping, TAX_MREC);

		this.accessToken = this.session.getAccessToken();
        this.logged_in_org_id = this.session.getLoggedInCompanyId();
        this.parent_org_id = this.session.getLoggedInParentCompanyId();
	}

	initialState = getInitialState(fields);

	state = {
		data: deepCopy(this.initialState),
	}

	componentDidMount() {
		this.getSelectedOrgData();
	}

	async getSelectedOrgData() {
		let retData;
		try {
			retData = await companyServiecRoutines.fetch(this.serverInstance, this.accessToken, this.logged_in_org_id);
			if (retData !== undefined && retData !== null) {
				this.selectedOrgData = retData;
				if(this.selectedOrgData.company_tax_dtls === undefined){
					this.selectedOrgData.company_tax_dtls = [];
				}
				let newData_1, newData_2
				newData_1 = deepCopy(this.state.data);
                copyDataFieldsToFormFields(mapping, this.selectedOrgData, newData_1);
                newData_2 = this.taxHandler.dataArrayToFormFields(this.selectedOrgData.company_tax_dtls, newData_1);
				this.setState({data : newData_2});
			}
		}
		catch (e) {

			this.setError(raiseError(e.error_message));
		}
	}

    onReturn = (ret) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        this.resetError();
        if (ret.error) {
            this.setError(ret.error);
            return;
        }

        /*eslint no-fallthrough: 0*/
        switch (ret.retFrom) {
            case "EDIT":
            case "VIEW":
                this.setState((prevState, props) => {
                    this.selectedOrgData = ret.selectData;
                    //prevState.data = this.initializations(prevState.data);

                    if(this.selectedOrgData.company_tax_dtls === undefined){
                        this.selectedOrgData.company_tax_dtls = [];
                    }
                    let newData;
                    newData = deepCopy(prevState.data);
                    copyDataFieldsToFormFields(mapping, this.selectedOrgData, newData);
                    newData = this.taxHandler.dataArrayToFormFields(this.selectedOrgData.company_tax_dtls, newData);

                    prevState.data = newData

                    prevState.editCount++;

                    return prevState;
                });
                break;
            case 'BACK':
                this.setState({ editCount: this.state.editCount + 1});
                break;
            default:
                this.setError(raiseError("Invalid return from EditCompany"));
        }

    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.selectedOrgData, error
            });
        else {
            this.setState({ loading: false });
        }
    }

	addTax = () => {
		if (this.selectedOrgData.company_tax_dtls.length === Object.keys(tax_type).length) {
			return;
		}
		let data = this.state.data;
		this.resetError();
		let [newData, new_list] = this.taxHandler.addMultiRecRow(data, this.selectedOrgData.company_tax_dtls);
		this.selectedOrgData.company_tax_dtls = new_list;
		this.setState({ data: newData });
	}

	deleteRow = (rowData, i) => {
		if (this.selectedOrgData.company_tax_dtls[i].version === null ||
			this.selectedOrgData.company_tax_dtls[i].version === undefined) {
			this.resetError();
			let [newData, updated_list] = this.taxHandler.deleteMultiRecRow(this.state.data, this.selectedOrgData.company_tax_dtls, i);
			this.selectedOrgData.company_tax_dtls = updated_list;
			this.setState({ data: newData });
		}
		else {
			this.selectedOrgData.company_tax_dtls[i].deleted = true;
		}
	}


	getRowTax = (rowData, index) => {
		const i = index - 1;

		let key = 0;
		let r = <React.Fragment key={index}>
			<tr key={++key}>
			<	td key={++key}>
                	<MultiRecFormField field={tax_fields.tax_number_type} controlProps={this.controlProps} index={i}
                    multiRecId={TAX_MREC} readOnly={this.readOnly}/>
                </td>
                <td key={++key}>
                    <MultiRecFormField field={tax_fields.tax_number} controlProps={this.controlProps} index={i}
                    multiRecId={TAX_MREC} readOnly={this.readOnly}/>
                </td>
				{ (!this.readOnly) &&
					<td key={++key}>
					<div style={{ paddingLeft: "20px", cursor: "pointer" }}>
						<span onClick={() => { return this.deleteRow(rowData, i); }} data-toggle="tooltip" title="Delete">
							<i className="bi bi-trash3" style={{ fontSize: 20 }}></i>
						</span>
					</div>
				</td>
				}

			</tr>
		</React.Fragment>
		return r;
	}

	getHeaderTax = () => {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("TAX_TYPE")}</th>
				<th key={++key}>{window.getLabelLiteral("TAX_NUMBER")}</th>
				{
					(!this.readOnly) &&
					<th key={++key}>Actions</th>
				}
			</tr>
		);
	}

	button_inputs = [
		{
			literal: window.getLabelLiteral("ADD"),
			func: () => this.addTax()
		}
	]

    handler = (action) => {
        this.callForm(EditCompany, { ...this.props, action }, this.onReturn);
    }

	render() {
		let buttons = [];
		let key = 0;
		if (this.readOnly) {
			this.disabled = true;
		}

		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="row  p-0 m-0 g-0 " >

                <div className="container-xxl row border border-0 p-0 m-0 ">
                    <div className="col-4 m-0 p-0 g-0">
                        <span className="h6 text-start">
                            {this.getBreadcrumb([window.getLabelLiteral(this.action)])}
                        </span>
                    </div>
                    <div className="d-inline-flex col-7 justify-content-end">
                        <span className="h6 text-center">
                            {(this.action === "VIEW")&&
                                <button type="button"
                                    style={{
                                        color: "blue", textDecoration: "underline", cursor: "pointer",
                                        border: "none", background: "none", padding: 0, margin:0,
                                        textAlign: "left"
                                    }}
                                    onClick={() => this.handler("EDIT")}
                                >
                                    {window.getLabelLiteral("EDIT_COMPANY")}
                                </button>
                            }
                        </span>
                    </div>
                    <div className="col-1 ">
                        &nbsp;&nbsp;
                    </div>
                </div>
                <hr></hr>

				<div className="col-sm-6" >
					<FormField field={fields.org_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.contact_phone_number} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.contact_email_id} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.contact_name} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.address_1} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.address_2} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.zip_code} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.city} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.cntry} controlProps={this.controlProps} readOnly={this.readOnly} />
				</div>

				<div className="col-sm-6" >
					<FormField field={fields.state}
						controlProps={this.controlProps}
						country={this.state.data.cntry}
						readOnly={this.readOnly}
					/>
				</div>
			</div>
			<br></br>
			<br></br>
			<br></br>
			<br></br>

            { (!this.readOnly ||
                this.selectedOrgData?.company_tax_dtls?.length > 0) &&
			<div className="col-sm-12" >
				<div className="col-sm-9">
					<MultiRec
						pageHeading={"Tax Details"}
						key={++key}
						getRow={this.getRowTax}
						getHeader={this.getHeaderTax}
						data={this.selectedOrgData.company_tax_dtls}
						buttons={(this.readOnly) ? null : this.button_inputs}
						tfootStyle={{ borderTop: "0.1rem solid" }}
						tbodyStyle={{ borderTop: "2.5px solid" }}
					/>

				</div>
			</div>
            }
			<div className="row  p-0 m-0 g-0 " >

				<div className="col-sm-6 mt-4 d-flex justify-content-right ">
					{(!this.readOnly) && 
                    <>
                        <NarrowButton value="Update" onClick={this.submitForm} key={(++key).toString()} />
                        <NarrowButton value="Back" onClick={()=>this.backHandler(this.action)} key={(++key).toString()} />
                    </>
					}
				</div>
			</div>

		</div>


		let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
		return form;
	}

	validateForm = () => {
		let tax_details = {};
		this.taxHandler.formFieldsToDataArray(this.state.data, tax_details);
		const taxIdentityTypeSet = new Set();
		// eslint-disable-next-line no-unused-vars
		for(const [k, v] of Object.entries(tax_details)){
			if(taxIdentityTypeSet.has(v.tax_identity_type)){
				this.setError(raiseError(window.getLiterals("REPEATED_TAX_TYPE_NOT_ALLOWED")));
				return false;
			}
			else{
				taxIdentityTypeSet.add(v.tax_identity_type);
			}
		}
		// eslint-disable-next-line no-unused-vars
		for (const [key, value] of Object.entries(tax_details)) {
			const taxIdentityType = value.tax_identity_type;
			const taxIdentifier = value.tax_identifier;
			if (taxIdentityType === 'IP'){
				if (/^([A-Z]{5}[\d]{4}[A-Z])$/.test(taxIdentifier) === false) {
					this.setError(raiseError(window.getLiterals("PAN_NO_INVALID")));
					return false;
				}
			}
			else if(taxIdentityType === 'IT'){
				if (/^([A-Z]{4}[\d]{5}[A-Z])$/.test(taxIdentifier) === false) {
					this.setError(raiseError(window.getLiterals("TAN_NO_INVALID")));
					return false;
				}
			}
		}
		let r = fieldValidations(fields, this.state.data);
		if (!r.status) {
			this.setError(r);
			return false;
		}
		return true;
	}

	submitForm = async () => {
		this.resetError();
		if (!this.validateForm()) {
			return;
		}
		copyFormFieldsToDataFields(mapping, this.state.data, this.selectedOrgData, fields);
		this.taxHandler.formFieldsToDataArray(this.state.data, this.selectedOrgData.company_tax_dtls);
        try {
			const retData = await companyServiecRoutines.modify(this.serverInstance, this.accessToken, this.selectedOrgData);
			if (retData !== undefined && retData !== null) {
				this.backHandler(this.action);
			}
        }
        catch (e) {
            //nsole.log(this.selectedOrgData);
            console.log(e)
            this.setError(raiseError(e.error_message));
        }
	}

}

export default EditCompany;
