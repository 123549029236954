import { valuePresent, FormField, MultiRecFormField } from 'WebUI_Framework';
import { useEffect, useState } from "react";
import commonServiceRoutines from './CommonServiceRoutines'

function PartnerList(props) {
	if (typeof(props.selectOption) !== "function") {
		throw new Error("PartnerList: Invalid input selectOption");
	}
	if (props.field === null || props.field === undefined) {
		throw new Error("PartnerList: Field definition must be passed");
	}
	if (props.controlProps === null || props.controlProps === undefined) {
		throw new Error("PartnerList: controlProps definition must be passed");
	}
	let readOnly = false;
	if (props.readOnly !== null && props.readOnly !== undefined) {
		readOnly = props.readOnly;
	}
	let onChange = (event) => {
		if (props.onChange) {
			return props.onChange(event);
		}
	};
	let inMultiRec = false;
	let i;
	if (props.inMultiRec !== null && props.inMultiRec !== undefined) {
		inMultiRec = props.inMultiRec;
		if (props.i === null || props.i === undefined) {
			throw new Error("PartnerList: iterator i is necessary in a multirec field");
		}
		i = props.i;
	}
	let relationship = 'B';
	if (props.relationship !== undefined && props.relationship !== null) {
		relationship = props.relationship;
	}

	const session = props.controlProps.cInstance.session;
	if (session === null || session === undefined) {
		throw new Error("PartnerList: Unable to deduce session");
	}
	const accessToken = session.getAccessToken();
	if (accessToken === null || accessToken === undefined) {
		throw new Error("PartnerList: Unable to deduce accessToken");
	}
	const loggedInCompanyId = session.getLoggedInCompanyId();
	if (loggedInCompanyId === null || loggedInCompanyId === undefined) {
		throw new Error("PartnerList: Unable to deduce loggedInCompanyId");
	}
	const parentCompanyId = session.getLoggedInParentCompanyId();
	if (parentCompanyId === null || parentCompanyId === undefined) {
		throw new Error("PartnerList: Unable to deduce parentCompanyId");
	}
	const isSubscriber = (loggedInCompanyId === parentCompanyId);

	let [orgName, setOrgName] = useState(!(props.org_name)?"":props.org_name);
	let [orgId, setOrgId] = useState(!(props.org_id)?"":props.org_id);

	let f = async () => {
		if (isSubscriber === false) {
			const parentOrg =
				await commonServiceRoutines.fetchCompany(session, parentCompanyId);
			setOrgName(parentOrg.org_name);
			setOrgId(parentOrg.org_id);
			props.selectOption(parentOrg.org_id, parentOrg.org_name);
		}
	}

	useEffect(() => {
		f();
		// eslint-disable-next-line
	}, [] );

	let formatDataList = (array) => {
		let formatted_array = [];
		array.map((value, index) => {
			let s = value.org_name;
			formatted_array.push(s)
			return s;
		})
		return formatted_array;
	}

	let clearSelection = () => {
		orgId = '';
		orgName = '';
		props.selectOption(orgId, orgName);
		setOrgName(orgName);
		setOrgId(orgId);
	}

	let local_selectOption = (index, list) => {
		return props.selectOption(list[index].org_id, list[index].org_name);
	}

	let getDataList = async (control, searchText) => {
		try {
			const retData =
				await commonServiceRoutines.fetchPartners(session, loggedInCompanyId, relationship);
            let value;
            if (valuePresent(props.value)) {
                value = props.value;
            }
            else {
                const state_data = props?.controlProps?.cInstance?.state?.data;
                if (valuePresent(state_data) && valuePresent(state_data[props.field.name]) && state_data[props.field.name] !== "") {
                    value = state_data[props.field.name];
                }
            }
            if (valuePresent(value)) {
                let found = false;
                for (const p of retData) {
                    if (p.org_id === value) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    control.error = true;
                    control.errorMsg = (value+" not a valid partner");
                }
            }
			return retData;
		}
		catch (e) {
			control.error = true;
			if (e.error_message !== null && e.error_message !== undefined) {
				control.errorMsg = e.error_message;
			}
			else {
				control.errorMsg = e.message;
			}
		}
	}

    /*
    let value;
    if (valuePresent(props.value)) {
        value = props.value;
    }
    else {
        const state_data = props?.controlProps?.cInstance?.state?.data;
        if (valuePresent(state_data) && valuePresent(state_data[props.field.name]) && state_data[props.field.name] !== "") {
            value = state_data[props.field.name];
        }
    }
    */

	if (!inMultiRec) {
		return (isSubscriber === true) ?
				<FormField
					field={ props.field }
					controlProps={ props.controlProps }
					getDataList={(control, searchtext) => { return getDataList(control, searchtext)}}
					formatDataList={ formatDataList }
					selectOption={ local_selectOption }
					clearSelection={clearSelection}
					readOnly={readOnly}
					onChange={onChange}
					style={props.style}
					width2={props.width2}
					w_pcnt={props.w_pcnt}
					w_pcnt1={props.w_pcnt1}
					w_pcnt2={props.w_pcnt2}
					keyName={"org_id"}
					descName={"org_name"}
                    dependency={props.dependency}
				/>
			:
				<FormField
						field={ props.field }
						controlProps={ props.controlProps }
						formatDataList = { formatDataList }
						readOnly={ true }
						style={props.style}
						width2={props.width2}
						w_pcnt={props.w_pcnt}
						w_pcnt1={props.w_pcnt1}
						w_pcnt2={props.w_pcnt2}
                        keyName={"org_id"}
                        descName={"org_name"}
                        dependency={props.dependency}
                        value={orgName}
				/>
			;
	}
	else {
		return (isSubscriber === true) ?
				<MultiRecFormField
					field={ props.field }
					controlProps={ props.controlProps }
					getDataList={(control, searchtext) => { return getDataList(control, searchtext)}}
					formatDataList={ formatDataList }
					selectOption={ local_selectOption }
					clearSelection={clearSelection}
					readOnly={readOnly}
					onChange={onChange}
					index={i}
					style={props.style}
					width2={props.width2}
					w_pcnt={props.w_pcnt}
					w_pcnt1={props.w_pcnt1}
					w_pcnt2={props.w_pcnt2}
					multiRecId={props.multiRecId}
					keyName={"org_id"}
					descName={"org_name"}
                    dependency={props.dependency}
				/>
			:
				<MultiRecFormField
					field={ props.field }
					controlProps={ props.controlProps }
					formatDataList = { formatDataList }
					readOnly={ true }
					index={i}
					style={props.style}
					width2={props.width2}
					w_pcnt={props.w_pcnt}
					w_pcnt1={props.w_pcnt1}
					w_pcnt2={props.w_pcnt2}
					multiRecId={props.multiRecId}
                    keyName={"org_id"}
                    descName={"org_name"}
                    dependency={props.dependency}
                    value={orgName}
				/>
			;
	}

}

export default PartnerList;
