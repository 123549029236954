import {
	GenericComponent,
	GenericForm,
	getInitialState,
	GenericTable,
	deepCopy,
	raiseError,
    valuePresent,
} from 'WebUI_Framework';

import 'bootstrap-icons/font/bootstrap-icons.css';
import ViewSalesOrder from './ViewSalesOrder';
import AddEditOrder from './AddEditOrder';
import salesOrdersServiceRoutines from './SalesOrdersServiceRoutines';

class ManageSalesOrders extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		this.selectOrderId = 0;
		//this.recordsPerPage = 7;
        this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.state = {
			isLoading: true,
            editCount: 0
		}
		this.getRow = this.getRow.bind(this);
		this.getFrame = this.getFrame.bind(this);
		this.company_id = this.session.getLoggedInCompanyId();
        this.orders = [];
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	}

	viewHandler = async (orderId) => {
		this.selectOrderId = orderId;
		try {
			const retData = await salesOrdersServiceRoutines.fetch(this.session, this.selectOrderId)
			if (retData !== undefined && retData !== null) {
				let orderDetails = retData.details;
				let orderHeaderObject = retData.header;
				this.callForm(ViewSalesOrder, { orderDetails, orderHeaderObject , ...this.props }, this.onReturn);
			}
		}
		catch (e) {
			this.setError(raiseError(e.error_message));
		}
	}

	async getFrame(offset, recordsPerFrame) {
		try {
			const retData = await salesOrdersServiceRoutines.list(this.session, offset, recordsPerFrame, this.company_id);
			if (retData.length > 0) {
				this.orders = this.orders.concat(retData);
			}
			this.setState({ isLoading: false })
            this.setState({ editCount: ++this.state.editCount });
		}
		catch (e) {
			this.setError(raiseError(e.error_message));
		}
	}

	onAddEditReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				this.orders.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
				break;
			case "EDIT":
			case "CANCEL":
			case "ACCEPT":
			case "REJECT":
			case "AMEND":
			case "APPROVE":
			case "APPROVEAMENDMENT":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.orders[ updatedArrayIndex ] = ret.selectData.header;
                    if (valuePresent(ret.referralId)) {
                        this.orders[ updatedArrayIndex ].referral_id = ret.referralId;
                    }

					let newState = deepCopy(prevState);
					newState.editCount ++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.orders.splice(ret.arrayIndex, 1);
				this.setState({editCount: this.state.editCount + 1});
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditInvoice"));
		}
		return ;
	}

    crudHandler = async (action, orderId, pageNumber, arrayIndex) => {
        this.pageNumber = pageNumber;
		this.callForm(AddEditOrder, { action, orderId, pageNumber, arrayIndex,  ...this.props }, this.onAddEditReturn);
    }

	getRow(data, index, pageNumber, arrayIndex) {
		this.pageNumber = pageNumber;
		return (
			<tr key={index}>
				<td>
					<button type="button" style={{
						color: "blue", textDecoration: "underline",
						cursor: "pointer", border: "none", background: "none", padding: "0"
					}}
						onClick={() => this.viewHandler(data.order_id)}>{data.order_number}
					</button>
				</td>
				<td>{data.order_issue_date}</td>
				<td>{data.buyer_org_name}</td>
				<td>{data.description}</td>
				<td>{data.order_valid_from_date}</td>
				<td>{data.order_valid_to_date}</td>
				<td>
                    {(data.owner_org_id === data.seller_org_id) &&
                        <>
                            {(data.entity_state === "0" && data.deleted === false && !valuePresent(data.referral_id)) &&
                            <>
                                <span onClick={() =>
                                    this.crudHandler("EDIT", data.order_id, pageNumber, arrayIndex)}
                                    title='Edit' style={{cursor : "pointer"}}>
                                    <i className="bi bi-pencil-square" style={{ fontSize: "20" }} ></i>
                                </span>&nbsp;&nbsp;
                                <span onClick={() =>
                                    this.crudHandler("DELETE", data.order_id, pageNumber, arrayIndex)}
                                    title='Delete' style={{cursor : "pointer"}}>
                                    <i className="bi bi-trash3" style={{ fontSize: "20" }} ></i>
                                </span>
                                &nbsp;&nbsp;
                                <span onClick={() =>
                                    this.crudHandler("APPROVE", data.order_id, pageNumber, arrayIndex)}
                                    title='Approve' style={{cursor : "pointer"}}>
                                    <i className="bi bi-check2" style={{ fontSize: "20" }} ></i>
                                </span>&nbsp;&nbsp;
                            </>
                            }
                            {(data.entity_state === '1') &&
                            <>
                                <span onClick={() =>
                                    this.crudHandler("AMEND", data.order_id, pageNumber, arrayIndex)}
                                    title='Amend' style={{cursor : "pointer"}}>
                                    <i className="bi bi-pen" style={{ fontSize: "20" }} ></i>
                                </span>&nbsp;&nbsp;
                            </>
                            }
                            {(data.entity_state === '2') &&
                            <>
                                <span
                                    onClick={() => this.crudHandler("APPROVEAMENDMENT",
                                                        data.order_id, pageNumber, arrayIndex)}
                                    title='Approve' style={{cursor : "pointer"}}>
                                    <i className="bi bi-check2" style={{ fontSize: "20" }} ></i>
                                </span>&nbsp;&nbsp;
                                <span onClick={() =>
                                    this.crudHandler("AMEND", data.order_id, pageNumber, arrayIndex)}
                                    title='Amend' style={{cursor : "pointer"}}>
                                    <i className="bi bi-pen" style={{ fontSize: "20" }} ></i>
                                </span>&nbsp;&nbsp;
                                <span onClick={() => this.crudHandler("CANCEL", data.order_id, pageNumber, arrayIndex)}
                                    title={window.getLabelLiteral("CANCEL")}
                                    style={{ cursor: 'pointer' }}>
                                    <i className="bi bi-x-square" style={{ fontSize: 20 }} ></i>
                                </span>
                            </>
                            }
                        </>
                    }
                </td>
			</tr>
		)
	}

	getHeader() {
		let key = 0;
		return (
			<tr key={++key}>
				<th key={++key}>Order Number</th>
				<th key={++key}>Issue Date</th>
				<th key={++key}>Issued By</th>
				<th key={++key}>Description</th>
				<th key={++key}>Valid From </th>
				<th key={++key}>Valid Till</th>
				<th key={++key}></th>
			</tr>
		);
	}

    table_buttons = [
        /*
        {
            literal: "Filter",
            func: () => {
                this.setState({ viewFilter: true });
            }
        },
        */
		{
			literal: "Add",
			func: () => {
				const ucp = this.session.getUserCompanyProps(this.session.getLoggedInCompanyId(),
															this.session.getLoggedInParentCompanyId());
				if (!(ucp.org_is_subscriber)) {
					if (ucp.partner_relationship_type === 'S') {
						this.setError(raiseError(window.parseLiteral("CANNOT_CREATE_PURCHASE_ORDER"
															, this.session.getLoggedInCompanyName())));
						return false;
					}
				}
				this.crudHandler("ADD", null, this.pageNumber, 0);
			}
		}
    ];

	render() {
		let buttons = [];

		const tableclass = "table table-striped border-dark";
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="col-sm-12">
				<GenericTable
					pageHeading={this.getBreadcrumb()}
					pageHeadingClass="h6 text-left"
					data={this.orders}
					recordsPerPage={this.recordsPerPage}
					getRow={this.getRow}
					getHeader={this.getHeader}
					tableclass={tableclass}
					getFrame={this.getFrame}
					recordsPerFrame={this.recordsPerFrame}
					pageNumber={this.pageNumber}
					isLoading={this.state.isLoading}
					button={this.table_buttons}
				/>
			</div>

		</div>

		let frm = <GenericForm
			datablk={datablk} buttons={buttons}
			controlProps={this.controlProps}
			{...this.props} />

		return frm;
	}
}

export default ManageSalesOrders;
