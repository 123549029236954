import { deepCopy, valuePresent, FormField, MultiRecFormField } from 'WebUI_Framework';
import { useRef, useEffect, useState } from "react";

import subscriberServiceRoutines from "../subscribers/SubscriberServiceRoutines";

const InboxName = (props) => {
    const session = props.session;
    const parentOrgId = session.getLoggedInParentCompanyId();
    const documentType = props.documentType;
    let dependency = deepCopy(props.dependency);

    if (!valuePresent(session)) {
        throw new Error("InboxName: props.session missing");
    }
    if (!dependency) dependency = [];

    let inMultiRec = false;
    let i;
    if (props.inMultiRec !== null && props.inMultiRec !== undefined) {
        inMultiRec = props.inMultiRec;
        if (props.i === null || props.i === undefined) {
            throw new Error("InboxName: iterator i is necessary in a multirec field");
        }
        i = props.i;
    }
    const list = useRef([]);
    let [c, setC] = useState(0);

    let f = async (documentType) => {
        const subscriberParameters = await subscriberServiceRoutines.fetchParameters(session, parentOrgId);
        const inboxes = [];
        let i = 0;
        for (let element of subscriberParameters.valid_inboxes) {
            if (!valuePresent(element.document_type) ||
                element.document_type.trim() === "" ||
                element.document_type === documentType) {

                inboxes[i++] = { name : element.name, desc: element.name }
            }
        }

        list.current = inboxes;
        setC(++c);
    }

    useEffect(() => {
        if (valuePresent(documentType) && documentType !== "") { f(documentType); }
        else {
            list.current = [];
            props.controlProps.cInstance.setFieldValueInState(props.field.id, "");
            setC(++c);
        }
        // eslint-disable-next-line
    }, [documentType] );

    if (!inMultiRec) {
        return <FormField
                    {...props}
                    field={ props.field }
                    controlProps={ props.controlProps }
                    keyName={"name"}
                    descName={"desc"}
                    list={list.current}
                    dependency={[...dependency, c]}
                />
            ;
    }
    else {
        return <MultiRecFormField
                    {...props}
                    field={ props.field }
                    controlProps={ props.controlProps }
                    index={i}
                    keyName={"name"}
                    descName={"desc"}
                    list={list.current}
                    dependency={[...dependency, c]}
                />
            ;
    }
}


export default InboxName;
