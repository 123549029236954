import React from 'react';
import {
    raiseError,
    //GenericComponent,
    email_id,
    emailid,
    user_name,
    username,
    contact_phone_number,
    contactphonenumber,
    genericstring,
    generic_string,
    documentnumber,
    document_number,
    inputdate,
    input_date,
    currency,
    Currency,
    getInitialState,
    deepCopy,
    FormField,
    NarrowButton,
    GenericForm,
    copyDataFieldsToFormFields,
    GenericTable,
    genericdecimal,
    generic_decimal,
    amount_textbox,
    amounttextbox,
    FileSet,
    file_set,
    GenericError,
} from 'WebUI_Framework';

import { BaseComponent } from '../common/BaseComponent';
import SubmitReferral from '../referral/SubmitReferral';

import Attachments from '../common/Attachments'
import receivedInvoiceServiceRoutines from './ReceivedInvoiceServiceRoutines';

import referralServiceroutines from '../referral/ReferralServiceRoutines'

const DOCUMENT_TYPE = "8";

let tax_code = {
    CGST: "Total CGST",
    IGST: "Total IGST",
    SGST: "Total SGST",
    VAT: "Total VAT",
    total_amount: "Total Invoice Amount",
};

let mapping = [
    ["invoice_number", "invoice_number"],
    ["invoice_date", "invoice_date"],
    ["order_number", "order_number"],
    ["order_issue_date", "order_issue_date"],
    ["payment_due_date", "payment_due_date"],
    ["seller_contact_user_name", "seller_contact_user_name"],
    ["seller_contact_email_id", "seller_contact_email_id"],
    ["seller_contact_phone_num", "seller_contact_phone_num"],
    ["buyer_contact_user_name", "buyer_contact_user_name"],
    ["buyer_contact_email_id", "buyer_contact_email_id"],
    ["buyer_contact_phone_num", "buyer_contact_phone_num"],
    ["notes", "notes"],
    ["discount_percentage", "discount_percentage"],
    ["discount_amount", "discount_amount"],
    ["currency", "currency"],
    ["payment_due_date", "payment_due_date"],
    ["seller_org_name", "seller_org_name"],
    ["attachments", "attachments"],
];


const fields = {
    invoice_number: {
        id: "invoice_number",
        name: "invoice_number",
        label: "Invoice Number:",
        class: documentnumber,
        component: document_number,
        mandatory: true,
    },

    invoice_date: {
        id: "invoice_date",
        name: "invoice_date",
        label: "Invoice Date:",
        class: inputdate,
        component: input_date,
        mandatory: true,
    },

    seller_address: {
        id: "seller_address",
        name: "seller_address",
        label: "Address:",
        class: genericstring,
        component: generic_string,
        mandatory: true,
    },

    seller_org_name: {
        id: "seller_org_name",
        name: "seller_org_name",
        label: "Issued By:",
        class: genericstring,
        component: generic_string,
        mandatory: true,
    },

    buyer_address: {
        id: "buyer_address",
        name: "buyer_address",
        label: "Address:",
        class: genericstring,
        component: generic_string,
        mandatory: true,
    },

    order_number: {
        id: "order_number",
        name: "order_number",
        label: "Order Number:",
        class: documentnumber,
        component: document_number,
        mandatory: true,
    },

    order_issue_date: {
        id: "order_issue_date",
        name: "order_issue_date",
        label: "Order Date:",
        class: inputdate,
        component: input_date,
        mandatory: true,
    },

    payment_due_date: {
        id: "payment_due_date",
        name: "payment_due_date",
        label: "Payment due Date:",
        class: inputdate,
        component: input_date,
        mandatory: true,
    },

    seller_contact_user_name: {
        id: "seller_contact_user_name",
        name: "seller_contact_user_name",
        label: "Contact Person:",
        class: username,
        component: user_name,
        mandatory: true,
    },

    seller_contact_email_id: {
        id: "seller_contact_email_id",
        name: "seller_contact_email_id",
        label: "Contact Email:",
        class: emailid,
        component: email_id,
        mandatory: true,
    },

    seller_contact_phone_num: {
        id: "seller_contact_phone_num",
        name: "seller_contact_phone_num",
        label: "Contact Phone:",
        class: contactphonenumber,
        component: contact_phone_number,
        mandatory: true,
    },

    buyer_contact_user_name: {
        id: "buyer_contact_user_name",
        name: "buyer_contact_user_name",
        label: "Contact Person:",
        class: username,
        component: user_name,
        mandatory: true,
    },

    buyer_contact_email_id: {
        id: "buyer_contact_email_id",
        name: "buyer_contact_email_id",
        label: "Contact Email:",
        class: emailid,
        component: email_id,
        mandatory: true,
    },

    buyer_contact_phone_num: {
        id: "buyer_contact_phone_num",
        name: "buyer_contact_phone_num",
        label: "Contact Phone:",
        class: contactphonenumber,
        component: contact_phone_number,
        mandatory: true,
    },

    notes: {
        id: "notes",
        name: "notes",
        label: "Notes:",
        class: genericstring,
        component: generic_string,
        mandatory: false,
    },

    discount_percentage: {
        id: "discount_percentage",
        name: "discount_percentage",
        label: "Discount Percentage",
        class: genericdecimal,
        component: generic_decimal,
        mandatory: false,
    },

    discount_amount: {
        id: "discount_amount",
        name: "discount_amount",
        label: "Discount Amount",
        class: amounttextbox,
        component: amount_textbox,
        mandatory: false,
    },

    currency: {
        id: "currency",
        name: "currency",
        label: "Currency:",
        class: Currency,
        component: currency,
        mandatory: true,
    },

    attachments: {
        id: "attachments",
        name: "attachments",
        label: window.getLabelLiteralWithColon("ATTACHMENTS"),
        class: file_set,
        component: FileSet,
        mandatory: false,
        //initialValue: "1234244.00",
    },

};

const formatAddress = (address) => {
    let strAddress = "";
    strAddress += ((address.address_line_1) ? address.address_line_1 : "") + ",";
    strAddress += strAddress + ((address.address_line_2) ? address.address_line_2 : "") + ",";
    strAddress += strAddress + (address.city) ? address.city : "";
    return strAddress;
}

let refcode_tax_type = window.getRefCode("00010");


class ViewReceivedInvoice extends BaseComponent {
    constructor(props) {
        super(props);
        this.total = {};
        this.pageNumber = 1;
        this.state.loading = true;
        this.readOnly = true;
        this.grand_total = 0;
        this.getRow = this.getRow.bind(this);
        this.getRowTax = this.getRowTax.bind(this);
        this.getFrame = this.getFrame.bind(this);

        this.action = props.action;
        this.invoice_id = props.invoice_id;

        this.logged_in_company_id = this.session.getLoggedInCompanyId();
        this.parent_company_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
        this.excpAccepted = false;

        this.orgId = props.orgId;
        this.number = props.number;
        this.date = props.date;
    }

    calculateTotals = () => {
        this.total.total_amount = 0;
        Object.entries(refcode_tax_type).map(([key, value]) => (this.total[`${key}`] = 0));
        for (const i in this.invoiceDetails) {
            if (!this.invoiceDetails[i].deleted) {
                for (const j in this.invoiceDetails[i].tax_details) {
                    for (const key in this.total) {
                        if (this.invoiceDetails[i].tax_details[j].tax_type === key) {
                            let percentage = this.invoiceDetails[i].tax_details[j].tax_percentage;
                            let amount =
                                (this.invoiceDetails[i].rate_per_unit *
                                    this.invoiceDetails[i].quantity * percentage) / 100;
                            if (this.invoiceDetails[i].discount_percentage) {
                                let percentage = this.invoiceDetails[i].discount_percentage;
                                amount = amount - (percentage * amount) / 100;
                            }
                            else if (this.invoiceDetails[i].discount_amount) {
                                amount = amount - (this.invoiceDetails[i].discount_amount);
                            }
                            this.total[`${key}`] = amount + this.total[`${key}`];
                        }
                    }
                }
            }
        }
        for (const a in this.invoiceDetails) {
            if (!this.invoiceDetails[a].deleted) {
                this.invoiceDetails[a].quantity = this.invoiceDetails[a].quantity * 1;
                this.total.total_amount =
                    this.total.total_amount +
                    this.invoiceDetails[a].rate_per_unit * this.invoiceDetails[a].quantity;
                if (this.invoiceDetails[a].discount_percentage) {
                    let percentage = this.invoiceDetails[a].discount_percentage;
                    this.total.total_amount = this.total.total_amount - (percentage * this.total.total_amount) / 100;
                }
                else if (this.invoiceDetails[a].discount_amount) {
                    this.total.total_amount = this.total.total_amount - this.invoiceDetails[a].discount_amount;
                }
            }
        }
        for (let index in this.total) {
            this.grand_total = this.total[index] + this.grand_total;
        }
    }

    fetch = async () => {
        const retData =
            await receivedInvoiceServiceRoutines.fetch(this.session.getServerInstance('SUBSCRIBER'),
                this.session.getAccessToken(), this.invoice_id);
        return retData;
    }

    fetchUsingNumberAndDate = async (number, date, orgId) => {
        return await receivedInvoiceServiceRoutines.fetchUsingNumberAndDate(this.session, number, date, orgId);
    }

    populateFetchedDetails = () => {
        let data = this.state.data;
        this.invoiceHeader = this.invoice.header;
        this.invoiceDetails = this.invoice.details;

        data.seller_address = formatAddress(this.invoiceHeader.seller_address);
        data.buyer_address = formatAddress(this.invoiceHeader.buyer_address);

        data.seller_org_name = this.invoiceHeader.seller_org_name;
        copyDataFieldsToFormFields(mapping, this.invoiceHeader, data);
        this.setState({ data });
        this.calculateTotals();

        return data;
    }

    componentDidMount = async () => {
        try {

            switch (this.action) {
                case 'VIEWWITHNUMBER':
                    this.invoice = await this.fetchUsingNumberAndDate(this.number, this.date, this.orgId);
                    this.populateFetchedDetails();
                    break;
                case 'VIEW':
                case 'ACKNOWLEDGE':
                case 'ACCEPT':
                case 'REJECT':
                case 'INITIATEGRN':
                case 'MARK_PAID':
                    this.invoice = await this.fetch();
                    this.populateFetchedDetails();
                    break;
                default:
                    throw new Error("Action ["+this.action+"] not supported");
            }
            this.setState({ loading: false });
        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    initialState = getInitialState(fields);

    state = {
        data: deepCopy(this.initialState),
    }

    getFrame(offset, recordsPerFrame) {

        return true;
    }

    getRowTax = (data, index, pageNumber, arrayIndex, amount) => {
        let tax_amount =
            (amount * data.tax_percentage) / 100;
        return (
            <tr key={index}>
                <td>{refcode_tax_type[data.tax_type]}</td>
                <td style={{ textAlign: "right" }}>{data.tax_percentage}%</td>
                <td style={{ textAlign: "right" }}>{window.formatCurrency(this.invoiceHeader.currency, tax_amount)}</td>
            </tr>
        );
    }

    getHeaderTax = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th>Tax Type</th>
                <th style={{ textAlign: "right" }}>Tax Percentage</th>
                <th style={{ textAlign: "right" }}>Tax Amount</th>
            </tr>
        );
    }

    getRow = (data, index, pageNumber, arrayIndex) => {
        this.pageNumber = pageNumber;
        let key = 0;
        let taxtable;
        let amount = data.quantity * data.rate_per_unit;
        if (data.discount_amount) {
            amount = amount - data.discount_amount;
        }
        else if (data.discount_percentage) {
            amount = amount - (data.discount_percentage * amount) / 100;
        }
        if (data.tax_details) {
            if (data.tax_details.length > 0) {
                taxtable = (
                    <GenericTable
                        data={data.tax_details}
                        recordsPerPage={20}
                        getRow={this.getRowTax}
                        getHeader={this.getHeaderTax}
                        tableclass={"table"}
                        getFrame={this.getFrame}
                        recordsPerFrame={20}
                        pageNumber={1}
                        isLoading={this.state.loading}
                        showSearch={false}
                        additionalInfo={amount}
                    />
                );
            }
        }
        if (!data.discount_amount) {
            data.discount_amount = 0;
        }
        if (!data.discount_percentage) {
            data.discount_percentage = 0;
        }
        return (
            <React.Fragment key={index}>
                <tr key={++key}>
                    {this.readOnly && data.deleted && data.status && <td> To Delete </td>}
                    {data.deleted && !data.status && <td> Deleted </td>}
                    {this.readOnly && !data.deleted && <td> Active </td>}
                    {!this.readOnly && data.deleted && data.status === true && (
                        <td>
                            <input
                                className="form-check-input"
                                onChange={(e) => this.checkDeletedHandler(e, arrayIndex)}
                                style={{ fontSize: 18 }}
                                type="checkbox"
                                id={"checkDeleted" + key}
                                name={"checkDeleted" + key}
                                value=""
                                checked={data.status ? "checked" : ""}
                            />
                            &nbsp;<label className="form-check-label" htmlFor={"checkDeleted" + key}>Deleted</label>
                        </td>
                    )}
                    {!this.readOnly &&
                        !data.deleted &&
                        (data.status === undefined || data.status === false) && (
                            <td>
                                <input
                                    className="form-check-input"
                                    onChange={(e) => this.checkDeletedHandler(e, arrayIndex)}
                                    style={{ fontSize: 18 }}
                                    type="checkbox"
                                    id={"checkDeleted" + key}
                                    name={"checkDeleted" + key}
                                    value=""
                                    checked={data.status ? "checked" : ""}
                                />
                                &nbsp;<label className="form-check-label" htmlFor={"checkDeleted" + key}>Deleted</label>
                            </td>
                        )}
                    <td>{data.seller_item_id}</td>
                    <td>{data.seller_item_description}</td>
                    <td>{data.hsn_code}</td>
                    <td>{window.getRefCode("00006")[data.unit_of_measure]}</td>
                    <td style={{ textAlign: "right" }}>{data.quantity}</td>
                    <td style={{ textAlign: "right" }}>{window.formatCurrency(this.invoiceHeader.currency, data.rate_per_unit)}</td>
                    <td style={{ textAlign: "right" }}>{data.discount_percentage}%</td>
                    <td style={{ textAlign: "right" }}>{window.formatCurrency(this.invoiceHeader.currency, data.discount_amount)}</td>
                    <td style={{ textAlign: "right" }}>{window.formatCurrency(this.invoiceHeader.currency, amount)}</td>
                    {!this.readOnly && data.deleted && <td> </td>}
                    {!this.readOnly && !data.deleted && (
                        <td>
                            <span onClick={() => this.editHandler(data, arrayIndex)}>
                                <i className="bi bi-pencil-square" style={{ fontSize: 20 }}></i>
                            </span>
                        </td>
                    )}
                </tr>
                { taxtable &&
                <tr key={++key}>
                    <td colSpan="7"></td>
                    <td colSpan="3" className="m-0 p-0">
                        {taxtable}
                    </td>
                    {!this.readOnly && !data.deleted && (
                        <td
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                this.setState({ showModalTax: true });
                                this.setState({ subarrayindex: arrayIndex });
                            }}
                        >
                            add tax
                        </td>
                    )}
                </tr>
                }
            </React.Fragment>
        );
    }

    getHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>Status</th>
                <th key={++key}>Seller Item Id</th>
                <th key={++key}>Seller Item Description</th>
                <th key={++key}>HSN/SAC Code</th>
                <th key={++key} >Unit</th>
                <th key={++key} style={{ textAlign: "right" }}>Qty</th>
                <th key={++key} style={{ textAlign: "right" }}>Rate</th>
                <th key={++key} style={{ textAlign: "right" }}>Discount Percentage</th>
                <th key={++key} style={{ textAlign: "right" }}>Discount Amount</th>
                <th key={++key} style={{ textAlign: "right" }}>Amount</th>
            </tr>
        );
    }

    backHandler = (retFrom, error, excp_obj) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.invoice, error,
                referralId: excp_obj?.referral_id
            });
        else console.log("LOADED AS A PARENT");
    }


    data_render = () => {
        let tableclass = "table table-striped border - dark";
        let key = 0;
        let grandTotal = (
            <>
                <h4 className="d-flex justify-content-end" style={{marginRight:"5px"}}>Total Amount</h4>
                {Object.entries(this.total).map(
                    ([keyval, value]) =>
                        value > 0 && (
                            <div key={++key} className="row" style={{marginRight:"0.1px"}}>
                                <div className="col-sm-6 d-flex justify-content-end"></div>
                                <div className="col-sm-4 d-flex justify-content-end">
                                    <b>{tax_code[`${keyval}`]} :</b>
                                </div>
                                <div className="col-sm-2 d-flex justify-content-end">
                                    <b>{window.formatCurrency(this.invoiceHeader.currency, value)}</b>
                                </div>
                            </div>
                        )
                )}
                <div className="row" style={{marginRight:"0.1px"}}>
                    <div className="col-sm-7"></div>
                    <p className="col-sm-3 d-flex justify-content-end">
                        <b>Total (Incl. tax) :</b>
                    </p>
                    <p className="col-sm-2 d-flex justify-content-end">
                        <b>{window.formatCurrency(this.invoiceHeader.currency, this.grand_total)}</b>
                    </p>
                </div>
            </>
        );
        let buttons = [];
        if (this.props.readOnly) {
            this.readOnly = true;
            this.disabled = true;
        }

        let datablk = (
            <>
                <div className="container-fluid pt-0 m-0 pe-2">
                    <div className="row  p-0 m-0 g-0 " >
                        <div className="col-sm-12 h6 text-left">
                            {this.getBreadcrumb([window.getLabelLiteral(this.action)])}
                        </div>
                        <hr></hr>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.invoice_number}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.invoice_date}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.order_number}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.order_issue_date}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.payment_due_date}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.notes}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.discount_percentage}
                                controlProps={this.controlProps}
                                formattingInputs={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
                                readOnly={this.readOnly}
                                style={{}}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.discount_amount}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                                currency={this.state.data.currency}
                                style={{}}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.currency}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.seller_org_name}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6 mt-3 mb-3 ">
                            <Attachments
                                field={fields.attachments}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                                fileAttributeRequired={true}
                            />
                        </div>
                        <div className="col-sm-12">
                            <GenericError id="showerror" name="showerror" controlProps={this.controlProps} />
                        </div>
                        <div className="col-sm-12 h4 text-left">
                            <hr></hr>Seller Details
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.seller_address}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.seller_contact_user_name}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.seller_contact_email_id}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.seller_contact_phone_num}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-12 h4 text-left">
                            <hr></hr>Buyers Details
                        </div>

                        <div className="col-sm-6">
                            <FormField
                                field={fields.buyer_address}
                                controlProps={this.controlProps}
                                getDataList={(control, searchtext) => { return this.getAddressListBuyer(control, searchtext) }}
                                formatDataList={this.formatAddressList}
                                selectOption={this.selectAddressIdBuyer}
                                readOnly={this.readOnly}
                                clearSelection={this.clearSelectionBuyerAddress}
                                dependencyValue={[this.state.data.buyer_org_name]}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.buyer_contact_user_name}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.buyer_contact_email_id}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormField
                                field={fields.buyer_contact_phone_num}
                                controlProps={this.controlProps}
                                readOnly={this.readOnly}
                            />
                        </div>
                        <div className="col-sm-6"></div>
                    </div>
                </div>
                <div className="container-fluid pt-0 me-2 pe-2">
                    <div className="col-sm-12 h4 text-left">
                        <hr></hr>Line Items
                    </div>
                    <div className="col-sm-12">
                        <GenericTable
                            data={this.invoiceDetails}
                            recordsPerPage={20}
                            getRow={this.getRow}
                            getHeader={this.getHeader}
                            tableclass={tableclass}
                            getFrame={this.getFrame}
                            recordsPerFrame={20}
                            pageNumber={1}
                            isLoading={this.state.loading}
                            showSearch={false}
                        />
                    </div>
                    {this.readOnly && <div>{grandTotal}</div>}
                    {this.state.showErrorFlag && (
                        <p className="error text-danger fw-bold p-0 m-0" style={{ fontSize: "0.90rem" }}>
                            {this.error1}
                        </p>
                    )}

                    <div className="row    p-0 m-0 g-0 ">
                        <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                            {(this.action !== 'VIEW') &&
                                <NarrowButton value="Submit" onClick={this.submitForm} key={++key} />
                            }
                            <NarrowButton value="Back" onClick={() => this.backHandler(this.action)} key={++key} />
                        </div>
                    </div>
                </div>
            </>
        );

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />;
        return form;
    }

    loading_render = () => {
        let datablk = <div className="container border border-0 pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                <div className="col-sm-12 h4 text-center" >{this.page_title}</div>
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

    onReturn = async (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
            case 'BACK':
                break;
            case 'PROCEED':
                this.excpAccepted = true;
                return await this.submitForm();
            default:
                break;
        }
    }

    handleExceptions = (referral) => {
		this.callForm(SubmitReferral, { ...this.props,  referral_action: "PROCEED", referral }, this.onReturn);
    }

    submitForm = async () => {
        this.resetError();

        try {
            switch (this.action) {
                case "ACKNOWLEDGE":
                    {
                        let referral;
                        if (!(this.excpAccepted)) {
                            referral = await referralServiceroutines.check_rules(this.session, {
                                str_action : "acknowledge"
                                ,referral_org_id : this.logged_in_company_id
                                ,document_org_id : this.invoice.header.seller_org_id
                                ,document_type: DOCUMENT_TYPE
                                ,document_date: this.invoice.header.invoice_date
                                ,document_number: this.invoice.header.invoice_number
                            });
                        }
                        if (this.excpAccepted === true || referral.excp.count === 0) {
                            await receivedInvoiceServiceRoutines.acknowledge(
                                this.session.getServerInstance('SUBSCRIBER'),
                                this.session.getAccessToken(),
                                this.invoice.header
                            );
                            this.invoice = await this.fetch();
                        }
                        else {
                            return this.handleExceptions(referral);
                        }
                    }
                    break;
                case "ACCEPT":
                    {
                        let referral;
                        if (!(this.excpAccepted)) {
                            referral = await referralServiceroutines.check_rules(this.session, {
                                str_action : "acknowledge"
                                ,referral_org_id : this.logged_in_company_id
                                ,document_org_id : this.invoice.header.seller_org_id
                                ,document_type: DOCUMENT_TYPE
                                ,document_date: this.invoice.header.invoice_date
                                ,document_number: this.invoice.header.invoice_number
                            });
                        }
                        if (this.excpAccepted === true || referral.excp.count === 0) {
                            await receivedInvoiceServiceRoutines.accept(
                                this.session.getServerInstance('SUBSCRIBER'),
                                this.session.getAccessToken(),
                                this.invoice.header
                            );
                            this.invoice = await this.fetch();
                        }
                        else {
                            return this.handleExceptions(referral);
                        }
                    }
                    break;
                case "REJECT":
                    await receivedInvoiceServiceRoutines.reject(
                        this.session.getServerInstance('SUBSCRIBER'),
                        this.session.getAccessToken(),
                        this.invoice.header
                    );
                    this.invoice = await this.fetch();
                    break;
                case "MARK_PAID":
                    await receivedInvoiceServiceRoutines.payment_done(
                        this.session.getServerInstance('SUBSCRIBER'),
                        this.session.getAccessToken(),
                        this.invoice.header
                    );
                    this.invoice = await this.fetch();
                    break;
                case "INITIATEGRN":
                    this.invoice = await receivedInvoiceServiceRoutines.initiate_grn(
                        this.session.getServerInstance('SUBSCRIBER'),
                        this.session.getAccessToken(),
                        this.invoice.header
                    );
                    //this.invoice = await this.fetch();
                    break;
                default:
                    break;
            }

            this.backHandler(this.action);
        }
        catch (e) {
            console.log(e);
            this.setError(raiseError(e.error_message));
            if (this.handleFormSubmissionErr(e, this.excpAccepted)) {
                this.backHandler(this.action, undefined, e.excp_obj);
            }
        }
    }

}

export default ViewReceivedInvoice;
