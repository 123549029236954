import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";

let Receivedinvoicesserviceroutines = {};

Receivedinvoicesserviceroutines.list = async (serverInstance, accessToken, obj) => {
    let response;
    try {
        response =
            await serverInstance.put(
                `biop/subscriber/received_invoice/list`,
                obj,
                {
                    headers: {
                        "X-Auth": accessToken
                    }
                }
            );
        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.fetch = async (serverInstance, accessToken, selectedInvoiceId) => {
    let response;
    try {
        response = await serverInstance.get(`biop/subscriber/received_invoice/fetch?invoice_id=${selectedInvoiceId}`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.acknowledge = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/acknowledge`,
        invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.accept = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/accept`,
            invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.payment_done = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/payment_done`,
            invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.initiate_grn = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/initiate_grn`,
            invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.reject = async (serverInstance, accessToken, invoice_header) => {
    let response;
    try {
        response = await serverInstance.put(`biop/subscriber/received_invoice/reject`,
            invoice_header,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
        );

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.fetch_first_level_network = async (serverInstance, accessToken, org_id, offset,
    recordsPerFrame, searchtext, is_subscriber) => {
    let response;
    try {
        response = await serverInstance.get(`/biop/subscriber/control/fetch_first_level_network`,
            {
                headers: {
                    "X-Auth": accessToken
                },
                params: {
                    org_id: org_id,
                    offset: offset,
                    num_recs: recordsPerFrame,
                    org_name: searchtext,
                    is_subscriber: is_subscriber,
                }
            },
        );
        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.fetch_purchase_order = async (session, buyerOrgId, orderIssueDate, orderNumber) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
    let params = {};
    if (orderNumber) {
        params.order_number = orderNumber;
    }
    if (orderIssueDate) {
        params.order_issue_date = orderIssueDate;
    }
    if (buyerOrgId) {
        params.buyer_org_id = buyerOrgId;
    }

    try {
        response = await serverInstance.get(
            `/biop/subscriber/order/fetch_using_order_num_and_date`,
            {
                params,
                headers: {
                    "X-Auth": accessToken
                }
            }
        );
        return response.data;
    }
    catch (e) {
        console.log(e);
        return handleAxiosHTTPException(e);
    }
}

Receivedinvoicesserviceroutines.add = async (session, retObj) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post("biop/subscriber/purchase_invoice_manager/add", retObj, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

Receivedinvoicesserviceroutines.fetchPurchaseInvoice = async (session, invoiceId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		invoice_id : invoiceId
	};
	try {
		response = await serverInstance.get(
			`biop/subscriber/purchase_invoice_manager/fetch`,
			{
				params,
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

Receivedinvoicesserviceroutines.fetchUsingNumberAndDate = async (session, invoiceNumber, invoiceDate, orgId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    let response;
	let params = {
		invoice_number : invoiceNumber,
		invoice_date : invoiceDate,
		seller_org_id : orgId
	};
	try {
		response = await serverInstance.get(
			`biop/subscriber/purchase_invoice_manager/fetch_using_number_and_date`,
			{
				params,
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

Receivedinvoicesserviceroutines.modify = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put("biop/subscriber/purchase_invoice_manager/modify", invoice, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

Receivedinvoicesserviceroutines.delete = async (session, invoiceHeader) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(`biop/subscriber/purchase_invoice_manager/delete`, invoiceHeader, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

Receivedinvoicesserviceroutines.approve = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put("biop/subscriber/purchase_invoice_manager/approve", invoice, {
			headers: {
				"X-Auth": accessToken,
			},
		});
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

Receivedinvoicesserviceroutines.fetchAmended = async (session, selectedInvoiceId) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.get(`biop/subscriber/purchase_invoice_manager/fetch_for_amendment?invoice_id=${selectedInvoiceId}`,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

Receivedinvoicesserviceroutines.amend = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/purchase_invoice_manager/amend',
			invoice,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

Receivedinvoicesserviceroutines.cancelAmendment = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/purchase_invoice_manager/cancel_amendment',
			invoice,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

Receivedinvoicesserviceroutines.approveAmendment = async (session, invoice) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post('/biop/subscriber/purchase_invoice_manager/approve_amendment',
			invoice,
			{
				headers: {
					"X-Auth": accessToken
				}
			}
		);
		return response.data;
	}
	catch (e) {
		return handleAxiosHTTPException(e);
	}
}

export default Receivedinvoicesserviceroutines;
