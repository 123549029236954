import {
    GenericComponent,
    GenericForm,
    NarrowButton,
    getInitialState,
    deepCopy,
    Modal,
    Backdrop,
    raiseError,
    fieldValidations,
    FormField,
    copyDataFieldsToFormFields,
    copyFormFieldsToDataFields,
    Country,
    country,
    State,
    state,
    city,
    City,
    zip_code,
    zipcode,
    address_line,
    addressline,
    email_id,
    emailid,
    contact_phone_number,
    contactphonenumber,
    user_name,
    username,
    aadhar_number,
    aadharnumber,
    pan_number,
    pannumber,
    language_code,
    languagecode,
    region_code,
    regioncode,
    voter_id,
    voterid,
    search_select,
    searchselect,
    generic_string,
    genericstring,
    genericgenrefcode,
    generic_genrefcode,
} from 'WebUI_Framework';

import serviceRoutines from './UsersServiceRoutines';

const menu_trees = {
    S: "Service Provider User"
    ,SB: "Subsciber User"
    ,NSB: "Non Subsciber User"
    ,SE: "Sales Agent"
};

const fields = {
    user_name: {
        id: "user_name", name: "user_name", label: "User Name:",
        class: username, component: user_name, mandatory: true
    },
    email_id: {
        id: "email_id", name: "email_id", label: "Email ID:",
        class: emailid, component: email_id, mandatory: true
    },
    contact_phone_number: {
        id: "contact_phone_number", name: "contact_phone_number", label: "Contact Number:",
        class: contactphonenumber, component: contact_phone_number, mandatory: true
    },
    address_line1: {
        id: "address_line1", name: "address_line1", label: "Address Line 1:",
        class: addressline, component: address_line, mandatory: true
    },
    address_line2: {
        id: "address_line2", name: "address_line2", label: "Address Line 2:",
        class: addressline, component: address_line, mandatory: false
    },
    city: {
        id: "city", name: "city", label: "City:",
        class: city, component: City, mandatory: true
    },
    state: {
        id: "state", name: "state", label: "State:",
        class: state, component: State, mandatory: false
    },
    zip_code: {
        id: "zip_code", name: "zip_code", label: "PinCode:",
        class: zipcode, component: zip_code, mandatory: false
    },
    cntry: {
        id: "cntry", name: "cntry", label: "Country :",
        class: country, component: Country, mandatory: false
    },
    aadharnumber: {
        id: "aadharnumber", name: "aadharnumber", label: "Aadhar Number :",
        class: aadharnumber, component: aadhar_number, mandatory: false
    },
    voterid: {
        id: "voterid", name: "voterid", label: "Voter Id :",
        class: voterid, component: voter_id, mandatory: false
    },
    menu_tree: {
        id: "menu_tree", name: "menu_tree", label: "Role:",
        class: genericgenrefcode, component: generic_genrefcode, mandatory: false
    },
    pannumber: {
        id: "pannumber", name: "pannumber", label: "PAN Number :",
        class: pannumber, component: pan_number, mandatory: false
    },

    language_code: {
        id: "language_code", name: "language_code", label: "Language code :",
        class: languagecode, component: language_code, mandatory: true
    },

    region_code: {
        id: "region_code", name: "region_code", label: "Region code :",
        class: regioncode, component: region_code, mandatory: true
    },

    default_company_name: {
        id: "default_company_name", name: "default_company_name", label: "Default Company name:",
        class: searchselect, component: search_select, mandatory: true,
    },

    default_company_id: {
        id: "default_company_id", name: "default_company_id", label: "Default Company:",
        class: genericstring, component: generic_string, mandatory: true,
    },

    default_parent_company_id: {
        id: "default_parent_company_id", name: "default_parent_company_id", label: "Default Parent Company:",
        class: genericstring, component: generic_string, mandatory: false,
    },

    default_parent_company_name: {
        id: "default_parent_company_name", name: "default_parent_company_name", label: "Default Parent Company name:",
        class: genericstring, component: generic_string, mandatory: false,
    },

}

let mapping = [
    [ "user_name", "user_name" ],
    [ "contact_phone_number", "cell_number" ],
    [ "email_id", "email_id" ],
    [ "address_line1", "address_line_1" ],
    [ "address_line2", "address_line_2" ],
    [ "zip_code", "zip_code" ],
    [ "city", "city" ],
    [ "state", "state" ],
    [ "cntry", "cntry" ],
    [ "version", "version" ],
    [ "aadharnumber", "aadhar_number" ],
    [ "voterid", "voter_id" ],
    [ "menu_tree", "menu_tree" ],
    [ "pannumber", "pan" ],
    [ "deleted", "deleted" ],
    [ "access_type", "access_type" ],
    [ "language_code", "language_code" ],
    [ "region_code", "region_code" ],
    [ "default_company_id", "default_company_id" ],
    [ "default_parent_company_id", "default_parent_company_id" ],
    [ "default_company_name", "default_company_name" ],
    [ "default_parent_company_name", "default_parent_company_name" ],
];

class EditUser extends GenericComponent {
    constructor (props) {
        super(props);
        this.serverInstance = this.session.getServerInstance('ROC');
        this.accessToken = this.session.getAccessToken();
        this.readOnly = this.props.readOnly;
    }

    initialState = getInitialState(fields);

    state = {
        data: deepCopy(this.initialState),
    }

    componentDidMount () {
        this.getSelectedUserData();
    }

    async getSelectedUserData () {
        try {
            const retData = await serviceRoutines.fetch(this.serverInstance, this.accessToken, this.props.email_id);
            if (retData !== undefined && retData !== null) {
                let tempObj = this.state.data;
                if (retData.default_company_id !== retData.default_parent_company_id) {
                    this.setState({ default_org_is_subscriber: false });
                }
                this.selectedUserData = retData;
                copyDataFieldsToFormFields(mapping, this.selectedUserData, tempObj);
                this.setState({ data: tempObj });
            }
        }
        catch (e) {
            this.setError(raiseError(e.error_message));
        }
    }


    backHandler = (retFrom) => {
        if (retFrom !== "edit") {
            retFrom = "back";
        }

        if (this.isChildMode())
            this.props.returnToParent({ retFrom: retFrom, arrayIndex: this.props.arrayIndex, selectData: this.selectedUserData });
        else console.log("LOADED AS A PARENT");
    }

    getCompanyList = async (control, searchText) => {
        try {
            const retData = await serviceRoutines.fetch_first_level_network(this.serverInstance, this.accessToken, this.session.getLoggedInParentCompanyId(), null, null, searchText, false)
            return retData;
        }
        catch (e) {
            control.error = true;
            if (e.error_message !== null && e.error_message !== undefined) {
                control.errorMsg = e.error_message;
            }
            else {
                control.errorMsg = e.message;
            }
        }
    }

    formatDataList = (array) => {
        let formatted_array = [];
        array.map((value, index) => {
            let s = value.org_name;
            formatted_array.push(s);
            return s;
        });
        return formatted_array;
    }

    selectOption = (index, list) => {
        let data = deepCopy(this.state.data);
        data.default_company_name = list[index].org_name;
        data.default_company_id = list[index].org_id;
        this.setState({data});
    }

    clearSelectionDefaultCompany = () => {
        let data = deepCopy(this.state.data);
        data.default_company_name = '';
        data.default_company_id = '';
        this.setState({data});
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    render () {

        let buttons = [];
        let key = 0;
        if (this.props.readOnly) {
            this.readOnly = true;
            this.disabled = true;
        }

        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
            <div className="row  p-0 m-0 g-0 " >
                
                {!this.readOnly ?
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb([window.getLabelLiteral("EDIT")])}
                    </div>
                    :
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb([window.getLabelLiteral("VIEW")])}
                    </div>
                }
                <div className="col-sm-6" >
                    <FormField field={fields.user_name} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.email_id} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.contact_phone_number} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.aadharnumber} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.pannumber} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6">
                    <FormField field={fields.voterid} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6">
                    <FormField
                        field={fields.menu_tree}
                        controlProps={this.controlProps}
                        readOnly={this.readOnly}
                        refData={menu_trees}
                        />
                </div>
                <div className="col-sm-6">
                    &nbsp;
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.address_line1} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.address_line2} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.city} controlProps={this.controlProps} disabled={this.disabled} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={fields.zip_code} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6">
                    <FormField field={fields.cntry} controlProps={this.controlProps} disabled={this.disabled} />
                </div>
                <div className="col-sm-6">
                    <FormField
                        field={fields.state}
                        controlProps={this.controlProps}
                        country={this.state.data.cntry}
                        disabled={this.disabled}
                    />
                </div>
                <div className="col-sm-6">
                    <FormField field={fields.default_parent_company_name}
                        controlProps={this.controlProps}
                        readOnly={true}
                    />
                </div>
                <div className="col-sm-6">
                    <FormField field={ fields.default_company_name }
                        controlProps={ this.controlProps }
                        getDataList={(control, searchText) => {return this.getCompanyList(control, searchText)}}
                        formatDataList={ this.formatDataList }
                        selectOption={ this.selectOption }
                        clearSelection={this.clearSelectionDefaultCompany}
                        readOnly={this.readOnly}
                        />
                </div>
                <div className="col-sm-6">
                    <FormField field={fields.language_code} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6">
                    <FormField field={fields.region_code} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
            </div>
            <div className="row  p-0 m-0 g-0 " >

                <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                    {(!this.readOnly) && 
                    <NarrowButton value="Update" onClick={this.submitForm} key={(++key).toString()} />
                    }
                    <NarrowButton value="Back" onClick={this.backHandler} key={(++key).toString()} />
                </div>


            </div>
            {(this.state.showModal) && <Modal
                title="Update Confirmation"
                msg="Are you sure, you want to update this User?"
                btn1="Confirm"
                btn2="Cancel"
                onClick={this.closeModal}
                onConfirm={this.onConfirm}

            />

            }
            {(this.state.showModal) && <Backdrop />}

        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    validateForm = () => {
        let r = fieldValidations(fields, this.state.data);
        if (!r.status) {
            this.setError(r);
            return false;
        }
        return true;
    }

    submitForm = () => {
        this.resetError();
        if (!this.validateForm()) {
            return;
        }
        this.setState({ showModal: true });
    }

    onConfirm = async (control) => {
        this.resetError();
        let retObj = {};
        let data = deepCopy(this.state.data);
        data.deleted = false;
        data.access_type = "B";
        if (this.state.default_org_is_subscriber) {
            data.default_parent_company_id = data.default_company_id;
        }
        this.setState({ data });
        copyFormFieldsToDataFields(mapping, data, retObj, fields);
        try {
            const retData = await serviceRoutines.modify(this.serverInstance, this.accessToken, retObj);
            if (retData !== undefined && retData !== null) {
                this.backHandler("edit");
            }
        }
        catch (e) {
            control.error = true;
            control.errorMsg = e.error_message;
        }
    }
}

export default EditUser;
