import {
	GenericComponent,
	GenericForm,
	getInitialState,
	deepCopy,
	GenericTable,
	raiseError,
	valuePresent,
} from 'WebUI_Framework';

import AddEditDeliveryNote from './AddEditDeliveryNote';
import AddEditIssuedInvoice from '../invoice/AddEditIssuedInvoice';
import deliveryNoteService from "./DeliveryNoteServiceRoutines";
import { invoiceFromDeliveryNote } from '../common/utils';
import salesOrderServiceRoutines from '../sales_orders/SalesOrdersServiceRoutines';
import ViewInvoice from '../invoice/AddEditIssuedInvoice';

class ManageDeliveryNotes extends GenericComponent {
	constructor(p) {
		super(p);
		this.recordsPerFrame = window.getParam("CURSOR_FRAME_SIZE");
		this.pageNumber = 1;
		//this.recordsPerPage = 7;
		this.recordsPerPage = window.getParam("TABLE_PAGE_SIZE");
		this.subscriberService = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();

		this.delivery_notes = []; /* Data of main page */
		this.state.isLoading = true; /* Loading animation for table */
		this.state.editCount = 0;
	}

	initialState = getInitialState();

	state = {
		data: deepCopy(this.initialState),
	}

	async getFrame(offset, recordsPerFrame) {
		this.resetError();
		this.setState({ isLoading: true });
		try {
			const retData = await deliveryNoteService.list(this.subscriberService, this.accessToken,
											offset, recordsPerFrame, this.session.getLoggedInCompanyId());
			if (retData.length > 0) {
				this.delivery_notes = this.delivery_notes.concat(retData);
			}
		}
		catch (e) {
			console.log(e);
			this.setError(raiseError(e.error_message));
		}
		this.setState({ isLoading: false });
	}

	onReturnFromInvoice = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "FORMINVOICE":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					const invoiceHeader = ret.selectData.header;
					this.delivery_notes[updatedArrayIndex].invoice_id = invoiceHeader.invoice_id;
					this.delivery_notes[updatedArrayIndex].invoice_number = invoiceHeader.invoice_number;
					this.delivery_notes[updatedArrayIndex].invoice_date = invoiceHeader.invoice_date;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case "EDITINVOICE":
			case "VIEWINVOICE":
			case "BACK":
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditIssuedInvoice"));
		}
		return;
	}

	onReturn = (ret) => {
		this.resetError();
		if (ret.error) {
			this.setError(ret.error);
			return;
		}
		/*eslint no-fallthrough: 0*/
		switch (ret.retFrom) {
			case "ADD":
				this.delivery_notes.splice(((this.pageNumber - 1) * this.recordsPerPage), 0, ret.selectData.header);
			case "EDIT":
			case "CANCEL":
			case "AMEND":
			case "ACCEPT": 
			case "APPROVE":
			case "VIEW":
				let updatedArrayIndex = ((this.pageNumber - 1) * this.recordsPerPage) + ret.arrayIndex;
				this.setState((prevState, props) => {
					this.delivery_notes[updatedArrayIndex] = ret.selectData.header;

					let newState = deepCopy(prevState);
					newState.editCount++;
					return newState;
				});
				break;
			case "BACK":
				break;
			case "DELETE":
				this.delivery_notes.splice(ret.arrayIndex, 1);
				this.setState({ editCount: this.state.editCount + 1 });
				break;
			default:
				this.setError(raiseError("Invalid return from AddEditDeliveryNote"));
		}
		return;
	}

	handleFormInvoice = async (deliveryNoteId, invoiceId, arrayIndex, pageNumber) => {
		const action = (!valuePresent(invoiceId)) ? "FORMINVOICE" : "EDITINVOICE";
		let invoice;
		if (action === "FORMINVOICE") {
			let salesOrder;
			let deliveryNote = await deliveryNoteService.fetch(this.subscriberService, this.accessToken, deliveryNoteId);
			if (valuePresent(deliveryNote.header.order_number) && valuePresent(deliveryNote.header.order_issue_date)) {
				salesOrder = await salesOrderServiceRoutines.fetchUsingNumberAndDate(this.session,
                    deliveryNote.header.buyer_org_id, deliveryNote.header.order_issue_date, deliveryNote.header.order_number);
			}
			invoice = await invoiceFromDeliveryNote(this.session, deliveryNote, salesOrder);
			console.log(invoice);
		}
		this.pageNumber = pageNumber;
		this.callForm(AddEditIssuedInvoice,
			{ action, deliveryNoteId, invoiceId, invoice, arrayIndex, pageNumber, ...this.props }, this.onReturnFromInvoice);
	}

	handler = async (action, deliveryNoteId, arrayIndex, pageNumber) => {
		this.pageNumber = pageNumber;
		this.callForm(AddEditDeliveryNote, { action, deliveryNoteId, arrayIndex, pageNumber, ...this.props }, this.onReturn);
	}

	invoice_handler = async (invoiceId, pageNumber, arrayIndex) => {
		this.pageNumber = pageNumber;
		const action ="VIEWINVOICE";
		this.callForm(ViewInvoice,
			{ action, invoiceId, arrayIndex, pageNumber, ...this.props }, this.onReturnFromInvoice);
	}

	getRow = (row, index, pageNumber, arrayIndex) => {
		let stat;
		let i = index - 1;
		switch (row.delivery_note_status) {
			case '1':
				stat = window.getLabelLiteral("STATUS_DRAFT");
				break;
			case '2':
				stat = window.getLabelLiteral("STATUS_SENT");
				break;
			case '3':
				stat = window.getLabelLiteral("STATUS_RECEIVED");
				break;
			case '4':
				stat = window.getLabelLiteral("STATUS_ACCEPTED");
				break;
			case '5':
				stat = window.getLabelLiteral("STATUS_REJECTED");
				break;
			default:
				this.setError(raiseError("Invalid status code [" + row.delivery_note_status + "]"));
				stat = "ERROR";
				break;
		}
		let tableRow =
			<tr key={i}>
				<td>{row.delivery_note_date}</td>
				<td> <button type="button"
					style={{
						color: "blue", textDecoration: "underline", cursor: "pointer",
						border: "none", background: "none", padding: 0
					}}
					onClick={() => this.handler("VIEW", row.delivery_note_id, i, pageNumber)}>{row.delivery_note_number}
				</button>
				</td>
				<td>{row.buyer_org_name}</td>
				<td>{stat}</td>
				<td>{row.invoice_date}</td>
				<td>
					<button type="button"
						style={{
							color: "blue", textDecoration: "underline", cursor: "pointer",
							border: "none", background: "none", padding: 0
						}}
						onClick={() => this.invoice_handler(row.invoice_id, pageNumber, arrayIndex)}
					>
						{row.invoice_number}
					</button>
				</td>
				<td>
					{(row.deleted !== true && row.entity_state === "0") &&
						<>
							<span onClick={() => this.handler("APPROVE", row.delivery_note_id, i, pageNumber)}
								title={window.getLabelLiteral("APPROVE")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-check2-square" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={() => this.handler("EDIT", row.delivery_note_id, i, pageNumber)}
								title={window.getLabelLiteral("EDIT")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-pencil-square" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={() => this.handler("DELETE", row.delivery_note_id, i, pageNumber)}
								title={window.getLabelLiteral("DELETE")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-trash3" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{(row.deleted !== true && row.delivery_note_status === '5' &&
					  this.session.getLoggedInCompanyId() === row.owner_org_id) &&
						<>
							<span onClick={() => this.handler("AMEND", row.delivery_note_id, i, pageNumber)}
								title={window.getLabelLiteral("AMEND")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-pen" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
						</>
					}
					{(row.deleted !== true && row.entity_state === "2") &&
						<>
							<span onClick={() => this.handler("CANCEL", row.delivery_note_id, i, pageNumber)}
								title={window.getLabelLiteral("CANCEL")}
								style={{ cursor: 'pointer' }}>
								<i className="bi bi-x-square" style={{ fontSize: 20 }} ></i>
							</span>
							&nbsp;&nbsp;
							<span onClick={ () => this.handler("ACCEPT", row.delivery_note_id, i, pageNumber) }
									title={window.getLabelLiteral("ACCEPT")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-hand-thumbs-up" style={{ fontSize: 20 }} ></i>
							</span>
						</>
					}
					{(row.deleted !== true && row.delivery_note_status === '4' &&
                        row.delivery_document_type !== '3' &&
						(!valuePresent(row.invoice_state) || row.invoice_state === '0')) &&
						<>
							<span onClick={ () => this.handleFormInvoice(row.delivery_note_id, row.invoice_id, i, pageNumber) }
									title={window.getLabelLiteral("INVOICE_TITLE")}
									style={{ cursor: 'pointer' }}>
								<i className="bi bi-envelope-arrow-up" style={{ fontSize: 20 }} ></i>
							</span>
						</>
					}
				</td>
			</tr>

		return tableRow;

	}

	getHeader() {
		let key = 0;
		return <>
			<tr key={++key}>
				<th key={++key}>{window.getLabelLiteral("DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("DELIVERY_NOTE_NUMBER")}</th>
				<th key={++key}>{window.getLabelLiteral("BUYER_COMPANY_NAME")}</th>
				<th key={++key}>{window.getLabelLiteral("STATUS_TITLE")}</th>
				<th key={++key}>{window.getLabelLiteral("INVOICE_DATE")}</th>
				<th key={++key}>{window.getLabelLiteral("INVOICE_NUMBER")}</th>
			</tr>
		</>
	}

	componentDidMount() {
		this.getFrame(1, this.recordsPerFrame);
	}

	buttons = [
		{
			literal: "Add",
			func: () => {
				const ucp = this.session.getUserCompanyProps(this.session.getLoggedInCompanyId(),
															this.session.getLoggedInParentCompanyId());
				if (!(ucp.org_is_subscriber)) {
					if (ucp.partner_relationship_type === 'D') {
						this.setError(raiseError(window.parseLiteral("CANNOT_CREATE_DELIVERY_NOTE"
															, this.session.getLoggedInCompanyName())));
						return false;
					}
				}
				this.callForm(AddEditDeliveryNote, { action: "ADD", ...this.props }, this.onReturn);
			}
		}
	]

	render() {
		let datablk = <div className="container-fluid pt-0 m-0 pe-2">
			<div className="col-sm-12">
				<GenericTable
					pageHeading={this.getBreadcrumb()}
					pageHeadingClass="h6 text-left"
					data={this.delivery_notes}
					recordsPerPage={this.recordsPerPage}
					getRow={this.getRow}
					getHeader={this.getHeader}
					tableclass="table table-striped border-dark"
					getFrame={this.getFrame}
					recordsPerFrame={this.recordsPerFrame}
					button={this.buttons}
					pageNumber={this.pageNumber}
					isLoading={this.state.isLoading}
				/>
			</div>

		</div>

		let frm = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
		return frm;
	}
}

export default ManageDeliveryNotes;
