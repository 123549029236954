import {
    GenericComponent,
    NarrowButton,
    GenericForm,
    FormField,
    MultiRec,
    MultiRecFormField,
    copyMultiRecFormFieldsToDataArray,
    copyDataArrayToMultiRecFormFields,
    deleteMultiRecRow,
    addMultiRecRow,
    multiRecFieldValidations,
    fieldValidations,
    getInitialState,
    copyFormFieldsToDataFields,
    copyDataFieldsToFormFields,
    deepCopy,
    raiseError,
    filteredselect,
    filtered_select,
    searchselect,
    search_select,
    document_number,
    documentnumber,
    place_holder,
    placeholder,
    input_date,
    inputdate,
    genericstring,
    generic_string,
    genericcheckbox,
    generic_checkbox,
    itemid,
    item_id,
    genericdecimal,
    generic_decimal,
    unit_measure,
    unitmeasure,
    Modal_generic_content,
    Backdrop,
    valuePresent,
    file_set,
    FileSet
} from 'WebUI_Framework';

import Attachments from '../common/Attachments'
import deliveryNoteServiceRoutines from './DeliveryNoteServiceRoutines'
import PartnerList from '../common/PartnerList'
import SalesOrderList from '../common/SalesOrderList';
import { isSubscriber } from '../common/utils';

const header_fields = {
    order_number: {
        id: "order_number", name: "order_number", label: window.getLabelLiteralWithColon("SO_NUMBER"),
        class: searchselect, component: search_select, mandatory: false
    },

    order_issue_date: {
        id: "order_issue_date", name: "order_issue_date",
        label: window.getLabelLiteralWithColon("SO_ISSUE_DATE"), class: inputdate, component: input_date, mandatory: false
    },

    seller_org_id: {
        id: "seller_org_id", name: "seller_org_id", label: window.getLabelLiteralWithColon("SELLER_ORG_ID"),
        class: genericstring, component: generic_string, mandatory: false
    },

    seller_org_name: {
        id: "seller_org_name", name: "seller_org_name", label: window.getLabelLiteralWithColon("SELLER_ORG"),
        class: searchselect, component: search_select, mandatory: false
    },
    place_holder: {
        id: "place_holder", name: "place_holder", label: "",
        class: placeholder, component: place_holder, mandatory: false
    },

    buyer_org_id: {
        id: "buyer_org_id", name: "buyer_org_id", label: window.getLabelLiteralWithColon("BUYER_ORG"),
        class: filteredselect, component: filtered_select, mandatory: false
    },

    buyer_org_name: {
        id: "buyer_org_name", name: "buyer_org_name", label: window.getLabelLiteralWithColon("BUYER_ORG"),
        class: searchselect, component: search_select, mandatory: false
    },

    delivery_note_number: {
        id: "delivery_note_number", name: "delivery_note_number", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_NUMBER"),
        class: documentnumber, component: document_number, mandatory: true
    },

    delivery_note_date: {
        id: "delivery_note_date", name: "delivery_note_date", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_DATE"),
        class: inputdate, component: input_date, mandatory: true
        //, initialValue : "2023-10-06"
    },

    description: {
        id: "description", name: "description", label: window.getLabelLiteralWithColon("DESCRIPTION"),
        class: genericstring, component: generic_string, mandatory: false
        //, initialValue : "2023-10-06"
    },

    expiry_date: {
        id: "expiry_date", name: "expiry_date", label: window.getLabelLiteralWithColon("EXPIRY_DATE"),
        class: inputdate, component: input_date, mandatory: false
    },

    manufacture_date: {
        id: "manufacture_date", name: "manufacture_date", label: window.getLabelLiteralWithColon("MANUFACTURE_DATE"),
        class: inputdate, component: input_date, mandatory: false
    },

    lot_identification_number: {
        id: "lot_identification_number", name: "lot_identification_number",
        label: window.getLabelLiteralWithColon("LOT_IDENTIFICATION_NUMBER"), class: genericstring,
        component: generic_string, mandatory: false
    },
    attachments: {
        id: "attachments",
        name: "attachments",
        label: window.getLabelLiteralWithColon("ATTACHMENTS"),
        class: file_set,
        component: FileSet,
        mandatory: false,
        //initialValue: "1234244.00",
    },
}

let header_mapping = [
    ["order_number", "order_number"],
    ["order_issue_date", "order_issue_date"],
    ["seller_org_id", "seller_org_id"],
    ["seller_org_name", "seller_org_name"],
    ["buyer_org_id", "buyer_org_id"],
    ["buyer_org_name", "buyer_org_name"],
    ["delivery_note_number", "delivery_note_number"],
    ["delivery_note_date", "delivery_note_date"],
    ["description", "description"],
    ["attachments", "attachments"]
];

const details_fields = {
    seller_item_id: {
        id: "seller_item_id", name: "seller_item_id", label: window.getLabelLiteralWithColon("SELLER_ITEM_ID"),
        class: itemid, component: item_id, mandatory: true
    },

    lookup_seller_item_id: {
        id: "lookup_seller_item_id", name: "lookup_seller_item_id", label: window.getLabelLiteralWithColon("SELLER_ITEM_ID"),
        class: searchselect, component: search_select, mandatory: false
    },

    ordered_quantity: {
        id: "ordered_quantity", name: "ordered_quantity", label: window.getLabelLiteralWithColon("ORDERED_QUANTITY"),
        class: genericdecimal, component: generic_decimal, mandatory: false
    },

    delivered_quantity: {
        id: "delivered_quantity", name: "delivered_quantity", label: window.getLabelLiteralWithColon("PRE_DELIVERED_QUANTITY"),
        class: genericdecimal, component: generic_decimal, mandatory: false
    },

    accepted_quantity: {
        id: "accepted_quantity", name: "accepted_quantity", label: window.getLabelLiteralWithColon("ACCEPTED_QUANTITY"),
        class: genericdecimal, component: generic_decimal, mandatory: false
    },

    dispatched_quantity: {
        id: "dispatched_quantity", name: "dispatched_quantity", label: window.getLabelLiteralWithColon("DISPATCHED_QUANTITY"),
        class: genericdecimal, component: generic_decimal, mandatory: true
    },

    unit_of_measure: {
        id: "unit_of_measure", name: "unit_of_measure", label: window.getLabelLiteralWithColon("UNIT_OF_MEASURE"),
        class: unitmeasure, component: unit_measure, mandatory: true
    },

    delivery_note_remarks: {
        id: "delivery_note_remarks", name: "delivery_note_remarks", label: window.getLabelLiteralWithColon("DELIVERY_NOTE_REMARKS"),
        class: genericstring, component: generic_string, mandatory: false
    },

    receipt_note_remarks: {
        id: "receipt_note_remarks", name: "receipt_note_remarks", label: window.getLabelLiteralWithColon("RECEIPT_NOTE_REMARKS"),
        class: genericstring, component: generic_string, mandatory: false
    },

    seller_item_description: {
        id: "seller_item_description", name: "seller_item_description", label: window.getLabelLiteralWithColon("ITEM_DESCRIPTION"),
        class: genericstring, component: generic_string, mandatory: false
    },

    hsn_code: {
        id: "hsn_code", name: "hsn_code", label: "HSN/SAC Code:",
        class: genericstring, component: generic_string, mandatory: false
    },

    deleted: {
        id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
        class: genericcheckbox, component: generic_checkbox, mandatory: false
    }

};

let details_mapping = [
    ["seller_item_id", "seller_item_id"],
    ["ordered_quantity", null],
    ["delivered_quantity", null],
    ["dispatched_quantity", "dispatched_quantity"],
    ["accepted_quantity", "accepted_quantity"],
    ["unit_of_measure", "unit_of_measure"],
    ["delivery_note_remarks", "delivery_note_remarks"],
    ["receipt_note_remarks", "receipt_note_remarks"],
    ["seller_item_description", "seller_item_description"],
    ["hsn_code", "hsn_code"],
    ["deleted", "deleted"],
];

let additional_mapping = [
    ["manufacture_date", "manufacture_date"],
    ["lot_identification_number", "lot_identification_number"],
    ["expiry_date", "expiry_date"]
]

class AddEditDeliveryNote extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;
        this.state.step = 1;
        this.state.showModal = false;
        this.idx = null;
        this.readOnly = false;
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();
        this.action = props.action;
        this.deliveryNoteId = props.deliveryNoteId;
        this.deliveryNote = { header: {}, details: [{}] };
        this.logged_in_company_id = this.session.getLoggedInCompanyId();
        this.parent_company_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
        this.page_title = "";
        this.getRow = this.getRow.bind(this);
        this.page_title += (this.action === "DELETE") ? window.getLabelLiteral("DELETING") : window.getLabelLiteral(this.action);
        if (this.action === "ADD") {
            this.state.data.delivery_note_date = this.session.getCurrentDate();
        }
        else if (this.action === "EDIT" || this.action === "AMEND") {
            this.state.step = 2;
        }
        else if (this.action === "APPROVE" || this.action === "CANCEL" || this.action === "ACCEPT") {
            this.state.step = 2;
            this.readOnly = true;
        }
        else if (this.action === "VIEW") {
            this.readOnly = true;
            this.state.step = 2;
        }
        else if (this.action === "DELETE") {
            this.readOnly = true;
        }
        this.page_title += " " + window.getLabelLiteral("DELIVERY_NOTE_TITLE");
    }

    initialState = getInitialState(header_fields);

    state = {
        data: deepCopy(this.initialState),
    }

    reset = () => {
        let data = deepCopy(this.initialState);
        this.setState({ data: data, error: undefined });
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode())
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                selectData: this.deliveryNote, error
            });
        else console.log("LOADED AS A PARENT");
    }

    fetch = async (deliveryNoteId) => {
        return await deliveryNoteServiceRoutines.fetch(this.subscriberServerInstance,
            this.accessToken, deliveryNoteId);
    }

    fetchAmended = async (deliveryNoteId) => {
        return await deliveryNoteServiceRoutines.fetchAmended(this.subscriberServerInstance, this.accessToken, deliveryNoteId);
    }

    delete = async (deliveryNote) => {
        let retData;
        try {
            await deliveryNoteServiceRoutines.delete(this.subscriberServerInstance,
                this.accessToken, deliveryNote.header);
        }
        catch (e) {
            //this.setError(raiseError(e.error_message));
            throw e;
        }

        return retData
    }

    componentDidMount = async () => {
        let newData_1, newData_2, newData;
        try {
            switch (this.action) {
                case 'ADD':
                    newData = deepCopy(this.state.data);
                    newData = copyDataArrayToMultiRecFormFields(details_mapping, this.deliveryNote.details, newData);
                    this.deliveryNote.header.seller_org_id = this.logged_in_company_id;
                    newData.seller_org_id = this.logged_in_company_id;
                    this.setState({ loading: false, data: newData });
                    break;
                case 'AMEND':
                case 'CANCEL':
                case 'ACCEPT':
                    this.deliveryNote = await this.fetchAmended(this.deliveryNoteId);
                    newData_1 = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.deliveryNote.header, newData_1);
                    newData_2 = copyDataArrayToMultiRecFormFields(details_mapping, this.deliveryNote.details, newData_1);
                    if (newData_2.order_issue_date !== "" && newData_2.order_number !== "")
                        await this.fetchOrder(newData_2.buyer_org_id, newData_2.order_issue_date, newData_2.order_number);
                    this.setState({ loading: false, data: newData_2 });
                    break;
                case 'EDIT':
                case 'VIEW':
                case 'APPROVE':
                    this.deliveryNote = await this.fetch(this.deliveryNoteId);
                    newData_1 = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.deliveryNote.header, newData_1);
                    newData_2 = copyDataArrayToMultiRecFormFields(details_mapping, this.deliveryNote.details, newData_1);
                    if (newData_2.order_issue_date !== "" && newData_2.order_number !== "")
                        await this.fetchOrder(newData_2.buyer_org_id, newData_2.order_issue_date, newData_2.order_number);
                    this.setState({ loading: false, data: newData_2 });
                    break;
                case 'DELETE':
                    this.deliveryNote = await this.fetch(this.deliveryNoteId);
                    newData_1 = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.deliveryNote.header, newData_1);
                    newData_2 = copyDataArrayToMultiRecFormFields(details_mapping, this.deliveryNote.details, newData_1);
                    this.setState({ data: newData_2 });
                    if (this.deliveryNote.deleted === true) {
                        this.setError(raiseError(window.getLabelLiteral("RECORD_DELETED") + " [" + this.deliveryNoteId + "]"));
                        this.backHandler(this.action);
                    }
                    await this.delete(this.deliveryNote);
                    this.backHandler(this.action);
                    break;
                default:
                    this.setError(raiseError("AddDeliveryNote: Invalid action [" + this.action + "]"));
                    this.backHandler(this.action, this.error);
                    break;
            }
        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    partnerSelectOption = (org_id, org_name) => {
        let data = deepCopy(this.state.data)
        data.buyer_org_name = org_name;
        data.buyer_org_id = org_id;
        this.setState({ data });
    }

    soSelectOption = (order_number, order_issue_date, seller_org_id, buyer_org_id, buyer_org_name) => {
        let data = deepCopy(this.state.data)
        data.order_number = order_number;
        data.order_issue_date = order_issue_date;
        if (isSubscriber(this.session) && valuePresent(buyer_org_id) && buyer_org_id !== "") {
            data.buyer_org_id = buyer_org_id;
            data.buyer_org_name = buyer_org_name;
        }
        this.setState({ data });
    }

    soOnChange = (event) => {
        let data = deepCopy(this.state.data)
        data.order_number = event.target.value;
        this.setState({ data });
    }

    addNewRow = () => {
        let data = this.state.data;
        this.resetError();
        let [newData, new_list] = addMultiRecRow(details_mapping, data,
                        this.deliveryNote.details, undefined, undefined,  [], details_fields);
        this.deliveryNote.details = new_list;
        this.setState({ data: newData });
    }

    button_inputs = [
        {
            literal: "Add",
            func: () => this.addNewRow()
        }
    ]

    deleteRow = (data, i) => {
        if (this.deliveryNote.details[i].version === null || this.deliveryNote.details[i].version === undefined) {
            this.resetError();
            let [newData, updated_list] = deleteMultiRecRow(details_mapping,
                            this.state.data, this.deliveryNote.details, i, undefined, [], details_fields);
            this.deliveryNote.details = updated_list;
            this.setState({ data: newData });
        }
        else {
            this.deliveryNote.details[i].deleted = true;
        }
    }

    getHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
                <th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
                {(this.action === "ADD") &&
                    <>
                        <th key={++key}>{window.getLabelLiteral("ORDERED_QUANTITY")}</th>
                        <th key={++key}>{window.getLabelLiteral("PRE_DELIVERED_QUANTITY")}</th>
                        <th key={++key}>{window.getLabelLiteral("DISPATCHED_QUANTITY")}</th>
                    </>}
                {(this.action !== "ADD") && <th key={++key}>{window.getLabelLiteral("DISPATCHED_QUANTITY")}</th>}
                {(this.action !== "ADD" &&
                (this.deliveryNote.header.delivery_note_status === "4" ||
                this.deliveryNote.header.delivery_note_status === "5")) 
                && <th key={++key}>{window.getLabelLiteral("ACCEPTED_QUANTITY")}</th>}
                <th key={++key}>{window.getLabelLiteral("DELIVERY_NOTE_REMARKS")}</th>
                <th key={++key}>{window.getLabelLiteral("RECEIPT_NOTE_REMARKS")}</th>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
                <th key={++key}>{window.getLabelLiteral("HSN_SAC_CODE")}</th>
                {(!this.readOnly) &&
                    <th key={++key} colSpan={2}>{window.getLabelLiteral("ACTIONS")}</th>
                }
            </tr>
        );
    }

    fetchOrder = async (buyerOrgId, orderIssueDate, orderNumber) => {
        let retData;
        try {
            retData = await deliveryNoteServiceRoutines.fetch_sales_order(this.subscriberServerInstance,
                this.accessToken, buyerOrgId, orderIssueDate, orderNumber);
            this.order = retData;
        }
        catch (e) {
            //this.setError(raiseError(e.error_message));
            // console.log(e.error_message);
            console.log(e);
            this.order = null;
        }

    }

    showAdditional(i) {
        this.idx = i;
        let line_item = this.deliveryNote.details[this.idx];
        let data = deepCopy(this.state.data);
        if (line_item.expiry_date || line_item.manufacture_date || line_item.lot_identification_number) {
            copyDataFieldsToFormFields(additional_mapping, line_item, data);
            this.setState({ data: data });
        }
        this.setState({ showModal: true })
    }

    getRow = (data, index) => {
        const i = index - 1;
        let delete_button = 1;
        if (data.version >= 0)
            delete_button = 2;

        let view_delete_option = 0;
        if (!this.readOnly && delete_button === 1) view_delete_option = 1;
        else if (!this.readOnly && delete_button === 2) view_delete_option = 2;

        let select = false;
        let selectList = [];
        if (this.order) {
            select = true;
            selectList = deepCopy(this.order.details);
        }

        selectList.forEach(item => {
            if (item.hasOwnProperty('seller_item_id')) {
                item['item_id'] = item['seller_item_id'];
                item['item_description'] = item['seller_item_description'];
                delete item['seller_item_id'];
                delete item['seller_item_description'];
            }
        });

        let itemIdChange = (event, i) => {
            let data = deepCopy(this.state.data);
            /*
             * We have to set item id also because the state change done in 
             * genericcomponent will get overwritten by the following change
             */
            let selected_i = event.target.selectedIndex;
            let selected_rec = selectList[selected_i - 1];
            data[`seller_item_id_${i}`] = selected_rec.item_id;
            data[`seller_item_description_${i}`] = selected_rec.item_description;
            data[`hsn_code_${i}`] = valuePresent(selected_rec.hsn_code)?selected_rec.hsn_code:"";
            data[`unit_of_measure_${i}`] = selected_rec.unit_of_measure;
            data[`ordered_quantity_${i}`] = selected_rec.quantity;
            data[`delivered_quantity_${i}`] = selected_rec.delivered_quantity;
            data[`dispatched_quantity_${i}`] = (selected_rec.quantity - selected_rec.delivered_quantity > 0) ?
            String(selected_rec.quantity - selected_rec.delivered_quantity) : "";
            this.setState({ data });
        }

        let r =
            <tr key={++index}>
                <td>
                    {(select) &&
                        <MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
                            readOnly={(this.readOnly) || (data.version !== undefined && data.version !== null)}
                            select={select} selectList={selectList}
                            style={{width:"100%"}}
                            onChange={(event) => { return itemIdChange(event, i); }} />
                    }
                    {!(select) &&
                        <MultiRecFormField field={details_fields.seller_item_id}
                            controlProps={this.controlProps} index={i}
                            readOnly={(this.readOnly) || (data.version !== undefined && data.version !== null)}
                            style={{width:"100%"}}
                        />
                    }
                </td>
                <td>
                    <MultiRecFormField field={details_fields.unit_of_measure} controlProps={this.controlProps} index={i}
                        disabled={this.readOnly || select === true} style={{width : "100%"}}
                    />
                </td>
                {(this.action === "ADD") &&
                    <>
                        <td>
                            <MultiRecFormField field={details_fields.ordered_quantity}
                                controlProps={this.controlProps} index={i}
                                style={{width:"100%", textAlign:"right"}}
                                readOnly={this.readOnly || select === true} />
                        </td>
                        <td>
                            <MultiRecFormField field={details_fields.delivered_quantity} 
                            controlProps={this.controlProps} 
                            index={i}
                            style={{width:"100%", textAlign:"right"}}
                            readOnly={this.readOnly || select === true} />
                        </td>
                        <td>
                            <MultiRecFormField field={details_fields.dispatched_quantity} 
                            controlProps={this.controlProps} 
                            index={i}
                            style={{width:"100%", textAlign:"right"}}
                            readOnly={this.readOnly} />
                        </td>
                    </>
                }
                {
                    (this.action !== "ADD") &&
                    <td>
                        <MultiRecFormField field={details_fields.dispatched_quantity} 
                        controlProps={this.controlProps} 
                        index={i}
                        style={{width:"100%", textAlign:"right"}}
                        readOnly={this.readOnly} />
                    </td>
                }
                {
                    (this.action !== "ADD" &&
                    (this.deliveryNote.header.delivery_note_status === "4" ||
                    this.deliveryNote.header.delivery_note_status === "5")) &&
                    <td>
                        <MultiRecFormField field={details_fields.accepted_quantity} 
                        controlProps={this.controlProps} 
                        index={i}
                        style={{width:"80%", textAlign:"right"}}
                        readOnly={this.readOnly || select === true} />
                    </td>
                }
                <td>
                    <MultiRecFormField 
                    field={details_fields.delivery_note_remarks} 
                    controlProps={this.controlProps} 
                    index={i}
                    style={{width:"100%"}}
                    readOnly={this.readOnly} />
                </td>
                <td>
                    <MultiRecFormField 
                    field={details_fields.receipt_note_remarks} 
                    controlProps={this.controlProps} 
                    index={i}
                    style={{width:"80%"}}
                    readOnly={true} />
                </td>
                <td>
                    <MultiRecFormField
                    field={details_fields.seller_item_description}
                    controlProps={this.controlProps}
                    index={i}
                    style={{ width: "100%", textAlign: "left" }}
                    readOnly={this.readOnly} />
                </td>
                <td >
                    <MultiRecFormField
                        field={details_fields.hsn_code}
                        controlProps={this.controlProps}
                        index={i}
                        style={{width:"95%"}}
                        maxLength={10}
                        readOnly={this.readOnly} />
                </td>
                {(view_delete_option === 1) &&
                    <>
                        <td>{
                            (!this.deliveryNote.details[i].show_additional) &&
                            <div className="mt-0" style={{ cursor: "pointer" }}>
                                <span onClick={() => { return this.showAdditional(i) }}>
                                    <i className="bi bi-file-plus" style={{ padding: "1rem", fontSize: 20 }}></i>
                                </span>
                            </div>
                        }
                        </td>
                        <td>
                            <div className="mt-0" style={{ cursor: "pointer" }}>
                                <span onClick={() => { return this.deleteRow(data, i); }}>
                                    <i className="bi bi-trash3" style={{ padding: "1rem", fontSize: 20 }}></i>
                                </span>
                            </div>
                        </td>
                    </>
                }
                {(view_delete_option === 2) &&
                    <>
                        <td>
                            {
                                (!this.deliveryNote.details[i].show_additional) &&
                                <div className="mt-0" style={{ cursor: "pointer" }}>
                                    <span onClick={() => { return this.showAdditional(i) }}>
                                        <i className="bi bi-file-plus" style={{ padding: "1rem", fontSize: 20 }}></i>
                                    </span>
                                </div>
                            }
                        </td>
                        <td>
                            <div className="ml-0 mt-1" style={{ paddingLeft: "30px" }}>
                                <MultiRecFormField field={details_fields.deleted} controlProps={this.controlProps}
                                    style={{ paddingLeft: "12px" }} index={i} disabled={this.readOnly} />
                            </div>
                        </td>
                    </>
                }
            </tr>
        return r;
    }

    partnerListOnChange = (event) => {
        console.log(event.target.value);
    }

    proceed = async () => {
        this.resetError();
        let r = fieldValidations(header_fields, this.state.data);
        if (!r.status) {
            this.setError(r);
            return false;
        }
        let data = deepCopy(this.state.data);
        if (this.state.data.order_issue_date !== "" && this.state.data.order_number !== "") {
            await this.fetchOrder(this.state.data.buyer_org_id, this.state.data.order_issue_date, this.state.data.order_number);
            if (this.order) {
                data.buyer_org_id = this.order.header.buyer_org_id;
            }
        }
        else {
            this.order = null
        }
        this.setState({ step: 2, data });
    }

    closeModal = () => {
        let data = deepCopy(this.state.data);
        data.expiry_date = "";
        data.manufacture_date = "";
        data.lot_identification_number = "";
        this.setState({ data: data });
        this.setState({ showModal: false });
    }

    onConfirm = () => {
        let data = deepCopy(this.state.data);
        if (data.expiry_date === "" && data.manufacture_date === "" && data.lot_identification_number === "") {
            return;
        }
        copyFormFieldsToDataFields(additional_mapping, this.state.data, this.deliveryNote.details[this.idx], details_fields);
        data.expiry_date = "";
        data.manufacture_date = "";
        data.lot_identification_number = "";
        this.setState({ data: data });
        this.setState({ showModal: false });

    }

    data_render = () => {
        let modalContent = <>
            <div className='row'>
                <div className='col-sm-6'>
                    <FormField field={header_fields.expiry_date} controlProps={this.controlProps}
                        readOnly={this.readOnly} />
                </div>
                <div className='col-sm-6'>
                    <FormField field={header_fields.manufacture_date} controlProps={this.controlProps}
                        readOnly={this.readOnly} />
                </div>
                <div className='col-sm-6'>
                    <FormField field={header_fields.lot_identification_number} controlProps={this.controlProps}
                        readOnly={this.readOnly} />
                </div>
            </div>
        </>
        let buttons = [];
        let key = 0;
        let datablk = <div className="container-fluid pt-0 m-0 pe-2">
            <div className="row  p-0 m-0 g-0 " >
                <div className="col-sm-12 h6 text-left" >{this.getBreadcrumb([window.getLabelLiteral(this.action)])}</div>

                <div className="col-sm-6" >
                    <PartnerList
                        selectOption={this.partnerSelectOption}
                        field={header_fields.buyer_org_id}
                        controlProps={this.controlProps}
                        relationship='D'
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>

                <div className="col-sm-6" >
                    <FormField field={header_fields.place_holder} controlProps={this.controlProps} readOnly={this.readOnly} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.order_issue_date} controlProps={this.controlProps}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))} />
                </div>
                <div className="col-sm-6" >
                    <SalesOrderList
                        selectOption={this.soSelectOption}
                        field={header_fields.order_number}
                        issueDateFieldName="order_issue_date"
                        controlProps={this.controlProps}
                        onChange={this.soOnChange}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.delivery_note_date} controlProps={this.controlProps} 
                    readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))} />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.delivery_note_number} controlProps={this.controlProps}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
                <div className="col-sm-6" >
                    <FormField field={header_fields.description} controlProps={this.controlProps}
                        readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                    />
                </div>
            
                <div className="col-sm-6 mt-2 mb-3 ">
                        <Attachments
                            field={header_fields.attachments}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                            fileAttributeRequired={true}
                        />
                </div>


                {(this.state.step === 1) &&
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        <NarrowButton value="Proceed" onClick={this.proceed} />
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                    </div>
                }

                {(this.state.step === 2) &&
                    <div className='col-sm-12'>
                        <div style={{ margin: 0, padding: 0, height: "25px" }}></div>
                        <MultiRec getRow={this.getRow} getHeader={this.getHeader}
                            data={this.deliveryNote.details} buttons={(this.readOnly) ? null : this.button_inputs} />
                    </div>
                }
            </div>

            {(this.state.step === 2) &&
                <div className="row  p-0 m-0 g-0 " >
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        {!(this.readOnly) &&
                            <NarrowButton value="Submit" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        {(this.readOnly && (this.action === "APPROVE")) &&
                            <NarrowButton value="Approve" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        {(this.readOnly && (this.action === "ACCEPT")) &&
                            <NarrowButton value="Accept" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        {(this.readOnly && (this.action === "CANCEL")) &&
                            <NarrowButton value="Cancel" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                    </div>
                </div>
            }

            {(this.state.showModal) && <Modal_generic_content
                title="Add Additional Information"
                btn1="Confirm"
                btn2="Cancel"
                onClick={this.closeModal}
                onConfirm={this.onConfirm}
                modalContent={modalContent}
            />
            }
            {(this.state.showModal) && <Backdrop />}
        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    loading_render = () => {
        let buttons = [];

        let datablk = <div className="container pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                {/*<div className="col-sm-12 h4 text-center" >{this.page_title}</div>*/}
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

    validateForm = () => {
        let r = fieldValidations(header_fields, this.state.data);

        if (!r.status) {
            this.setError(r);
            return false;
        }
        r = multiRecFieldValidations(details_fields, this.state.data);
        if (!r.status) {
            this.setState({ error: r });
            return false;
        }

        return true;
    }

    submitForm = async () => {
        this.resetError();

        if (!this.validateForm()) {
            return;
        }

        let data = deepCopy(this.state.data);
        copyFormFieldsToDataFields(header_mapping, data, this.deliveryNote.header, header_fields);
        copyMultiRecFormFieldsToDataArray(details_mapping, this.state.data, this.deliveryNote.details, null, [], details_fields);
        try {
            switch (this.action) {
                case "ADD":
                    await deliveryNoteServiceRoutines.add(this.subscriberServerInstance, this.accessToken, this.deliveryNote);
                    this.deliveryNote =
                        await deliveryNoteServiceRoutines.fetchUsingNumberAndDate(this.subscriberServerInstance, this.accessToken,
                            this.deliveryNote.header.delivery_note_number, this.deliveryNote.header.delivery_note_date, this.deliveryNote.header.seller_org_id);

                    break;
                case "EDIT":
                    await deliveryNoteServiceRoutines.modify(this.subscriberServerInstance, this.accessToken, this.deliveryNote);
                    this.deliveryNote = await this.fetch(this.deliveryNoteId);
                    break;
                case "AMEND":
                    await deliveryNoteServiceRoutines.amend(this.subscriberServerInstance, this.accessToken, this.deliveryNote);
                    this.deliveryNote = await this.fetch(this.deliveryNoteId);
                    break;
                case "CANCEL":
                    await deliveryNoteServiceRoutines.cancelAmendMent(this.subscriberServerInstance, this.accessToken, this.deliveryNote);
                    this.deliveryNote = await this.fetch(this.deliveryNoteId);
                    break;
                case "ACCEPT":
                    await deliveryNoteServiceRoutines.send(this.subscriberServerInstance, this.accessToken, this.deliveryNote);
                    this.deliveryNote = await this.fetch(this.deliveryNoteId);
                    break;
                case "APPROVE":
                    await deliveryNoteServiceRoutines.approve(this.subscriberServerInstance, this.accessToken, this.deliveryNote);
                    this.deliveryNote = await this.fetch(this.deliveryNoteId);
                    break;
                default:
                    break;
            }

            this.backHandler(this.action);
        }
        catch (e) {
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }
}

export default AddEditDeliveryNote;
