import React from 'react';
import {
    GenericComponent,
    NarrowButton,
    GenericForm,
    FormField,

    MultiRecFormField,
    MultiRecHandler,
    file_set,
    FileSet,

    getInitialState,
    copyFormFieldsToDataFields,
    copyDataFieldsToFormFields,
    deepCopy,
    raiseError,
    extractNumberStr,

    documentnumber,
    document_number,
    inputdate,
    input_date,
    user_name,
    username,
    emailid,
    email_id,
    contactphonenumber,
    contact_phone_number,
    generic_string,
    genericstring,
    generic_integer,
    genericinteger,
    genericcheckbox,
    generic_checkbox,
    genericgenrefcode,
    generic_genrefcode,
    currency,
    Currency,
    item_id,
    itemid,
    amounttextbox,
    amount_textbox,
    genericdecimal,
    generic_decimal,
    unit_measure,
    unitmeasure,
    MultiRec,

} from 'WebUI_Framework';


import receivedQuotationServiceRoutines from "./receivedQuotationServiceRoutines";
import Attachments from '../common/Attachments'

const LINE_ITEMS_MREC = "LINE_ITEMS";

const header_fields = {
    quotation_number: {
        id: "quotation_number", name: "quotation_number", label: "Quotation Number:",
        class: documentnumber, component: document_number, mandatory: true
    },

    quotation_issue_date: {
        id: "quotation_issue_date", name: "quotation_issue_date",
        label: "Quotation Issue Date:", class: inputdate, component: input_date, mandatory: true
    },

    quotation_valid_from_date: {
        id: "quotation_valid_from_date", name: "quotation_valid_from_date",
        label: "Quotation Valid From:", class: inputdate, component: input_date, mandatory: true
    },

    quotation_valid_to_date: {
        id: "quotation_valid_to_date", name: "quotation_valid_to_date",
        label: "Quotation Valid Till:", class: inputdate, component: input_date, mandatory: true
    },

    description: {
        id: "description", name: "description", label: "Description:",
        class: genericstring, component: generic_string, mandatory: true
    },

    quotation_contact_user_name: {
        id: "quotation_contact_user_name", name: "quotation_contact_user_name",
        label: "Contact Person:", class: username, component: user_name, mandatory: true
    },

    quotation_contact_email_id: {
        id: "quotation_contact_email_id", name: "quotation_contact_email_id",
        label: "Contact Email:", class: emailid, component: email_id, mandatory: true
    },

    quotation_contact_phone_num: {
        id: "quotation_contact_phone_num", name: "quotation_contact_phone_num",
        label: "Contact Phone:", class: contactphonenumber, component: contact_phone_number, mandatory: true
    },

    seller_org_id: {
        id: "seller_org_id", name: "seller_org_id", label: "Seller Id:",
        class: genericstring, component: generic_string, mandatory: false
    },

    seller_org_name: {
        id: "seller_org_name", name: "seller_org_name",
        label: window.getLabelLiteralWithColon("SELLER_COMPANY_NAME"),
        class: genericstring, component: generic_string, mandatory: false
    },

    buyer_org_id: {
        id: "buyer_org_id", name: "buyer_org_id", label: "", class: genericstring,
        component: generic_string, mandatory: false
    },

    buyer_org_name: {
        id: "buyer_org_name", name: "buyer_org_name",
        label: window.getLabelLiteralWithColon("BUYER_COMPANY_NAME"), class: genericstring,
        component: generic_string, mandatory: true,
    },

    rfq_number: {
        id: "rfq_number", name: "rfq_number", label: "RFQ Number:",
        class: documentnumber, component: document_number, mandatory: true
    },

    rfq_issue_date: {
        id: "rfq_issue_date", name: "rfq_issue_date",
        label: "RFQ Issue Date:", class: inputdate, component: input_date, mandatory: true
    },

    rfq_id: {
        id: "rfq_id", name: "rfq_id", label: "", class: genericstring,
        component: generic_string, mandatory: false
    },

    rfq_description: {
        id: "rfq_description", name: "rfq_description", label: "RFQ Description:",
        class: genericstring, component: generic_string, mandatory: true
    },

    notes: {
        id: "notes", name: "notes", label: "Notes:",
        class: genericstring, component: generic_string, mandatory: false
    },

    payment_period: {
        id: "payment_period", name: "payment_period", label: "Payment period:",
        class: genericinteger, component: generic_integer, mandatory: false,
        comandatory_with: ["payment_period_unit"]
    },

    payment_period_unit: {
        id: "payment_period_unit", name: "payment_period_unit", label: "Payment period unit:",
        class: genericgenrefcode, component: generic_genrefcode, mandatory: false,
        comandatory_with: ["payment_period"]
    },

    currency: {
        id: "currency", name: "currency", label: "Currency:",
        class: Currency, component: currency, mandatory: true
    },

    attachments: {
        id: "attachments", name: "attachments", label: window.getLabelLiteralWithColon("ATTACHMENTS"),
        class: file_set, component: FileSet, mandatory: false,
        //initialValue: "1234244.00",
    },

}

let header_mapping = [
    ["quotation_number", "quotation_number"],
    ["quotation_issue_date", "quotation_issue_date"],
    ["quotation_valid_from_date", "quotation_valid_from_date"],
    ["quotation_valid_to_date", "quotation_valid_to_date"],
    ["quotation_contact_user_name", "quotation_contact_user_name"],
    ["quotation_contact_email_id", "quotation_contact_email_id"],
    ["quotation_contact_phone_num", "quotation_contact_phone_num"],
    ["seller_org_id", "seller_org_id"],
    ["seller_org_name", "seller_org_name"],
    ["buyer_org_id", "buyer_org_id"],
    ["buyer_org_name", "buyer_org_name"],
    ["rfq_id", "rfq_id"],
    ["rfq_number", "rfq_number"],
    ["rfq_description", null],
    ["rfq_issue_date", "rfq_issue_date"],
    ["description", "description"],
    ["notes", "notes"],
    ["payment_period", "payment_period"],
    ["payment_period_unit", "payment_period_unit"],
    ["currency", "currency"],
    ["attachments", "attachments"]
];

const details_fields = {
    seller_item_id: {
        id: "seller_item_id", name: "seller_item_id", label: "Item Id:",
        class: itemid, component: item_id, mandatory: true
    },

    seller_item_description: {
        id: "seller_item_description", name: "seller_item_description", label: "Description:",
        class: genericstring, component: generic_string, mandatory: true
    },

    hsn_code: {
        id: "hsn_code", name: "hsn_code", label: "HSN/SAC Code:",
        class: genericstring, component: generic_string, mandatory: true
    },

    quantity: {
        id: "quantity", name: "quantity", label: "Quantity:",
        class: genericdecimal, component: generic_decimal, mandatory: true
    },

    amount: {
        id: "amount", name: "amount", label: "Amount:",
        class: amounttextbox, component: amount_textbox, mandatory: false
    },

    rate_per_unit: {
        id: "rate_per_unit", name: "rate_per_unit", label: "Rate per Unit:",
        class: amounttextbox, component: amount_textbox, mandatory: true
    },

    unit_of_measure: {
        id: "unit_of_measure", name: "unit_of_measure", label: "Unit:",
        class: unitmeasure, component: unit_measure, mandatory: true
    },

    item_delivery_from_date: {
        id: "item_delivery_from_date", name: "item_delivery_from_date", label: "Delivery From Date:",
        class: inputdate, component: input_date, mandatory: true
    },

    item_delivery_to_date: {
        id: "item_delivery_to_date", name: "item_delivery_to_date", label: "Delivery To Date:",
        class: inputdate, component: input_date, mandatory: true
    },

    notes: {
        id: "notes", name: "notes", label: "Notes:",
        class: genericstring, component: generic_string, mandatory: false
    },

    quotation_serial_id: {
        id: "quotation_serial_id", name: "quotation_serial_id", label: "Quotation Serial Id",
        class: genericinteger, component: generic_integer, mandatory: false,
    },
    
    deleted: {
        id: "deleted", name: "deleted", label: window.getLabelLiteralWithColon("DELETED"),
        class: genericcheckbox, component: generic_checkbox, mandatory: false
    },
}

let details_mapping = [
    ["seller_item_id", "seller_item_id"]
    ,["seller_item_description", "seller_item_description"]
    ,["hsn_code", "hsn_code"]
    ,["quantity", "quantity"]
    ,["rate_per_unit", "rate_per_unit"]
    ,["unit_of_measure", "unit_of_measure"]
    ,["notes", "notes"]
    ,["item_delivery_from_date", "item_delivery_from_date"]
    ,["item_delivery_to_date", "item_delivery_to_date"]
    ,["quotation_serial_id", "quotation_serial_id"]
    ,["deleted", "deleted"]
    ,["amount", null]
];

class AddEditQuotation extends GenericComponent {
    constructor(props) {
        super(props);
        this.state.loading = true;
        this.state.step = 1;

        this.readOnly = false;
        this.subscriberServerInstance = this.session.getServerInstance('SUBSCRIBER');
        this.accessToken = this.session.getAccessToken();
        this.action = props.action;
        this.rfqId = props.rfqId;
        this.quotationId = props.quotationId;
        this.receivedQuotation = { header: {}, details: [] };
        this.logged_in_company_id = this.session.getLoggedInCompanyId();
        this.logged_in_company_name = this.session.getLoggedInCompanyName();
        this.parent_company_id = this.session.getLoggedInParentCompanyId();
        this.is_subscriber = (this.logged_in_company_id === this.parent_company_id);
        this.receivedQuotation.header.seller_org_id = this.logged_in_company_id;
        this.state.data.seller_org_id = this.logged_in_company_id;
        this.state.data.seller_org_name = this.logged_in_company_name;
        this.receivedQuotation.details[0] = {};
        this.page_title = "";
        if (this.action === 'REJECT') {
            this.state.step = 2;
            this.readOnly = true;
        }
        else if (this.action === "VIEW") {
            this.readOnly = true;
            this.state.step = 2;
        }
        this.page_title += " " + window.getLabelLiteral("RECEIVED_QUOTATION_TITLE");

        this.lineItemsHandler = new MultiRecHandler(this.session, details_fields, details_mapping, LINE_ITEMS_MREC);
    }

    initialState = getInitialState(header_fields);

    state = {
        data: deepCopy(this.initialState),
    }

    backHandler = (retFrom, error) => {
        if (this.isChildMode()) {
            this.props.returnToParent({
                retFrom, arrayIndex: this.props.arrayIndex,
                qArrayIndex: this.props.qArrayIndex,
                selectData: this.receivedQuotation, error
            });
        }
        else {
            console.log("LOADED AS A PARENT");
        }
    }

    fetch = async (quotationId) => {
        return await receivedQuotationServiceRoutines.fetch(this.session, quotationId);
    }

    componentDidMount = async () => {
        let newData;
        try {
            switch (this.action) {
                /*eslint no-fallthrough: 0*/
                case 'VIEW':
                case 'REJECT':
                    this.receivedQuotation = await this.fetch(this.quotationId);

                    newData = deepCopy(this.state.data);
                    copyDataFieldsToFormFields(header_mapping, this.receivedQuotation.header, newData);
                    newData = this.lineItemsHandler.dataArrayToFormFields(this.receivedQuotation.details, newData);
                    this.setState({ loading: false, data: newData });

                    break;
                default:
                    this.setError(raiseError("AddEditQuotation: Invalid action [" + this.action + "]"));
                    this.backHandler(this.action, this.error);
                    break;
            }
        }
        catch (e) {
            console.log(this.action);
            console.log(e);
            this.setError(raiseError((e.error_message) ? e.error_message : e.message));
            this.backHandler(this.action, this.error);
        }
    }

    getHeader = () => {
        let key = 0;
        return (
            <tr key={++key}>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_ID")}</th>
                <th key={++key}>{window.getLabelLiteral("SELLER_ITEM_DESCRIPTION")}</th>
                <th key={++key}>{window.getLabelLiteral("HSN_SAC_CODE")}</th>
                <th key={++key}>{window.getLabelLiteral("FROM_DATE")}</th>
                <th key={++key}>{window.getLabelLiteral("TO_DATE")}</th>
                <th key={++key}>{window.getLabelLiteral("QUANTITY")}</th>
                <th key={++key}>{window.getLabelLiteral("UNIT_OF_MEASURE")}</th>
                <th key={++key}>{window.getLabelLiteral("RATE")}</th>
                <th key={++key}>{window.getLabelLiteral("NET_AMOUNT")}</th>
                <th key={++key}></th>
            </tr>
        );
    }

    computeAmount = (data, i) => {
        let rate;
        try {
            rate = Number(
                extractNumberStr(data[this.lineItemsHandler.getFieldIdentifier("rate_per_unit", i)].trim(), true));
        } catch (e) {
            console.log(e);
            return "";
        }
        let quantity = Number(data[this.lineItemsHandler.getFieldIdentifier("quantity", i)]);
        if (isNaN(quantity)) return "";

        return (rate * quantity).toString();
    }

    getRow = (rowData, index) => {
        const i = index - 1;

        let data = deepCopy(this.state.data);
        const ca = this.computeAmount(data, i);

        let key = 0;
        let r = <React.Fragment key={index}>
            <tr key={++key}>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.seller_item_id} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "75%" }}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.seller_item_description} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{width:"95%"}}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.hsn_code} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{width:"95%"}}
                        maxLength={10}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.item_delivery_from_date} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "85%" }} disabled={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.item_delivery_to_date} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{ width: "85%" }} disabled={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.quantity} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        formattingInputs={{ minimumFractionDigits: 0, maximumFractionDigits: 6 }}
                        style={{ width: "80%", textAlign:"right"}} readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.unit_of_measure} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        style={{width:"100%"}}
                        disabled={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.rate_per_unit} controlProps={this.controlProps} index={i}
                        multiRecId={LINE_ITEMS_MREC}
                        currency={this.state.data.currency}
                        readOnly={this.readOnly} />
                </td>
                <td key={++key}>
                    <MultiRecFormField field={details_fields.amount} controlProps={this.controlProps} index={i}
                        valueSuperseed={ca}
                        multiRecId={LINE_ITEMS_MREC}
                        currency={this.state.data.currency}
                        readOnly={true} />
                </td>
            </tr>
        </React.Fragment>
        return r;
    }

    data_render = () => {
        let key = 0;

        let datablk = (
            <div className="container-fluid border border-0 pt-0 m-0 ">
                <div className="row    p-0 m-0 g-0 ">
                    <div className="col-sm-12 h6 text-left">
                        {this.getBreadcrumb([window.getLabelLiteral(this.action)])}
                    </div>
                    <hr></hr>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.quotation_number}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>

                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.quotation_issue_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.description}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.currency}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.quotation_valid_from_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.quotation_valid_to_date}
                            style={{}}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.payment_period}
                            style={{textAlign:"left"}}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.payment_period_unit}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                            refType={"PeriodUnitType"}
                        />
                    </div>

                    <div className="col-sm-6" >
                        <FormField
                            field={header_fields.seller_org_name}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                        />
                    </div>

                    <div className="col-sm-6">
                        &nbsp;
                    </div>

                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.quotation_contact_user_name}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.quotation_contact_email_id}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.quotation_contact_phone_num}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step > 1) || (this.action !== "ADD"))}
                        />
                    </div>

                    <div className="col-sm-6">
                        &nbsp;
                    </div>
                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_number}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step>1) || (this.action !== "ADD")) ||
                                        (this.receivedRFQ !== null)
                                    }
                        />
                    </div>

                    <div className="col-sm-6">
                        <FormField
                            field={header_fields.rfq_issue_date}
                            controlProps={this.controlProps}
                            readOnly={((this.readOnly) || (this.state.step>1) || (this.action !== "ADD")) ||
                                        (this.receivedRFQ !== null)
                                    }
                        />
                    </div>

                    <div className="col-sm-6 mt-3 mb-3 ">
                        <Attachments
                            field={header_fields.attachments}
                            controlProps={this.controlProps}
                            readOnly={this.readOnly}
                            fileAttributeRequired={true}
                        />
                    </div>

                    <div className="col-sm-6">
                        &nbsp;
                    </div>

                    <div>
                        <MultiRec
                            key={++key}
                            getRow={this.getRow}
                            getHeader={this.getHeader}
                            // getFooter={this.getFooter}
                            data={this.receivedQuotation.details}
                            tfootStyle={{ borderTop: "0.1rem solid" }}
                            tbodyStyle={{ borderTop: "2.5px solid" }}
                        />
                    </div>
                </div>

                <div className="row  p-0 m-0 g-0 " >
                    <div className="col-sm-6 mt-4 d-flex justify-content-right ">
                        {(this.readOnly && (this.action === "REJECT")) &&
                            <NarrowButton value="Reject" onClick={this.submitForm} key={(++key).toString()} />
                        }
                        <NarrowButton value="Back" onClick={() => { return this.backHandler("BACK"); }} key={(++key).toString()} />
                    </div>
                </div>
            </div>
        );

        let form = <GenericForm datablk={datablk} buttons={[]} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    loading_render = () => {
        let buttons = [];

        let datablk = <div className="container border border-0 pt-0 m-0 ">
            <div className="row  p-0 m-0 g-0 " >
                <div className="col-sm-12 h4 text-center" >{this.page_title}</div>
                <div className="d-flex justify-content-evenly">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

        </div>

        let form = <GenericForm datablk={datablk} buttons={buttons} controlProps={this.controlProps} {...this.props} />
        return form;
    }

    render = () => {
        if (this.state.loading) {
            return this.loading_render();
        }
        else {
            return this.data_render();
        }
    }

    submitForm = async () => {
        this.resetError();

        let data = deepCopy(this.state.data);
        copyFormFieldsToDataFields(header_mapping, data, this.receivedQuotation.header, header_fields);
        this.lineItemsHandler.formFieldsToDataArray(this.state.data, this.receivedQuotation.details);

        try {
            switch (this.action) {
                case "REJECT":
                    await receivedQuotationServiceRoutines.reject(this.session, this.receivedQuotation);
                    this.receivedQuotation = await this.fetch(this.quotationId);
                    break;
                default:
                    break;
            }

            this.backHandler(this.action);
        }
        catch (e) {
            console.log(this.receivedQuotation);
            console.log(e);
            this.setError(raiseError(e.error_message));
        }
    }

}


export default AddEditQuotation;
