import { getServiceDetails } from '../common/utils'
import { handleAxiosHTTPException } from "WebUI_Framework";
import { getAAAServiceDetails } from '../common/utils'

let usersserviceroutines = {};


usersserviceroutines.list = async (serverInstance, accessToken, offset, recordsPerFrame) => {
    let response;
    try {
        response = await serverInstance.get(
            `/biop/registrar/user_profile/list?offset=${ offset }&num_recs=${ recordsPerFrame }&deleted=0&access_type=B`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.fetch = async (serverInstance, accessToken, select_email_id) => {
    let response;
    try {
        response = await serverInstance.get(
            `/biop/registrar/user_profile/fetch?email_id=${ select_email_id }`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.enable_access_to_default_company = async (serverInstance, accessToken, userData) => {
    let response;
    try {
        response = await serverInstance.post(
            '/biop/registrar/user_profile/enable_default_company_access', userData,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.disable_access_to_default_company = async (serverInstance, accessToken, userData) => {
    let response;
    try {
        response = await serverInstance.post(
            '/biop/registrar/user_profile/disable_default_company_access', userData,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.modify = async (serverInstance, accessToken, selectedUserData) => {
    let response;
    try {
        response = await serverInstance.post(
            '/biop/registrar/user_profile/modify', selectedUserData,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.add = async (serverInstance, accessToken, retObj) => {
    let response;
    try {
        response = await serverInstance.post('/biop/registrar/user_profile/add',
            retObj,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.link_fetch = async (serverInstance, accessToken, select_email_id, org_id) => {

    let response;
    try {
        response = await serverInstance.get(`/biop/registrar/user_company_access/fetch?user_principal=${ select_email_id }&org_id=${ org_id }`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.link_delete = async (serverInstance, accessToken, InputObj) => {
    let response;
    try {
        response = await serverInstance.put(`/biop/registrar/user_company_access/delete`,
            InputObj, {
            headers: {
                "X-Auth": accessToken
            }
        }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.addunlinked = async (serverInstance, accessToken, InputObj) => {
    let response;
    try {
        response = await serverInstance.put(`/biop/registrar/user_company_access/add`,
            InputObj,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }

           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}


usersserviceroutines.fetchCompanyList = async (serverInstance, accessToken, searchText) => {
    let response;
    try {
        response = await serverInstance.get(`/biop/registrar/company/list?org_name=${ searchText }&deleted=0 `,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.fetchParentCompanyList = async (serverInstance, accessToken, OrgId, searchText) => {
    let response;
    try {
        response = await serverInstance.get(
				`/biop/registrar/partner/parent_company_list?org_id=${ OrgId }&org_name=${ searchText }&deleted=0`,
            {
                headers: {
                    "X-Auth": accessToken
                }
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.fetch_first_level_network = async (serverInstance, accessToken, org_id, offset,
													recordsPerFrame, searchtext, is_subscriber) => {
    let response;
    try {
        response = await serverInstance.get(`/biop/registrar/company/fetch_first_level_network`,
            {
                headers: {
                    "X-Auth": accessToken
                },
                params: {
                    org_id: org_id,
                    offset : offset,
                    num_recs : recordsPerFrame,
                    org_name : searchtext,
                    is_subscriber: is_subscriber,
                }
            },
       );
       return response.data;
    }
    catch (e) {
       return handleAxiosHTTPException(e);
    }
}

usersserviceroutines.list_linked_companies = async (serverInstance, accessToken, email_id, offset, recordsPerFrame) => {
    let response;
    try {
        response = await serverInstance.get(`/biop/registrar/user_company_access/list`,
            {
                headers: {
                    "X-Auth": accessToken
                },
                params: {
                    user_principal: email_id,
                    offset : offset,
                    num_recs : recordsPerFrame
                }
            },
       );
       return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

usersserviceroutines.fetchCompany = async (serverInstance, accessToken, selectOrgId) => {
    let response;
    try {
        response = await serverInstance.get(
            `/biop/registrar/company/fetch?org_id=${selectOrgId}`, {
            headers: {
                "X-Auth": accessToken
            }
        });

        return response.data;
    }
    catch (e) {
        return handleAxiosHTTPException(e);
    }
}

usersserviceroutines.genPw = async (session, emailId) => {
    let response;
	const service = getAAAServiceDetails(session);
    try {
        response = await service.serverInstance.put(
            `/biop/aaa/individual_pw/gen?email_id=${emailId}`, null, {
                headers: {
                    "X-Auth": service.accessToken
                }
            }
        );

        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

usersserviceroutines.getUserInboxes = async (session, userPrincipal) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    const qp = {
        parent_org_id : session.getLoggedInCompanyId(),
        user_principal : userPrincipal
    }
	let response;
	try {
		response = await serverInstance.get(
			`/biop/subscriber/user_inbox_manager/list`,
			{
				headers: {
					"X-Auth": accessToken,
				},
                params: qp,
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

usersserviceroutines.addUserInbox = async (session, userInbox) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.post(
			`/biop/subscriber/user_inbox_manager/add`,
            userInbox,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

usersserviceroutines.deleteUserInbox = async (session, userInbox) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
	let response;
	try {
		response = await serverInstance.put(
			`/biop/subscriber/user_inbox_manager/delete`,
            userInbox,
			{
				headers: {
					"X-Auth": accessToken,
				},
			}
		);
	} catch (e) {
		return handleAxiosHTTPException(e);
	}
	return response.data;
};

usersserviceroutines.list_for_org = async (session, orgId, offset, recordsPerFrame) => {
	const {serverInstance, accessToken} = getServiceDetails(session);
    const qp = {
        org_id : orgId,
        deleted : "0",
        access_type : "B",
        offset: offset,
        num_recs: recordsPerFrame
    }
    let response;
    try {
        response = await serverInstance.get(
            `/biop/registrar/user_profile/list_for_org`,
            {
                headers: {
                    "X-Auth": accessToken
                },
                params: qp
            }
           );
        return response.data;
    }
    catch(e) {
        return handleAxiosHTTPException(e);
	}
}

export default usersserviceroutines;
