//import { GenericComponent } from "../genericcomponent"
//import { deepCopy } from "../browser";
//import { withRouter } from "./withRouter";
import { GenericComponent, valuePresent } from "WebUI_Framework"
import { deepCopy } from "WebUI_Framework"
import { withRouter } from "./withRouter"
import srvcRoutines from './SwitchOrgServiceRoutines';
import usersServiceRoutines from '../users/UsersServiceRoutines'

class SwitchOrg extends GenericComponent {
	constructor(props) {
		super(props);
		this.session = props.session;
		this.handleOrgChange = this.handleOrgChange.bind(this);
		this.serverInstance = this.session.getServerInstance('SUBSCRIBER');
		this.accessToken = this.session.getAccessToken();
		this.resetMenu = props.resetMenu;
		this.navigate = props.navigate;

		let companyId = this.session.getLoggedInCompanyId();
		let parentCompanyId = this.session.getLoggedInParentCompanyId();
		let option = this.session.getUserCompanyProps(companyId, parentCompanyId).index;
		this.state = {
			selectedKey: option,
			userCompanyList : this.session.getUserCompanyList()
		}
	}

	getSubscriber = async (serverInstance, accessToken) => {
		try {
			let ret = await srvcRoutines.subscribersList(serverInstance, accessToken);
			let usercompanylist = deepCopy(this.state.userCompanyList);
			if(ret.data !== undefined && ret.data !== null){
				if(ret.data.length){
					usercompanylist = usercompanylist.filter(company => {
						return ret.data.some(obj => obj.org_id === company.parent_org_id);
					});
					this.setState({ userCompanyList: usercompanylist });
				}
				else{
					this.setState({userCompanyList : {}});
					console.log("No subscriber found in setup");
				}
			}
		}
		catch (e) {
			console.log(e);
		}
	}


	componentDidMount(){
		this.getSubscriber(this.serverInstance, this.accessToken);
	}

	switchOrgId = async (company_id, parent_company_id, option) => {

		try {
			let ret = await srvcRoutines.switchOrgId(this.serverInstance, this.accessToken, company_id, parent_company_id);
			let data = ret.data;

			this.session.setAccessToken(data.jwt_token);
			this.session.setLoggedInCompany(company_id);
			this.session.setLoggedInParentCompany(parent_company_id);
			this.setState({ selectedKey: option });

			this.resetMenu();
			this.navigate("/");
		}
		catch (e) {
			console.log(e);
			alert(e.error_message);
            throw new Error(e.error_message);
		}

		try {
            if (!valuePresent(this.session.getUserInboxes(company_id))) {
                /*
                 * Inboxes, workflow and approvals are available only for
                 * subscribers
                 */
                if (company_id !== parent_company_id) {
                    this.session.setUserInboxes(company_id, []);
                }
                else {
                    const inboxes = await usersServiceRoutines.getUserInboxes(this.session, this.session.getLoginId());
                    this.session.setUserInboxes(company_id, inboxes);
                }
            }
		}
		catch (e) {
			console.log(e);
			alert(e.error_message);
            throw new Error(e.error_message);
		}

	}

	handleOrgChange = async (event) => {
		const selectedOption = event.target.options[event.target.selectedIndex];
		let key = Number(selectedOption.value);
		this.setState({ selectedKey : key });
		const companyId = this.state.userCompanyList[key].org_id;
		const parentCompanyId = this.state.userCompanyList[key].parent_org_id;
		return this.switchOrgId(companyId, parentCompanyId, key);
	}

	render() {

		return (
			<>
				{ (Object.entries(this.state.userCompanyList).length > 1) &&
					<>
						<div className="m-0 p-0 g-0 ">
							<cite className="p-0 m-0 g-0 small fw-bold" >Switch company&nbsp;&nbsp;</cite>
						</div>
						<div className="m-0 p-0 g-0 ">
							<cite className="p-0 m-0 g-0 small" >
								{/*&lt;{this.props.session.getLoggedInParentCompanyName()}&gt;*/}
								<select name="selectedOrg" id="selectedOrg" style={{ width: "100%", height:"20px"}}
									className=" bg-white border m-0 p-0 g-0"
									value={this.state.selectedKey}
									onChange={this.handleOrgChange}>
									{Object.entries(this.state.userCompanyList).map(([key]) => (
										<option
											key={key}
											value={key}>
											{this.state.userCompanyList[key].duplicate_org_id &&
												<>
												{this.state.userCompanyList[key].org_name+"->"+this.state.userCompanyList[key].parent_org_name}
												</>
											}
											{!(this.state.userCompanyList[key].duplicate_org_id) &&
												<>
												{this.state.userCompanyList[key].org_name}
												</>
											}
										</option>
									))}
								</select>
							</cite>
						</div>
					</>
				}
				{ (Object.entries(this.state.userCompanyList).length === 1) &&
					<>
						<div className="m-0 p-0 g-0 pt-1 ">
							<cite className="p-0 m-0 g-0 small fw-bold" >Company&nbsp;&nbsp;</cite>
						</div>
						<div className="m-0 p-0 g-0 pt-1 ">
							<cite className="p-0 m-0 g-0 small" >{this.state.userCompanyList[0].org_name}</cite>
						</div>
					</>
				}
			</>
		)
		/*
		return (
			<div className="container-fluid m-0 p-0 g-0" >
				{ (Object.entries(this.state.userCompanyList).length > 1) &&
					<>
						<div className="row m-0 p-0 g-0">
							<label
								htmlFor="selectedOrg"
								className="p-0 m-0 g-0 small fw-bold"
								style={{fontSize:"0.9rem"}}>Switch company</label>
						</div>
						<div className="row m-0 p-0 g-0">
							<select name="selectedOrg" id="selectedOrg" style={{ width: "100%" }}
								className="form-control-sm bg-white border m-0 p-0 g-0"
								value={this.state.selectedKey}
								onChange={this.handleOrgChange}>
								{Object.entries(this.state.userCompanyList).map(([key]) => (
									<option
										key={key}
										value={key}>
										{this.state.userCompanyList[key].duplicate_org_id &&
											<>
											{this.state.userCompanyList[key].org_name+"->"+this.state.userCompanyList[key].parent_org_name}
											</>
										}
										{!(this.state.userCompanyList[key].duplicate_org_id) &&
											<>
											{this.state.userCompanyList[key].org_name}
											</>
										}
									</option>
								))}
							</select>
						</div>
					</>
				}
				{ (Object.entries(this.state.userCompanyList).length === 1) &&
					<>
						<div className="row my-1 p-0 g-0">
							<div
								className="p-0 m-0 g-0 small fw-bold"
								style={{fontSize:"0.9rem"}}>
								Company
							</div>
						</div>
						<div className="row m-0 p-0 g-0 form-control-sm bg-white ">
							{this.state.userCompanyList[0].org_name}
						</div>
					</>
				}
			</div>
		)
		*/
	}
}

export default withRouter(SwitchOrg);
